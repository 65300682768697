<script>
import TableSettings from "@/components/settings/TableSettings.vue";
import api from "@/utils/api";
import {useToast} from "vue-toastification";
import {mapGetters} from "vuex";

export default {
    name: "custErrorLogs",
    components: {TableSettings},
    computed:{
        ...mapGetters({
            loggedPermission: 'auth/loggedPermission'
        })
    },
    methods:{
        async handleResend(id){
            try {
                const {data} = await api.post(`/SaleOrderHeader/resend/${id}`);
                useToast().success('Action completed successfully')
            } catch (e) {

            }
        }
    }
}
</script>

<template>
    <div>
        <Breadcrumbs main="Sales Order Interface Log Table" title="View List" />
        <div class="container">

        <table-settings
            dateFormat="dd MMMM yyyy, HH:mm"
            :setting-type="{title: 'Order Error Logs', api: 'SaleOrderHeaderDynamics', CUD: [0,1,0]}"
        >
            <template #title-isSuccess="{key}">
                Status
            </template>
            <template #booleanCell-isSuccess="{cellData}">
                {{cellData ? 'Processed' : 'Pending'}}
            </template>
            <template #actions="{rowData: {saleOrderHeaderId, isSuccess}}">
                <button v-if="loggedPermission > 3 && !isSuccess" :disabled="isSuccess" class="btn btn-outline-primary" @click="handleResend(saleOrderHeaderId)">
                    Resend
                </button>
                <div v-else class="text-center">
                    No actions
                </div>
            </template>
        </table-settings>
        </div>
    </div>
</template>

<style scoped>

</style>
