<script>
import CreateEditSettings from "@/components/settings/CreateEditSettings.vue";
import {tableColumnTitles} from "@/constants/tableSettings";
import SettingsInput from "@/components/settings/SettingsInput.vue";
import PolarLoader from "@/components/PolarLoader.vue";
import { useVuelidate } from '@vuelidate/core'
import { email, sameAs, helpers, minLength, maxLength } from '@vuelidate/validators';
import {goToErrorInput, validatePhoneNumber} from "@/utils/helperFunctions";
import api from "@/utils/api";
import {useToast} from "vue-toastification";

const defaultAddress = (transactionType) => {
    let basedInfo = {};
    if(transactionType) {
        basedInfo = {
            "branchID": "",
            "branchName": "",
            "registrationNumber": "",
            "description": ""
        }
    } else {
        basedInfo = {
            isStatement: false,
            isCustom: false,
            statementLocator: ''
        }
    }
    return {
        "countryId": null,
        "stateId": null,
        "countyId": null,
        "cityId": null,
        ...basedInfo,
        "street": "",
        "address": "",
        "zipCode": "",
        transactionType,
        locatorExtension: '',
        "contactPhone": "",
        "email": "",
        addressNote: ''
    }
}
export default {
    name: "new_register",
    components: {PolarLoader, SettingsInput, CreateEditSettings},
    setup () {
        return { v$: useVuelidate() }
    },
    data(){
        return {
            finishedSuccess: false,
            loading: false,
            agreeCheck: false,
            agreeError: false,
            captchaError: '',
            emailExists: [],
            newAddressId: 0,
            sameAsInvoice: false,
            addresses:[
                defaultAddress(0),
                defaultAddress(1),
            ],
            accountDetails: {
                organizationName: '',
                companyRegistrationNumber: '',
                companyVATNumber: '',
                dunsNumber: '',
                isVATRegistered: true,
            },
            loginDetails: {
                title: "",
                firstName: "",
                lastName: "",
                email: "",
                confirmEmail: "",
                phonePrefix: "",
                phoneNumber: "",
                pharmacistRegistrationNumber: "",
                isRegisteredPharmacist: false
            },
            apiS: [
                'Country',
                'State',
                'County',
                'City',
                'CountryPrefix'
            ],
            apiParams: {
                City: {isDescending: false, columnName: 'name'},
                County: {isDescending: false, columnName: 'name'},
                Country: {isDescending: false, columnName: 'description'},
                State: {isDescending: false, columnName: 'description'},
            },
            apiData: {
                TitleOptions: [
                    'Ms',
                    'Miss',
                    'Mr',
                    'Mrs',
                ]
            }
        }
    },
    computed: {
        hasCustomAddress(){
            return !!this.addresses.find(a => !!a.statementLocator)
        },
        finalForm(){
            const fForm = {
                ...this.loginDetails,
                ...this.accountDetails,
                addresses: []
            }

            for(const add of this.addresses){
                const {contactPhone, email, emailDescription, phoneDescription, transactionType, locatorExtension, isStatement, isCustom, statementLocator, ...rest} = add;
                let statementContact = [];
                if(isCustom){
                    statementContact.push({
                        description: '',
                        purpose: '',
                        locatorExtension: null,
                        transactionType: 0,
                        communicationType: 2,
                        d365ElectronicAddressID: '',
                        isStatement,
                        isCustom,
                        isPrimary: false,
                        isPrivate: false,
                        isMobile: false,
                        locator: statementLocator
                    })
                }
                const contacts = [
                    ...statementContact,
                    {
                        description: phoneDescription,
                        isMobilePhone: true,
                        locator: contactPhone,
                        transactionType,
                        locatorExtension,
                        communicationType: 1
                    },
                    {
                        description: emailDescription,
                        isMobilePhone: false,
                        locator: email,
                        isStatement: !transactionType && !this.hasCustomAddress,
                        transactionType,
                        communicationType: 2
                    }
                ]

                const finalAdd = {
                    ...rest,
                    transactionType,
                    contacts
                };
                console.log(finalAdd);

                fForm.addresses.push(finalAdd);
            }
            return fForm
        },
        JSONForm(){
            const jsonForm = [
                {
                    title: 'Account Details',
                    form: this.getAddressForm({
                        "organizationName": {type: 'string', default: '', label: 'Organization Name'},
                        "companyRegistrationNumber": {type: 'string', default: '', label: 'Company Registration Number'},
                        "companyVATNumber": {type: 'string', default: '', label: 'Company VAT Number', notRequired: !this.accountDetails.isVATRegistered},
                        "dunsNumber": {type: 'string', default: '', label: 'DUNS Number', notRequired: true},
                        "isVATRegistered": {type: 'boolean', default: false, label: 'I am VAT registered'},
                    }),
                    data: this.accountDetails,
                }
            ]
            // const emailInput = {"email": {type: 'string', default: '', inputType: 'email', label: 'Email address', validation: {email}}};
            // const phoneInput = {"contactPhone": {type: 'string', default: '', label: 'Phone number', validation: { validatePhoneNumber: helpers.withMessage('Invalid phone number', validatePhoneNumber)}},}
            for(const address of this.addresses){
                let basedInputs = {};
                if(address.transactionType){
                    basedInputs = {
                        "branchID": {type: 'string', default: '', label: 'Branch Identifier', notRequired: true},
                        "branchName": {type: 'string', default: '', label: 'Branch Name'},
                        "registrationNumber": {type: 'string', default: '', label: 'Registration number'},
                        "email": {type: 'string', default: '', inputType: 'email', label: 'Email address', validation: {email}},
                        "emailDescription": {type: 'string', default: '', label: 'Email Description'},
                    }
                } else {
                    basedInputs = {
                        "email": {type: 'string', default: '', inputType: 'email', label: 'Email address', validation: {email}},
                        "emailDescription": {type: 'string', default: '', label: 'Email Description'},
                        isCustom: {label: 'Custom Email Address for Statement', type: 'boolean', disabled: this.invoiceStatementContact?.isCustom, notRequired: true},
                        statementLocator: {
                            label: 'Email Address for Statement',
                            type: !address?.isCustom ? 'null' : 'string',
                            notRequired: !address?.isCustom
                        }
                    }
                }
                jsonForm.push({
                    title: address.transactionType ? 'Delivery Address Details' : 'Invoice Address Details',
                    data: address,
                    form: this.getAddressForm({
                        "description": {type: 'string', default: '', label: 'Description'},
                        "countryId": {type: 'select', default: '', api: 'Country', valueType: 'number', displayKey: 'description', params: {isDescending: false, columnName: 'description'}},
                        "stateId": {type: 'select', default: '', api: 'State', relation: 'countryId', valueType: 'number', displayKey: 'description', params: {isDescending: false, columnName: 'description'}},
                        "countyId": {type: 'select', default: '', api: 'County', relation: 'stateId', valueType: 'number', params: {isDescending: false, columnName: 'name'}},
                        "cityId": {type: 'select', default: '', api: 'City', relation: 'countyId', valueType: 'number', params: {isDescending: false, columnName: 'name'}},
                        "street": {type: 'string', default: '', label: 'Street'},
                        "zipCode": {type: 'string', default: '', label: 'Post Code'},
                        // "address": {type: 'string', default: '', label: 'Address'},
                        ...basedInputs,
                        "locatorExtension": {label: 'Prefix', type: 'searchSelect', api: 'CountryPrefix', key: 'locatorExtension', trackKey: 'prefix',
                            displayKey: (countryInfo) => {
                                if(countryInfo){
                                    return ` ${countryInfo.prefix}`
                                }
                                return ''
                            },
                            displayOption: (countryInfo) => {
                                return `${countryInfo.country} ${countryInfo.prefix}`
                            },
                            customClass: 'col-3 col-md-2 p-r-0',
                            filterKeys: ['country', 'prefix']
                        },
                        "contactPhone": {
                            type: 'string', default: '', label: 'Phone number',
                            customClass: 'col-9 col-md-4 p-l-0',
                            validation: { validatePhoneNumber: helpers.withMessage('Invalid phone number', validatePhoneNumber)}
                        },
                        "phoneDescription": {type: 'string', default: '', label: 'Phone Description'},
                        "addressNote": {type: 'textarea', default: '', label: 'Notes', notRequired: true, customClass: 'col-12'},
                    }),
                })
            }

            jsonForm.push({
                title: 'Login Details',
                data: this.loginDetails,
                form: this.getAddressForm({
                    title: {type: 'select', default: '', api: 'TitleOptions'},
                    firstName: {type: 'string', default: '', label: 'First name', validation: {minLength: minLength(3)}},
                    lastName: {type: 'string', default: '', label: 'Last name', validation: {minLength: minLength(3)}},
                    phonePrefix: {label: 'Prefix', type: 'searchSelect', api: 'CountryPrefix', trackKey: 'prefix',
                        displayKey: (countryInfo) => {
                            if(countryInfo){
                                return ` ${countryInfo.prefix}`
                            }
                            return ''
                        },
                        displayOption: (countryInfo) => {
                            return `${countryInfo.country} ${countryInfo.prefix}`
                        },
                        customClass: 'col-3 col-md-2 p-r-0',
                        filterKeys: ['country', 'prefix']
                    },
                    phoneNumber: {type: 'string',
                        customClass: 'col-9 col-md-4 p-l-0',
                        default: '', label: 'Phone number', validation: { validatePhoneNumber: helpers.withMessage('Invalid phone number', validatePhoneNumber)}
                    },
                    email: {type: 'string', default: '', inputType: 'email', label: 'Email address', validation: {email,
                        emailExists: helpers.withMessage('Email exists', (value) => {
                            return !this.emailExists.includes(value)
                        })}
                    },
                    confirmEmail: {type: 'string', default: '', inputType: 'email', label: 'Confirm email address', validation: {sameAs: helpers.withMessage('Confirm email is not the same as email', sameAs(this.loginDetails.email))}},
                    pharmacistRegistrationNumber: {
                        label: 'Registration Number',
                        type: 'string',
                        notRequired: !this.loginDetails.isRegisteredPharmacist,
                        immediate: true
                    },
                    isRegisteredPharmacist: {
                        label: 'Is Registered to Buy Pharmaceuticals',
                        type: 'boolean',
                    }
                })
            })
            return jsonForm;
        },
    },
    methods:{
        removeNewAddress(id){
            const idx = this.addresses.findIndex(a => a.id === id);
            this.addresses.splice(idx, 1);
        },
        addNewDeliveryAddress(){
            this.newAddressId = this.newAddressId - 1;
            this.addresses.push({...defaultAddress(1), id: this.newAddressId})
        },
        async register(){
            const result = await this.v$.$validate();
            this.agreeError = !this.agreeCheck;
            if(result && this.agreeCheck){
                this.agreeError = false;
                const verified = await this.verifyCaptcha();
                if(verified){
                    this.loading = true;
                    document.body.style.overflow = 'hidden';
                    console.log('forma finale', this.finalForm)
                    // return true;
                    const response = await this.$store.dispatch('auth/actRegister', this.finalForm);
                    this.loading = false;
                    document.body.style.overflow = '';
                    document.body.style['max-height'] = '';
                    if(response === 'success') {
                        this.finishedSuccess = true;
                        return true;
                    } else {
                        if(response?.response?.data){
                            const {errors} = response.response.data;
                            if(errors){
                                if(errors[0]?.code === 'Users.EmailNotUnique'){
                                    this.emailExists.push(this.finalForm.email);
                                }
                            }
                        }
                    }
                } else {
                    this.captchaError = 'Please verify you are human';
                }
            }
            goToErrorInput(-10);
        },
        getAddressForm(jsonForm){
            const form = [];
            for(const key in jsonForm){
                const formInput = {
                    label: tableColumnTitles[key] || key,
                    key,
                    ...jsonForm[key],
                }
                form.push(formInput);
            }
            return form;
        },
        handleDataChange({val, key, data, form}){
            data[key] = val;
            if (key === 'isCustom'){
                data['isStatement'] = val;
            }
            this.monitorRelations(key, data, form);
            this.regulatePharmacist(key, val);
        },
        monitorRelations(key, data, form){
            let nextKey = '';
            for(const input of form){
                if(nextKey === input.relation){
                    console.log('next relation', nextKey)
                    data[input.key] = null;
                } else if(input.relation === key){
                    data[input.key] = null;
                    nextKey = input.key;
                }
            }
        },
        async getSetApiData(){
            for(const url of this.apiS){
                api.get(`/${url}`, {
                    params: {
                        pageNumber: 1,
                        pageSize: 10000,
                        ...(this.apiParams[url] || {})
                    }
                }).then(({data}) => {
                    this.apiData[url] = data.items;
                })
            }
        },
        async verifyCaptcha(){
            try {
                const recaptchaResponse = grecaptcha.getResponse();
                console.log('recaptchaResponse::', recaptchaResponse);
                if(recaptchaResponse) {
                    const {data} = await api.post('/captcha', null, {
                        params: {
                            token: recaptchaResponse
                        }
                    })
                    if(data) return data
                } else {
                    this.captchaError = 'Please verify you are human'
                }
            } catch (e) {
                const {data} = e.response
                useToast().error(`${data.errors[0]?.description}`);
                if(grecaptcha){
                    grecaptcha.reset();
                }
            }
            return false;
        },
        setDeliveryAsInvoice(itemIdx){
            console.log(itemIdx, this.addresses)
                const invoice = this.addresses[0];
                const delivery = this.addresses[itemIdx];
                const {transactionType, ...rest} = invoice;
                for(const key in rest){
                    delivery[key] = invoice[key]
                }
        },
        regulatePharmacist(key, val){
            if(key === 'isRegisteredPharmacist'){
                if(!val){
                    this.loginDetails.pharmacistRegistrationNumber = '';
                }
            } else if(key === 'pharmacistRegistrationNumber') {
                this.loginDetails.isRegisteredPharmacist = !!val
            }
        },
        getRandom(length) {
            return `${Math.floor(Math.pow(10, length-1) + Math.random() * 9 * Math.pow(10, length-1))}`;
        },
        handleAutoFill(){
            this.accountDetails = {
                organizationName: 'Test',
                companyRegistrationNumber: this.getRandom(9),
                companyVATNumber: this.getRandom(7),
                dunsNumber: this.getRandom(5),
                isVATRegistered: true,
            }
            const city = this.apiData?.City?.[0]
            const location = {
                countryId: null,
                stateId: null,
                countyId: null,
                cityId: null
            }
            if(city){
                location.countryId = city.countryId;
                location.stateId = city.stateId;
                location.countyId = city.countyId;
                location.cityId = city.id;
            }
            const baseAdd = {
                ...location,
                description: 'Description Tirana',
                street: 'Tirana',
                zipCode: '12436',
                email: 'test@email.com',
                emailDescription: 'test@email.com description',
                locatorExtension: '+355',
                contactPhone: '69' + this.getRandom(7),
                phoneDescription: 'phone description',

            }
            this.addresses[0] = {
                ...baseAdd,
                isStatement: false,
                isCustom: false,
                statementLocator: '',
                transactionType: 0
            };
            this.addresses[1] = {
                ...baseAdd,
                transactionType: 1,
                branchID: this.getRandom(5),
                branchName: 'name branch',
                registrationNumber: this.getRandom(5),
            };

        }
    },
    created() {
        this.getSetApiData();
    }
}
</script>

<template>
<div>
    <div v-if="finishedSuccess" class="text-center">
        <h3 class="text-success">Your account has been created!</h3>
        <p>We have sent a confirmation link in the email below</p>
        <p><b class="text-primary">{{loginDetails.email}}</b></p>
        <router-link to="/login" class="ml-auto">
            Back to login
        </router-link>
    </div>
    <div v-else class="container w-100">
        <div class="d-flex my-4">
            <button @click="handleAutoFill" class="btn btn-primary ml-auto">Auto Fill</button>
        </div>
        <form name="Polar Register" id="polarRegister" autocomplete="on">
            <div v-for="({form, data, title}, idx) in JSONForm" :key="title + 'idx'">
                <div class="card theme-form" >
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0">{{ title }}</h4>
                       <div class="ml-auto" v-if="data.transactionType">
                           <button type="button" class="btn btn-outline-primary" @click="setDeliveryAsInvoice(idx - 1)">Same As Invoice</button>
                       </div>
                        <div v-if="data.id < 0" class="ml-auto cursor-pointer" @click="removeNewAddress(data.id)">
                            <i class="fa fa-trash fs-5 font-danger"></i>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <SettingsInput
                                v-for="(input, idx) in form"
                                :class="['mb-3', (input.customClass || 'col-md-6')]"
                                :key="input.key + title"
                                :settingsData="data"
                                :model-value="data[input.key]"
                                :parent-select="input.api ? (apiData[input.api] || [])  : null"
                                @update:model-value="(val) => handleDataChange({val, key: input.key, data, form})"
                                :input="input"
                            />
                        </div>
                    </div>
                </div>
                <div v-if="idx + 2 === JSONForm.length" class="mb-4">
                    <button type="button" class="btn btn-primary d-flex align-items-center gap-2 justify-content-center btn-lg w-100" @click="addNewDeliveryAddress">
                        <i class="fa fa-plus"/>
                        Add delivery address
                    </button>
                </div>
            </div>
        </form>
        <div class="form-group">
<!--            <label class="col-form-label">Please confirm*</label>-->
            <div class="g-recaptcha" data-sitekey="6LeQ04QpAAAAAJBnlfoa7mrn5IHzgmbgv6gaccbz"></div>
            <div class="invalid-feedback d-block" v-if="captchaError">{{captchaError}}</div>
        </div>
        <div class="d-flex justify-content-between align-items-center">

        <div class="form-group mb-0 mt-3 mb-3">
            <div class="checkbox p-0">
                <input v-model="agreeCheck" id="checkbox1" type="checkbox" />
                <label class="text-muted" for="checkbox1">
                    Agree with    <a href="/terms-and-conditions"
                                     rel="noopener noreferrer"
                                     target="_blank"
                                     class="ms-2">
                    Terms and Conditions
                </a>
                </label>
                <div class="invalid-feedback mt-0 d-block" v-if="agreeError && !agreeCheck">{{ 'You have to agree with our terms and conditions' }}</div>

            </div>
        </div>
        <router-link to="/login" class="ml-auto">
            Back to login
        </router-link>
        </div>
        <button @click="register" class="btn btn-lg btn-primary btn-block w-100">
            Create Account
        </button>
        <PolarLoader v-if="loading" classes="top-none"/>
    </div>
</div>

</template>

<style>
.top-none{
    top: unset !important;
}
</style>
