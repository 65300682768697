<script>
import SimpleTable from "@/components/order/SimpleTable.vue";
import ProggressButton from "@/components/ProggressButton.vue";
import PolarSearch from "@/components/uiKits/PolarSearch.vue";
import PolarLoader from "@/components/PolarLoader.vue";
import Pagination from "@/components/uiKits/Pagination.vue";
import api from "@/utils/api";
import {format} from "date-fns";
import {useToast} from "vue-toastification";
import {downloadReport} from "@/utils/helperFunctions";

const badgeItem = (color, title) => `<div class="badge p-2 w-100 max-w-max badge-${color || 'success'}">
                                        <div class="f-w-700">
                                            ${title}
                                        </div>
                                    </div>`
export default {
    name: "address_validation_status",
    components: {Pagination, PolarLoader, PolarSearch, ProggressButton, SimpleTable},
    data(){
        return {
            loading: false,
            params: {
                searchKey: '',
                columnName: 'addressExpiryDateUtc',
                isDescending: true,
                pageNumber: 1,
                pageSize: 8,
                isAddressValidated: {id: null},
                expireNext2Weeks: null,
            },
            displayColumns:[
                {key: 'customerMasterDataId', title: 'Customer Id'},
                {key: 'branchID', title: 'Branch Id'},
                {key: 'branchName', title: 'Branch Name'},
                {key: 'addressCheckDateUtc', title: 'Address Check Date', type: 'date'},
                {key: 'addressExpiryDateUtc', title: 'Address Expiry Date', type: 'date'},
                {key: 'validatedOnUtc', title: 'Validated On', type: 'date'},
                {key: 'createdOnUtc', title: 'Created On', type: 'date'},
                {key: 'isValidated', title: 'Address Validated', type: 'boolean', value: (item) => {
                        const info = item.isValidated ? {color:'success', title: 'YES'} : {color:'warning', title: 'NO'}
                        return badgeItem(info.color, info.title)
                    }},
            ],
            addressValidations: [],
            pagination: {
                "pageNumber": 1,
                "totalPages": 1,
                "totalCount": 0,
                "hasPreviousPage": false,
                "hasNextPage": false
            },
            validationOptions: [
                {id: null, name: 'All Addresses'},
                {id: true, name: 'Validated Addresses'},
                {id: false, name: 'Not Validated Addresses'},
            ],
            isValidatingAddress: []
        }
    },
    methods:{
        handleSearch(){
            this.params.pageNumber = 1;
            this.getValidations();
        },
        handleSort(column){
            if(column === this.params.columnName){
                this.params.isDescending = !this.params.isDescending;
            }
            this.params.columnName = column;
            this.params.pageNumber = 1;
            this.getValidations();
        },
        handlePaginationEvent(page){
            this.params.pageNumber = page;
            this.getValidations();
        },
        async handleDownloadValidationReport(){
            try {
                const {data} = await api.post('/AddressValidation/download');
                console.log(data)
                downloadReport({
                    data: data.fileContents,
                    type: data.contentType,
                    fileName: data.fileDownloadName
                })
            } catch (e) {
                console.log(e, 'eeeeee')
            }
        },
        async getValidations(){
            try {
                this.loading = true;
                const {data} = await api.get('/AddressValidation', {
                    params: {
                        ...this.params,
                        isAddressValidated: this.params.isAddressValidated.id
                    }
                })
                const {items, ...rest} = data;
                this.addressValidations = items;
                this.pagination = rest;
            } catch (e) {
                // errorToast(e)
            } finally {
                this.loading = false
            }
        },
        formatDate(date){
            return format(date, 'dd MMMM yyyy')
        },
        handleExpire(param){
            if(this.params[param]){
                this.params[param] = null
            } else {
                this.params[param] = true;
            }
            this.params.pageNumber = 1;
            this.getValidations();
        },
        handleStatusChange(opt){
            this.params.isAddressValidated = opt;
            this.params.pageNumber = 1;
            this.getValidations();
        },
        async handleValidateAddress({isAddressValidated, id}){
            try {
                this.isValidatingAddress.push(id);
                const {data} = await api.post(`/address/validateAddress/${id}`);
                useToast().success('Address validated with success');
                await this.getValidations();
                this.isValidatingAddress = this.isValidatingAddress.filter(el => el !== id);
            } catch (e){

            }
        }
    },
    mounted() {
        this.getValidations()
    }
}
</script>

<template>
    <div>

        <Breadcrumbs diff-main="Address Validation Status" main="Customer Master Data" main-path="/master-data" title="Address Validation Status" />
        <div class="container">
            <div class="row">
                <div class="card position-relative overflow-hidden">
                    <div class="card-header d-flex flex-column justify-content-center gap-2">
                        <div class="d-flex gap-2 flex-wrap w-100 align-items-center justify-content-between">
                            <PolarSearch v-model="params.searchKey" style="max-width: 400px;" @search="handleSearch"/>
                            <button class="btn btn-success" @click="handleDownloadValidationReport"> <i class="fa fa-download"></i> Download Address Validation Report</button>
                        </div>
                        <div class="d-flex gap-2">
                            <div class="dropdown">
                                <button class="btn filterSelect" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {{params.isAddressValidated?.name || 'All Addresses'}}
                                    <i class="fa fa-caret-down m-l-5"></i>
                                </button>
                                <ul class="dropdown-menu">
                                    <li v-for="opt in validationOptions" :key="opt.id"
                                        @click="handleStatusChange(opt)">
                                        <span class="dropdown-item">{{opt.name}}</span>
                                    </li>
                                </ul>
                            </div>
                            <button @click="handleExpire('expireNext2Weeks')"
                                    :class="['btn', (params.expireNext2Weeks ? 'btn-primary' : 'btn-white border-primary border')]"
                                    style="padding: 12px 10px;">Addresses That Expire in The Next 2 Weeks</button>
                        </div>
                    </div>
                    <div class="card-body" style="min-height: 565px;">
                        <simple-table :columns="displayColumns" :data="addressValidations" :actions="true" class="stickyLastCol">
                            <template #columnHead="{column: col}">
                                <div @click="handleSort(col.key)">
                                    {{ col.title }}
                                    <i class="fa fa-sort"/>
                                </div>
                            </template>
                            <template #columnRow="{tableData: data, column: col}">
                                <div v-if="col.value" v-html="col.value(data)"/>
                                <div v-else-if="col.type === 'date'">
                                    {{ data[col.key] ? formatDate(data[col.key]) : 'No date'}}
                                </div>
                                <div v-else-if="!data[col.key]" :class="['badge py-2 px-3 w-100 max-w-max badge-warning']">
                                    No {{ col.title }}
                                </div>
                                <div v-else>
                                    {{data[col.key]}}
                                </div>
                            </template>
                            <template #actions="{data: {isAddressValidated, id}}">
                                <div class="d-flex align-items-center">

                                <proggress-button @click="handleValidateAddress({isAddressValidated, id})"
                                                  :loading="isValidatingAddress.includes(id)"
                                                  :disabled="!id"
                                                  :active="true"
                                >
                                    Validate Address
                                </proggress-button>
                                </div>
                            </template>
                        </simple-table>
                    </div>
                    <div class="card-footer pagination-padding">
                        <pagination :pagination-info="pagination" @pageChanged="handlePaginationEvent"/>
                    </div>
                    <polar-loader v-if="loading">
                        Loading Customer Validation Status
                    </polar-loader>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.filterSelect{
    border-color: #efefef;
    background-color: #fff;
    font-size: 14px;
    padding: 12px 10px;
    box-shadow: none;
}

.dropdown span {
    cursor: pointer;
}

</style>
