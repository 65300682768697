import {validatePhoneNumber} from "@/utils/helperFunctions";
import { email, sameAs, helpers, required } from '@vuelidate/validators';

const contacts = [
    {label: 'Description', type: 'string', key: 'description', notRequired: true},
    {label: 'Contact', type: 'string', key: 'locator'},
    {label: 'D365 Address Location ID', type: 'null', key: 'd365ElectronicAddressID', notRequired: true},
    {label: 'Is Primary', type: 'boolean', key: 'isPrimary'},
    {label: 'Is Private', type: 'boolean', key: 'isPrivate'},
]

export const defaultAddressInputs = [
    // {label: 'Address', type: 'string', key: 'address'},
    {label: 'Description', type: 'string', key: 'description'},
    {label: 'Street', type: 'string', key: 'street'},
    {label: 'Country', type: 'select', api: 'Country', key: 'countryId', valueType: 'number', displayKey: 'description', params: {isDescending: false, columnName: 'description'}},
    {label: 'State', type: 'select', api: 'State', relation: 'countryId', key: 'stateId', valueType: 'number', displayKey: 'description', params: {isDescending: false, columnName: 'description'}},
    {label: 'County', type: 'select', api: 'County', relation: 'stateId', key: 'countyId', valueType: 'number', params: {isDescending: false, columnName: 'name'}},
    {label: 'City', type: 'select', api: 'City', relation: 'countyId', key: 'cityId', valueType: 'number', params: {isDescending: false, columnName: 'name'}},
    {label: 'Postal Code', type: 'string', key: 'zipCode'},
    {label: 'Valid From', type: 'date', key: 'addressValidFromUtc', notRequired: true},
    {label: 'Valid To', type: 'date', key: 'addressValidToUtc', notRequired: true},
    {label: 'D365 Address Location ID', type: 'null', key: 'd365AddressLocationID', notRequired: true},
]

const phoneContact = () => {
    const items = [...contacts];
    items.splice(1, 0, {label: 'Extension', type: 'string', key: 'locatorExtension', notRequired: true})
    return items
}
export const addressInputs = {
    invoiceAddress: [
        ...defaultAddressInputs,
        {label: 'Notes', type: 'textarea', key: 'addressNote', notRequired: true, col: 'col-12'},
    ],
    addresses: [
        {label: 'Branch Identifier', type: 'string', key: 'branchID'},
        {label: 'Branch Name', type: 'string', key: 'branchName'},
        {label: 'Registration number', type: 'string', key: 'registrationNumber'},
        ...defaultAddressInputs,
        {label: 'Address Check Date', type: 'date', key: 'addressCheckDateUtc', notRequired: true, disabled: true},
        {label: 'Address Validation Expiry Date', type: 'date', key: 'addressExpiryDateUtc', notRequired: true, disabled: true},
        {label: 'Notes', type: 'textarea', key: 'addressNote', notRequired: true, col: 'col-12'},
    ],
    contacts,
    phoneContact: phoneContact(),
    emailContact: contacts,
    createPhoneContact: [
        {label: 'Description', type: 'string', key: 'description', notRequired: true},
        {label: 'Extension', type: 'searchSelect', api: 'CountryPrefix', key: 'locatorExtension', trackKey: 'prefix',
            displayKey: (countryInfo) => {
                if(countryInfo){
                    return ` ${countryInfo.prefix}`
                }
                return ''
            },
            displayOption: (countryInfo) => {
                return `${countryInfo.country} ${countryInfo.prefix}`
            },
            customClass: 'col-4 col-md-3 col-lg-2 p-r-0',
            filterKeys: ['country', 'prefix']
        },
        {label: 'Contact Phone', customClass: 'col-8 col-md-9 col-lg-10 p-l-0', type: 'string', key: 'locator', validation: {required, validatePhoneNumber: helpers.withMessage('Invalid phone number', validatePhoneNumber)}},
        {label: 'Is Mobile Phone', type: 'boolean', key: 'isMobilePhone'},
        {label: 'Is Primary', type: 'boolean', key: 'isPrimary'},
        {label: 'Is Private', type: 'boolean', key: 'isPrivate'},
    ],
    createEmailContact:[
        {label: 'Description', type: 'string', key: 'description', notRequired: true},
        {label: 'Contact Email', type: 'string', key: 'locator', validation: {required, email}},
        {label: 'Is Primary', type: 'boolean', key: 'isPrimary'},
        {label: 'Is Private', type: 'boolean', key: 'isPrivate'},
    ]
}
