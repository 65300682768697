<script>
import {mapGetters, mapState} from "vuex";
import PasswordModal from "@/components/modals/PasswordModal.vue";
import api from "@/utils/api";

export default {
    name: "account",
    components: {PasswordModal},
    data(){
        return {
            accPermission: [
                {title: 'Can view client address', key: 'canViewClientAddress'},
                {title: 'Can edit client address', key: 'canEditClientAddress'},
                {title: 'Can create client address', key: 'canCreateNewClientAddress'},
            ],
            masterData: null
        }
    },
    computed:{
        ...mapState({
            loggedUser: state => state.auth.loggedUser,
        }),
        ...mapGetters({
            loggedPermission: 'auth/loggedPermission',
        }),
        personalInfo(){
            return [
                {title: 'Title', value: `${this.loggedUser?.title}.`},
                {title: 'Full name', value: `${this.loggedUser?.firstName} ${this.loggedUser?.lastName}`},
                {title: 'Phone number', value: `${this.loggedUser?.phonePrefix} ${this.loggedUser?.phoneNumber}`},
                {title: 'Email', value: this.loggedUser?.email},
                {title: 'Registration Number', value: this.loggedUser?.pharmacistRegistrationNumber || '-----'},
            ]
        },
        permissionInfo(){
            const addressP = this.accPermission.map(p => {
                return {title: p.title, value: this.loggedUser?.permission[p.key]}
            })
            return [
                ...addressP,
                {title: 'Role', value: this.loggedUser?.role},
                {title: 'Registered Pharmacist', value: this.loggedUser?.isRegisteredPharmacist},

            ];
        },
        masterdataInfo(){
            if(this.masterData){
                const {
                    status,
                    id,
                    custPaymModeId,
                    creditRating
                } = this.masterData
                return [
                    {title: 'Customer Status', value: th}
                ]
            }
            // 1. CMD Status ( In Check, Check Successful etc )
            // 2. Portal CMD Number
            // 3. Payment method
            // 4. customer balance, credit rating
        },
        handleNotifications(){
            this.$router.push('/account/notification-dashboard')
        },
        handleSupport(){
            this.$router.push('/support')
        }
    },
    methods:{
        handleLogOut(){
            this.$store.dispatch('auth/actLogOut')
        },
        async handleGetUserMasterData(){
            const {customerMasterDataId} = this.loggedUser
            if(!customerMasterDataId) return;

            try {
                if(this.loggedPermission > 2){
                    const {data} = await api.get(`/CustomerMasterData/${customerMasterDataId}`)
                    this.masterData = data;
                } else {
                    const {data} = await api.get(`/CustomerMasterData/otherUsers/${customerMasterDataId}`)
                    this.masterData = data;
                }
            }catch (e) {

            }

        }
    },
    mounted(){
        this.handleGetUserMasterData();

    }
}
</script>

<template>
    <div>
        <Breadcrumbs main="User Profile" title="Manage Account" />
        <div class="container">
            <div class="user-profile">
                <div class="card hovercard text-center">
                    <div class="cardheader bannerProfile"></div>
                    <div class="user-image">
                        <div class="avatar">
                            <i class="fa fa-user-md bg-white avatrIcon" style="color: #007cc2; font-size: 65px"/>
<!--                            <img class="text-white" id="profile-tour" alt="" src="@/assets/images/avtar/user-doctor-solid.svg">-->
                        </div>
    <!--                    <div class="icon-wrapper"><i id="update-profile-tour" class="icofont icofont-pencil-alt-5" data-intro="Change Profile image here"></i></div>-->
                    </div>
                    <div class="info">
                        <div class="row" data-intro="This is the your details" id="info-bar-tour">
                            <div class="col-sm-6 col-lg-4 order-sm-1 order-xl-0">
                                <div class="row">
                                    <div v-if="loggedPermission > 0" class="col-md-6 col-xl-4 p-1">
                                        <router-link to="/products" class="btn py-1 px-1 btn-info w-100">
                                            <i class="fa fa-shopping-basket"/>
                                            Shop Now
                                        </router-link>
                                    </div>
                                    <div class="col-md-6 col-xl-4 p-1">
                                        <button @click="handleSupport" class="btn py-1 px-1 btn-success w-100">
                                            <i class="fa fa-life-buoy"/>
                                            Support
                                        </button>
                                    </div>
                                    <div v-if="loggedPermission > 2" class="col-xl-4 p-1">
                                        <button @click="handleNotifications" class="btn py-1 px-1 btn-warning w-100">
                                            <i class="fa fa-bell"/>
                                            Notifications
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 order-first col-lg-4 order-lg-1">
                                <div class="user-designation">
                                    <div class="title f-w-600 font-primary">{{loggedUser?.firstName}} {{loggedUser?.lastName}}</div>
                                    <h5 class="mt-2">
                                        <span :class="['btn cursor-default', (loggedUser?.isActive ? 'btn-outline-success' : 'btn-outline-danger')]">
                                            {{ loggedUser?.isActive ? 'Active' : 'Inactive' }}
                                        </span>
                                    </h5>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-4 order-sm-2 order-xl-2">
                                <div class="row">
                                    <div class="col-xl-7 p-1">
                                        <button
                                            class="btn btn-primary py-1 px-1 w-100"
                                            data-bs-toggle="modal"
                                            data-bs-target="#changePassword"
                                        >
                                            <i class="fa fa-lock"/>
                                            Change Password
                                        </button>
    <!--                                    <div class="ttl-info text-start ttl-xs-mt">-->
    <!--                                        <h6>Phone number</h6><span>{{ loggedUser?.phoneNumber }}</span>-->
    <!--                                    </div>-->
                                    </div>
                                    <div class="col-lg-5 p-1">
                                        <button class="btn btn-danger py-1 px-1 w-100" @click="handleLogOut">
                                            <i class="fa fa-arrow-circle-up"/>
                                            Log out
                                        </button>
    <!--                                    <div class="ttl-info text-start ttl-sm-mb-0">-->
    <!--                                        <h6>Role</h6><span>{{ loggedUser?.role }}</span>-->
    <!--                                    </div>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row text-start">
                            <div class="col-md-6 mb-4">
                                <h5 class="mb-3 f-w-600">Personal Information</h5>
                                <div class="d-flex flex-column gap-3 border p-3 rounded">
                                    <div v-for="(p, idx) in personalInfo" :key="p.key" :class="['d-flex justify-content-between align-items-center', {'pb-3 border-bottom': idx + 1 < personalInfo.length}]">
                                        <span class="f-w-600">
                                            {{p.title}}
                                        </span>
                                        <div>
                                            {{p.value}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <h5 class="mb-3 f-w-600">Account Permissions</h5>
                                <div class="d-flex flex-column gap-3 border p-3 rounded">
                                    <div v-for="(p, idx) in permissionInfo" :key="p.key" :class="['d-flex justify-content-between align-items-center', {'pb-3 border-bottom': idx + 1 < permissionInfo.length}]">
                                        <span class="f-w-600">
                                            {{p.title}}
                                        </span>
                                        <div v-if="typeof(p.value) === 'boolean'" :class="['badge', (p.value ? 'badge-success' : 'badge-danger')]">
                                            <span class="f-w-700">{{p.value ? 'YES' : 'NO'}}</span>
                                        </div>
                                        <div v-else class="badge badge-success">
                                            <span class="f-w-700">{{p.value}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PasswordModal/>
    </div>
</template>

<style scoped>
.bannerProfile{
    height: 110px !important;
    background-color: #ffffff !important;
    background-image: linear-gradient(315deg, #ffffff 0%, #5899e2 74%) !important;
}

.avatrIcon{
    width: 100px;
    height: 100px;
    max-width: 155px;
    max-height: 155px;
    border-radius: 50%;
    border: 7px solid #fff;
}
</style>
