<script>
export default {
    name: "CustomerDropdown",
    props: ['customers'],
    data(){
        return{
            // customers: []
            zIdx: false
        }
    },
    methods:{
        handleCustomerSelect({id}){
            this.$emit(`viewAs`, id);
        },
        customLabel({id, organizationName}){
            return `${organizationName} - ${id}`
        },
        handleOpen(){
            // this.zIdx = true
            this.$emit('open')
        },
        handleClose(){
            // this.zIdx = false
            this.$emit('close')
        },
    },
}
</script>

<template>
    <div>
        <multiselect
            :style="['min-width: 300px', (zIdx ? 'z-index: 10;' : '')]"
            @select="handleCustomerSelect"
            @open="handleOpen"
            @close="handleClose"
            :options="customers"
            selectLabel=" "
            :custom-label="customLabel"
            track-by="id"
            placeholder="View As"
        />
<!--        <button class="btn btn btn-outline-primary btn-sm ms-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">-->
<!--            <slot>-->
<!--                {{modelValue}}-->
<!--            </slot>-->
<!--            <i class="fa fa-caret-down m-l-5"></i>-->
<!--        </button>-->
<!--        <ul class="dropdown-menu" style="height: 300px; overflow-y: scroll">-->
<!--            <li v-for="c in customers" class="hover-li text-center cursor-pointer" :key="c.id" @click="handleCustomerSelect(c)">-->
<!--                <span class="dropdown-item">{{c.id}}</span>-->
<!--            </li>-->
<!--        </ul>-->
    </div>
</template>

<style scoped>
.hover-li{
    span {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
    }
    &:hover{
        span{
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
        }
    }
}
</style>
