<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import AddressView from "@/components/masterdata/AddressView.vue";
import SettingsInput from "@/components/settings/SettingsInput.vue";
import EditMasterDataModal from "@/components/masterdata/EditMasterDataModal.vue";
import {useVuelidate} from "@vuelidate/core";
import InvoiceAddress from "@/components/masterdata/InvoiceAddress.vue";
import DownloadUploadContacts from "@/components/masterdata/DownloadUpload.vue";
import DownloadUpload from "@/components/masterdata/DownloadUpload.vue";

export default {
    name: "MasterdataAddress",
    components: {DownloadUpload, DownloadUploadContacts, InvoiceAddress, EditMasterDataModal, SettingsInput, AddressView},
    setup () {
        return { v$: useVuelidate() }
    },
    data(){
        return {
            transactionType: 0, // 0 = Invoice, 1 = Delivery
            transOptions: [
                {type: 0, title: 'Invoice'},
                {type: 1, title: 'Delivery'},
            ],
            communicationType: 1,// 1 = phone, 2 = email
            editModal: {
                inputs: null,
                data: null,
                title: null,
                type: null
            },
            editInvoiceAddress: false,
            editInvoiceContact: false,
        }
    },
    computed: {
        ...mapState({
            customerMasterData: state => state.masterdata.customerMasterData,
            loggedUser: state => state.auth.loggedUser,
        }),
        ...mapGetters({
            loggedPermission: "auth/loggedPermission",
            masterDataPermissions: "auth/masterDataPermissions"
        }),
        canEditInvoice(){
            return this.loggedPermission > 2 || this.masterDataPermissions.canEditClientAddress
        },
        editingInvoice(){
            return this.editInvoiceAddress
        },
        invoiceAddress(){
            return this.customerMasterData?.addresses?.find(a => a.transactionType === 0) || null
        }
    },
    methods:{
        ...mapMutations({
            mutMasterAddress: 'masterdata/mutMasterAddress',
            mutMasterContact: 'masterdata/mutMasterContact',
            mutAddNewContact: 'masterdata/mutAddNewContact',
            mutCloneEditAddress: "masterdata/mutCloneEditAddress",
        }),
        ...mapActions({
            actUpdateCustomerAddress: "masterdata/actUpdateCustomerAddress",
            actCreateNewCustomerAddress: "masterdata/actCreateNewCustomerAddress",
        }),
        resetEditModal(){
            this.mutCloneEditAddress({
                data: null,
                inputs: null,
                title: null,
                type: null
            })
        },
        getAddressPayload(data){
            const {
                contacts,
                accountum,
                d365AddressLocationID,
                transactionType,
                users,
                ...rest
            } = data

            const cntc = data?.id ? {} : contacts.map(c => {
                delete c.id;
                return c
            })
            console.log(accountum, this.customerMasterData, 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
            return {
                payload:{
                    ...rest,
                    ...(data?.id ? {} : {contacts: cntc, transactionType}),
                    customerMasterDataId: accountum || this.customerMasterData.id || this.customerMasterData.customerMasterDataId,
                    userIds: users.map(u => u.id)
                },
                users,
                rawData: data
            }
        },
        async handleModalSave(data){
            const result = await this.v$.$validate()
            console.log(this.v$, 'this.v$ this.v$')
            if(result){
                const {payload, users, rawData} = this.getAddressPayload(data);
                console.log(payload, 'handleModalSave payload')
                let response = '';
                if(data.id){
                    response = await this.actUpdateCustomerAddress({payload, rawData})
                } else {
                    response = await this.actCreateNewCustomerAddress({payload, users})
                }

                if(response === 'success'){
                    document.getElementById('closeEditData').click();
                    this.resetEditModal();
                }
            }
        },
        handleTabChange(type){
            if(!this.editingInvoice){
                this.transactionType = type
            }
        },
        getInvoiceInterface(invoiceInterface) {
            console.log(invoiceInterface, 'invoiceInterface')
            this.$options.invoiceInterface = invoiceInterface;
        },
        handleInvoiceAddressSave(){
            this.$options.invoiceInterface.handleSaveAddress();
        },
        handleCancel(){
            this.$options.invoiceInterface.cloneInvoice();
            this.editInvoiceAddress = !this.editInvoiceAddress;
        },
        handleExcelUpdate({contacts}){
            if(contacts.length){
                this.mutAddNewContact(contacts);
            }
        }
    },
    created() {
        if(this.loggedPermission < 3){
            this.transactionType = 1
        }
    }
}
</script>

<template>
    <div>
        <div class="modal fade" id="EditMasterDataModal" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <EditMasterDataModal
                        :inputs="editModal.inputs"
                        :title="editModal.title"
                        @modal-close="resetEditModal"
                        @save="handleModalSave"
                    />
                </div>
            </div>
        </div>
        <div class="d-flex gap-3 mb-3 align-items-end" v-if="loggedPermission > 2">
            <ul class="nav nav-tabs w-100">
                <li v-for="nav in transOptions" class="nav-item">
                    <div @click="handleTabChange(nav.type)" :class="['nav-link', {'font-primary active': transactionType === nav.type}, {'cursor-pointer': !editingInvoice}]">
                        {{nav.title}} Address
                    </div>
                </li>
            </ul>
            <button :disabled="editInvoiceContact" type="button" v-if="transactionType === 0 && !editInvoiceAddress && canEditInvoice"
                    @click="() => editInvoiceAddress = !editInvoiceAddress"
                    class="max-w-max h-max ml-auto btn btn-sm btn-outline-primary d-flex align-items-center gap-2">
                <i class="fa fa-pencil"></i> Address
            </button>
            <div v-else-if="transactionType === 0 && canEditInvoice"  class="d-flex gap-3 ml-auto">
                <button @click="handleInvoiceAddressSave" class="max-w-max d-flex h-max btn btn-sm btn-success fadeInLeft">
                    Save
                </button>
                <button @click="handleCancel" class="max-w-max h-max btn btn-sm btn-light fadeInLeft">
                    Cancel
                </button>
            </div>
            <download-upload v-else-if="loggedPermission > 2"
                             @dataUploaded="handleExcelUpdate"
                             template-api="/Contact/downloadDeliveryContactTemplate"
                             download-api="downloadDeliveryContacts"
                             upload-api="uploadDeliveryContacts"
                             :customer-id="customerMasterData.id">
                <template #downloadTemplate>
                    Contact Template
                </template>
                <template #download>
                    Export Contact
                </template>
                <template #upload>
                    Import Contact
                </template>
            </download-upload>
        </div>
        <transition-group name="fadeIn" enter-active-class="animated fadeIn">
            <InvoiceAddress v-if="invoiceAddress"
                            v-show="transactionType === 0"
                            key="1-addr"
                            @editInvoiceContact="(val) => editInvoiceContact = val"
                            @addressSaved="handleCancel"
                            @interface="getInvoiceInterface"
                            :invoiceAddress="invoiceAddress"
                            :editInvoiceAddress="editInvoiceAddress"/>
            <address-view v-show="transactionType === 1" key="2-addr"></address-view>
        </transition-group>
    </div>
</template>

<style scoped>
</style>
