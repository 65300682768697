import api from "@/utils/api";

const state = {
    settingsApiData: {},
    loadingApiData: true
}

const getters = {
    gSetting: (state) => (setting) => state.settingsApiData[setting]
}

const mutations = {
    mutSettingsApiData: (state, {api, data}) => {
        state.settingsApiData[api] = data;
    },
    mutEmptyApiData: (state) => {
        state.settingsApiData = {};
    },
    mutLoading: (state, loading) => {
        state.loadingApiData = loading;
    },
    mutUpdateKitData: (state, {data, currChildItems}) => {
        state.settingsApiData['ProductMasterData/getChildItems'] = data.map((d) => {
            const currChild = currChildItems.find(item => d.id === item.childItemId);
            if (currChild) {
                return {
                    itemId: d.id,
                    numberOfChildItems: currChild.numberOfChildItems,
                    numberOfMasterItems: currChild.numberOfMasterItems,
                    urlImage: d.urlImage
                }
            } else {
                return {
                    itemId: d.id,
                    numberOfChildItems: 0,
                    numberOfMasterItems: 0,
                    urlImage: d.urlImage
                }
            }
        });
    }
}

const actions = {
    actUpdateKitItems: async ({commit}, currChildItems) => {
        commit('mutSettingsApiData', {api: 'ProductMasterData/getChildItems', data: []});
        try {
            const {data} = await api.get('/ProductMasterData/getChildItems');
            commit('mutUpdateKitData', {data, currChildItems});
        } catch (e) {
            //
        }
    },
    actGetAllApiData: ({commit}, apiURLs) => {
        commit('mutLoading', true);
        let idx = 1
        for(const apiInfo of apiURLs){
            let url, params;
            if(typeof apiInfo === 'object'){
                url = apiInfo.url;
                params = apiInfo.params;
            } else {
                url = apiInfo
            }
            commit('mutSettingsApiData', {
                api: url,
                data: []
            })
            api.get(`/${url}`, {
                params: {
                    pageNumber: 1,
                    pageSize: 10000,
                    ...(params || {})
                }
            }).then(({data}) => {
                commit('mutSettingsApiData', {
                    api: url,
                    data: data.items
                })
                idx++;
                if(idx === apiURLs.length){
                    commit('mutLoading', false);
                }
            }).catch((e) => {
                // errorToast(e)
            })
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
