<template>
<div class="container-fluid">
    <div class="row">
        <div class="p-0">
            <div class="login-card">
                <div>
                    <div>
                        <div class="logo text-center">
                            <img class="img-fluid for-light" src="../../assets/images/logo/polar-logo.png" alt="looginpage" />
                        </div>
                    </div>
                    <div :class="['login-main position-relative overflow-hidden', {'b-t-success': successfulRegister > 0}, {'b-t-danger': successfulRegister < 0}]">
                        <polar-loader v-if="loading">
                            Logging in
                        </polar-loader>
                        <forget-password :prop-email="email" v-if="showForgetPassword" @back="() => showForgetPassword = false"/>
                        <form v-else class="theme-form" novalidate="" @submit.prevent="handleSignIn">
                            <h4>Sign in to account</h4>
                            <p>Enter your email & password to login</p>
                            <div class="form-group">
                                <label class="col-form-label">Email Address*</label>
                                <input v-model="email"
                                       :class="[
                                            'form-control',
                                            `${formSubmitted ? emailError ? 'is-invalid' : 'is-valid' : ''}`
                                       ]"
                                       type="email" required="" placeholder="user@email.com" />
                                <div class="invalid-feedback">{{ errors.email }}</div>
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">Password*</label>
                                <div class="form-input position-relative">
                                    <input v-model="password"
                                       :class="[
                                            'form-control',
                                            `${formSubmitted ? passwordError ? 'is-invalid' : 'is-valid' : ''}`
                                       ]"
                                       :type="active?'password':'text'" name="login[password]"  required="" placeholder="*********" />
                                    <div class="show-hide"
                                         style="padding-right: 10px;">
                                        <span :class="active?'show':'hide'" @click.prevent="show"></span></div>
                                </div>
                                <div class="invalid-feedback d-block">{{ errors.password }}</div>
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">Please confirm*</label>
                                <div class="g-recaptcha" data-sitekey="6LeQ04QpAAAAAJBnlfoa7mrn5IHzgmbgv6gaccbz"></div>
                                <div class="invalid-feedback d-block">{{captchaError}}</div>
                            </div>
                            <p class="mb-2 mt-0 text-end">
                                <a class="cursor-pointer" @click="() => showForgetPassword = true">
                                    Forgot password?
                                </a>
                            </p>
                            <div class="form-group mb-0">
<!--                                <div class="checkbox p-0">-->
<!--                                    <input id="checkbox1" type="checkbox" />-->
<!--                                    <label class="text-muted" for="checkbox1">Remember password</label>-->
<!--                                </div>-->
<!--                                <router-link to="/">-->
                                    <button class="btn btn-primary btn-block w-100"
                                            type="submit">
                                        Sign in
                                     </button>
<!--                                </router-link>-->
                            </div>
<!--                            <h6 class="text-muted mt-4 or">Or Sign in with</h6>-->
<!--                            <div class="social mt-4">-->
<!--                                <div class="btn-showcase"><a class="btn btn-light" href="#" ><vue-feather class="txt-linkedin" type="linkedin"></vue-feather> LinkedIn </a><a class="btn btn-light" href="#" ><vue-feather class="txt-twitter" type="twitter"></vue-feather>twitter</a><a class="btn btn-light" href="#" ><vue-feather class="txt-fb" type="facebook"></vue-feather>facebook</a></div>-->
<!--                              </div>-->
                            <p class="mt-4 mb-0 text-center">
                                Don't have an account?
                                <router-link tag="a" to="/auth/register">
                                    Register now
                                </router-link>
                            </p>
                        </form>
                        <div v-if="successfulRegister !== 0" class="absoluteCenter w-100 h-100 bg-white d-flex flex-column" style="padding: 40px;">
                            <h4 :class="[{'text-success': successfulRegister > 0},{'text-danger': successfulRegister < 0},]">{{authResponse.title}}</h4>
                            <p v-html="authResponse.description"></p>
                            <button v-if="successfulRegister < 0" class="btn btn-primary mt-auto mx-auto" @click="() => successfulRegister = 0">Try again!</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import PolarLoader from "@/components/PolarLoader.vue";
import loginValidation from "@/mixins/validations/loginValidation";
import ForgetPassword from "@/components/auth/ForgetPassword.vue";
import api from "@/utils/api";
import {useToast} from "vue-toastification";
export default {
    components: {ForgetPassword, PolarLoader},
    mixins: [loginValidation],
    data(){
        return{
            active: true,
            email: '',
            password: '',
            loading: false,
            successfulRegister: 0,
            showForgetPassword: false,
            captchaError: ''
        }
    },
    computed:{
        authResponse(){
            return this.$store.state.auth.authResponse
        }
    },
    methods:{
        async handleSignIn(){
            this.validateLoginForm({password:this.password, email: this.email});

            if(this.loginFormIsValid){
                this.loading = true;
                const verified = await this.verifyCaptcha();
                if(verified){
                    console.log('verifications passed');
                    await this.$store.dispatch('auth/actLogin', {email: this.email, password: this.password});
                    const user = this.$store.state.auth.loggedUser;
                    if(user){
                        this.$store.dispatch('product/actGetCart', user.id);
                        this.$router.push('/');
                    } else {
                        this.successfulRegister = -1;
                    }
                }
            }
            this.loading = false;
        },
        async verifyCaptcha(){
            try {
                const recaptchaResponse = grecaptcha.getResponse();
                console.log('recaptchaResponse::', recaptchaResponse);
                if(recaptchaResponse) {
                    const {data} = await api.post('/captcha', null, {
                        params: {
                            token: recaptchaResponse
                        }
                    })
                    if(data) return data
                } else {
                    this.captchaError = 'Please verify you are human'
                }
            } catch (e) {
                // const {data} = e.response
                // useToast().error(`${data.errors[0]?.description}`);
                if(grecaptcha){
                    grecaptcha.reset();
                }
            }
            return false;
        },
        show(){
            this.active=!this.active
        }
    },
    mounted() {
        const recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute(
            "src",
            "https://www.google.com/recaptcha/api.js"
        );
        document.head.appendChild(recaptchaScript);
    },
    unmounted() {
        this.$store.commit('auth/mutSetState', {key: 'authResponse', value: {title: '', description: ''}})
    }
}
</script>
