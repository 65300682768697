<script>
import OrderInfo from "@/components/order/SimpleTable.vue";
import {format} from "date-fns";
import SimpleTable from "@/components/order/SimpleTable.vue";
import {separateOnCapitalLetter} from "@/utils/helperFunctions";
import {colTitle} from "@/constants/tableSettings";

export default {
    name: "OrderAccordion",
    components: {SimpleTable, OrderInfo},
    props: ['order'],
    computed: {
        orderDate() {
            return format(this.order.createdOnUtc, 'dd MMMM yyyy')
        },
        orderUI() {
            return [
                "id",
                // "orderingCustomerAccountNumber",
                "d365OrderId",
                "createdOnUtc",
                "email",
                "vatTotal",
                "total",
                "totalWithoutVat",
                "customerMasterDataId",
                "invoiceCustomerAccountNumber",
                "dlvModeCode",
                "dlvTermCode",
                "deliveryAddress",
                "inventLocationName",
                "inventSiteName",
                "salesPoolName",
                // "status",
                // "d365OrderId",
                // "createdByRole",
                // "createdBy",
                // "comments",
                // "customerRefNo",
                // "invoiceAddress",
                // "currency",
            ]
        },
        productUI() {
            return [
                "productId",
                "externalItemNumber",
                "lineAmount",
                "lineDiscount",
                "linePercent",
                "priceUnit",
                "salesPrice",
                "vatAmount",
                "vatPercent",
                "quantity",
                "currency",
                "unitOfMeasure"
            ]
        }
    },
    methods:{
        orderUILabels(key){
            const labels = {
                id: 'Portal Id',
                customerMasterDataId: 'Portal Customer ID',
                orderingCustomerAccountNumber: 'D365 Customer ID',
            }
            return labels[key] || colTitle(key);
        },
    }
}
</script>

<template>
    <div class="default-according style-1" id="orderAccordion">
        <div class="card mb-4">
            <div
                class="card-header cursor-pointer bg-primary"
                style="padding: 20px !important;"
                data-bs-toggle="collapse"
                data-bs-target="#order-info"
                aria-expanded="true"
                aria-controls="order-info"
            >
                <div class="d-flex">
                    <div>
                        <div class="d-flex align-items-center">
                            <i class="fa fa-tag"></i>
                            <h6 class="card-title">Order Information</h6>
                        </div>
                        <p>
                            Order Date: {{ orderDate }}
                        </p>
                    </div>
                    <button class="btn max-w-max btn-white ml-auto" style="height: fit-content">
                        <i class="fa fa-eye"></i>
                        Details
                    </button>
                </div>

            </div>
            <div id="order-info"
                 class="collapse show"
                 aria-labelledby="order-info">
                <div class="card-body">
                    <simple-table v-if="order" :columns="orderUI" :data="[order]">
                        <template #columnHead="{column}">
                            {{orderUILabels(column)}}
                        </template>
                    </simple-table>
                </div>
            </div>
        </div>
        <div v-for="product in order?.saleOrderLines" class="card">
            <div
                class="card-header cursor-pointer bg-primary"
                style="padding: 20px !important;"
                data-bs-toggle="collapse"
                data-bs-target="#product-info"
                aria-expanded="true"
                aria-controls="product-info"
            >
                <div class="d-flex gap-4 align-items-center">
                    <img :src="product.urlImageThumb"
                         style="width: 80px"
                         class="bg-img"
                         alt="product image"/>
                    <div class="d-flex flex-column">
                        <div class="d-flex mb-2 align-items-start">
                            <i class="fa fa-tag"></i>
                            <h6>{{ product.productId }}</h6>
                        </div>
                        <div class="f-w-600">{{ product.productName }}</div>
                        <p>
                            Order Date: {{ orderDate }}
                        </p>
                    </div>
                    <button class="btn max-w-max btn-white ml-auto">
                        <i class="fa fa-eye"></i>
                        Details
                    </button>
                </div>
            </div>
            <div id="product-info"
                 class="collapse show"
                 aria-labelledby="product-info">
                <div class="card-body">
                    <simple-table :columns="productUI" :data="[product]"/>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
