<script>
import DashboardNotifications from "@/components/dashboard/DashboardNotifications.vue";
import HomeCard from "@/components/homeCard.vue";
import {mapGetters} from "vuex";
import api from "@/utils/api";
import {downloadReport} from "@/utils/helperFunctions";
import {compareAsc} from "date-fns";
import ProggressButton from "@/components/ProggressButton.vue";
import DropdownSearch from "@/components/uiKits/DropdownSearch.vue";
import SettingsInput from "@/components/settings/SettingsInput.vue";
import {helpers} from "@vuelidate/validators";

const cards = [
    {
        "title": "Credit Limit Changes",
        "description": "Changes that will be made to be date stamped and availability to run retrospective reports.",
        "pathText": "Download Credit Limit Change History",
        "color": "warning",
        "api": "/CustomerMasterData/downloadCreditLimitChanges"

    },
    {
        "title": "Order Over Limit History",
        "description": "Any time any account goes over order limit to be recorded on database and availability to run reports showing each time any account went over order limit within a date range.",
        "color": "danger",
        "api": "/SaleOrderHeader/downloadOverOrderLimit"
    },
    {
        "title": "Registration Form Received Date",
        "description": "Registration Form Received Date.",
        "color": "primary",
        "api": "/CustomerMasterData/downloadCustomersRegistrationDate"
    },
    {
        "title": "Accounts Creation Date",
        "description": "Accounts Creation Date.",
        "color": "info",
        "api": "/CustomerMasterData/downloadAccountCreationDates"
    },
    {
        "title": "Account Validation Delays",
        "description": "Ability to run a report that shows the accounts that have not been created within 24h after received date(excluding weekends).",
        "color": "success",
        "api": "/CustomerMasterData/downloadAccountValidationDelays"
    },
    {
        "title": "Manual Order Creation",
        "description": "To track if the orders are done manually , (by customer service) on web portal or through PMR system.",
        "color": "danger",
        "api": "/SaleOrderHeader/downloadManualCreatedOrders"
    },
    {
        "title": "Delivery Address Changes",
        "description": "Track changes on delivery address.",
        "color": "warning",
        "api": "/Address/downloadDeliveryAddressChanges"
    },
]
export default {
    name: "chargeable_activities",
    data(){
        return {
            currentDownloadingApi: [],
            filterDates: {
                startDate: null,
                endDate: null,
            },
            customers: [],
            users: [],
            selectedCustomer: null,
            selectedUser: null,
        }
    },
    methods: {
        async getUsers(){
            try {
                const {data} = await api.get('/user', {
                    params: {
                        pageNumber: 1,
                        pageSize: 10000
                    }
                })
                const {items} = data;
                this.users = items;
            } catch (e) {

            }
        },
        async getCustomers(){
            try {
                const {data} = await api.get('/CustomerMasterData', {
                    params: {
                        pageNumber: 1,
                        pageSize: 10000
                    }
                })

                this.customers = data.items
            } catch (e) {
                // errorToast(e);
            }
        },
        async handleDownload(downloadApi){
            console.log(downloadApi)
            try {
                this.currentDownloadingApi.push(downloadApi)
                const {data} = await api.post(downloadApi, null, {
                    params: this.downloadParamsC
                });
                console.log(data)
                downloadReport({
                    data: data.fileContents,
                    type: data.contentType,
                    fileName: data.fileDownloadName
                })
            } catch (e) {
                console.log(e, 'eeeeee')
            } finally {
                this.currentDownloadingApi = this.currentDownloadingApi.filter(a => a !== downloadApi)
            }
        },
        endDateValidation(endDate){
            if(endDate){
                return this.validateDates >= 0;
            }
            return true;
        }
    },
    components: {SettingsInput, DropdownSearch, ProggressButton, HomeCard, DashboardNotifications},
    computed:{
        helpers() {
            return helpers
        },
        ...mapGetters({
            loggedPermission: "auth/loggedPermission"
        }),
        cards(){
            return cards;
        },
        downloadParamsC(){
            return {
                startDate: this.filterDates.startDate || null,
                endDate: this.filterDates.endDate || null,
                userEmail: this.selectedUser?.email || null,
                customerMasterDataId: this.selectedCustomer?.id || null
            }
        },
        validateDates(){
            const {startDate, endDate} = this.filterDates
            if(startDate && endDate){
                return compareAsc(endDate, startDate);
            }
        }
    },
    created() {
        this.getCustomers();
        this.getUsers();
    }
}
</script>

<template>
    <div class="container-fluid">
        <Breadcrumbs main="Chargeable Activities"/>
        <div class="row starter-main">
            <div class="d-flex flex-wrap gap-3 align-items-start mt-3">
                <dropdown-search :options="customers"
                                 :id="'filterDownloadCustomer'"
                                 :canRemove="true"
                                 :display-key="'id'"
                                 :filter-keys="['id', 'organizationName']"
                                 placeholder="Search Customer Data"
                                 v-model="selectedCustomer">
                    <template #option="{data: {id, organizationName}}">
                        <div class="d-flex justify-content-between gap-5">
                            <div>
                                {{organizationName}}
                            </div>
                            <div>
                                {{id}}
                            </div>
                        </div>
                    </template>
                </dropdown-search>
                <dropdown-search :options="users"
                                 :canRemove="true"
                                 :id="'filterDownloadUsers'"
                                 placeholder="Search Users"
                                 :display-key="'fullName'"
                                 :filter-keys="['fullName', 'email']"
                                 v-model="selectedUser">
                    <template #option="{data: {fullName, email}}">
                        <div class="d-flex justify-content-between gap-5">
                            <div>
                                {{fullName}}
                            </div>
                            <div>
                                {{email}}
                            </div>
                        </div>
                    </template>
                </dropdown-search>
                <settings-input
                    :input="{
                        type: 'date',
                        label: '',
                        placeholder: 'Start Date',

                        notRequired: true
                    }"
                    v-model="filterDates.startDate"
                />
                <settings-input
                    :input="{
                        type: 'date',
                        label: '',
                        validation: {endDateBigger: helpers.withMessage('End Date must be greater than start date', endDateValidation)},
                        placeholder: 'End Date',
                        notRequired: true
                    }"
                    v-model="filterDates.endDate"
                />
            </div>
            <p class="font-danger">If no filters are chosen, the downloaded data will be specific to the current month and cover all users and customers.</p>
            <div v-for="(card, idx) in cards" class="col-12 col-sm-6 col-xl-3 p-1">
                <home-card :content="card">
                    <template #action="{data: {api, color}}">
                        <proggress-button
                            style="margin: 0 !important;"
                            :loading="currentDownloadingApi.includes(api)"
                            @click="handleDownload(api)" :btnClass="`btn btn-primary`">
                            <div class="d-flex gap-2 align-items-center">
                                <i class="fa fa-download"/>
                                Download
                            </div>
                        </proggress-button>
                    </template>
                </home-card>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
