<script>
import EditUser from "@/components/user/EditUser.vue";
import {mapMutations, mapState} from "vuex";

export default {
    name: "edit",
    components: {EditUser},
    methods:{
        ...mapMutations({
            mutSetState: 'user/mutSetState'
        }),
        handleCancel(){
            this.$router.push('/user/settings')
        },
        handleUserCreated(){
            this.$router.push('/user/settings')
        },
    },
    computed:{
        ...mapState({
            selectedUserEmail: state => state.user.selectedUserEmail
        })
    },
    beforeUnmount() {
        this.mutSetState({
            key: 'selectedUserEmail',
            value: null
        });
    }
}
</script>

<template>
    <div>
        <Breadcrumbs main="User Settings" mainPath="/user/settings" :title="`${selectedUserEmail ? 'Edit User' : 'Create User'}`" />
        <div class="container">
            <div class="row">
                <EditUser
                    @editCanceled="handleCancel()"
                    @userCreated="handleUserCreated"
                />
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
