<script>
import {downloadReport, errorToast, getFileFormData, ordinal_suffix_of} from "@/utils/helperFunctions";
import api from "@/utils/api";
import ProggressButton from "@/components/ProggressButton.vue";
import {useToast} from "vue-toastification";

export default {
    name: "DownloadUpload",
    components: {ProggressButton},
    props: ['customerId', 'uploadApi', 'downloadApi', 'errorKey', 'templateApi'],
    data(){
        return{
            uploading: false,
            downloading: false,
            downloadingTemplate: false,
        }
    },
    methods:{
        async handleDlvContactUpload(e){
            this.uploading = true;
            const file = e.target.files[0];
            const form = await getFileFormData(file)
            console.log(form)
            try {
                const {data} = await api.post(`CustomerMasterData/${this.uploadApi}/${this.customerId}`, form, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                const {errors, rowId} = data?.[this.errorKey]?.[0] || {}
                if(errors?.length && rowId){
                    useToast().error(`There's an error in the ${ordinal_suffix_of(rowId)} row! ${errors[0]?.description}!`)
                } else {
                    useToast().success('Document was imported successfully')
                }
                this.$emit('dataUploaded', data)
            } catch (e) {

            } finally {
                this.uploading = false;
            }
        },
        async handleDlvContactDownload(){
            this.downloading = true;
            try {
                const {data} = await api.post(`/CustomerMasterData/${this.downloadApi}/${this.customerId}`);
                downloadReport({
                    data: data.fileContents,
                    type: data.contentType,
                    fileName: data.fileDownloadName
                })
            } catch (e) {
            } finally {
                this.downloading = false;
            }
        },
        async handleTemplateDownload(){
            this.downloadingTemplate = true;
            try {
                const {data} = await api.post(this.templateApi);
                downloadReport({
                    data: data.fileContents,
                    type: data.contentType,
                    fileName: data.fileDownloadName
                })
            } catch (e) {
            } finally {
                this.downloadingTemplate = false;
            }
        },
        async handleChooseFile(){
            const fileInput = document.getElementById(this.uploadApi);
            fileInput.click();
        },
    }
}
</script>

<template>
    <div class="d-flex gap-2">
        <proggress-button :loading="downloadingTemplate"
                          btnClass="btn-primary"
                          :active="true"
                          @click="handleTemplateDownload"
        >
            <div class="d-flex gap-1 align-items-center">
                <i class="fa fa-download"></i>
                <slot name="downloadTemplate">
                    download
                </slot>
            </div>
        </proggress-button>
        <proggress-button :loading="downloading"
                          btnClass="btn-primary"
                          :active="true"
                          @click="handleDlvContactDownload"
        >
            <div class="d-flex gap-1 align-items-center">
                <i class="fa fa-download"></i>
                <slot name="download">
                    download
                </slot>
            </div>
        </proggress-button>
        <proggress-button :loading="uploading"
                          :active="true"
                          @click="handleChooseFile"
        >
            <div class="d-flex gap-1 align-items-center">
                <i class="fa fa-upload"></i>
                <slot name="upload">
                    upload
                </slot>
            </div>
        </proggress-button>
        <input @change="handleDlvContactUpload" :id="uploadApi" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" hidden/>
    </div>
</template>

<style scoped>

</style>
