<template>
    <div>
        <div class="page-wrapper">
            <div class="container-fluid p-0">
                <div class="authentication-main mt-0 ">
                    <div class="row">
                        <div class="col-12">
                            <div class="login-card">
                                <div>
                                    <div>
                                        <a class="logo">
                                            <img class="img-fluid for-light" src="../../assets/images/logo/polar-logo.png"
                                                alt="looginpage" />
                                        </a>
                                    </div>
                                    <div :class="['login-main position-relative overflow-hidden', {'b-t-success': emailConfirmed}, {'b-t-danger': !emailConfirmed && !loading}]">
                                        <div class="theme-form" style="height: 300px;">
                                            <h4 class="text-center fw-semibold" v-if="loading">Confirming your email</h4>
                                            <div class="loader-box" v-if="loading">
                                                <div class="loader-1"></div>
                                            </div>
                                            <div v-else class="absoluteCenter w-100 h-100 bg-white d-flex flex-column" style="padding: 40px;">
                                                <h4 :class="[{'text-success': emailConfirmed},{'text-danger': !emailConfirmed},]">
                                                    {{authResponse.title}}
                                                </h4>
                                                <p v-html="authResponse.description"></p>

                                                <router-link to="/login" class="mx-auto mt-3">
                                                    Back to login
                                                </router-link>
                                            </div>
<!--                                            <div class="form-group">-->
<!--                                                <label class="col-form-label">Enter your Password</label>-->
<!--                                                <div class="form-input position-relative">-->

<!--                                                    <input class="form-control" :type="active ? 'password' : 'text'"-->
<!--                                                        name="login[password]" required="" placeholder="*********" />-->
<!--                                                    <div class="show-hide"><span :class="active ? 'show' : 'hide'"-->
<!--                                                            @click.prevent="show"> </span></div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                            <div class="form-group mb-0">-->
<!--                                                <div class="checkbox p-0">-->
<!--                                                    <input id="checkbox1" type="checkbox" />-->
<!--                                                    <label class="text-muted" for="checkbox1">Remember password</label>-->
<!--                                                </div>-->
<!--                                                <button class="btn btn-primary btn-block" type="submit">-->
<!--                                                    Unlock-->
<!--                                                </button>-->
<!--                                            </div>-->
<!--                                            <p class="mt-4 mb-0">-->
<!--                                                Already Have an account?-->
<!--                                                <router-link class="ms-2" tag="a" to="/auth/login">-->
<!--                                                    Login-->
<!--                                                </router-link>-->
<!--                                            </p>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import PolarLoader from "@/components/PolarLoader.vue";

export default {
    components: {PolarLoader},
    data() {
        return {
            active: true,
            loading: true,
            token: "",
            userId: "",
        }
    },
    computed:{
        emailConfirmed(){
            return this.$store.state.auth.emailConfirmed
        },
        authResponse(){
            return this.$store.state.auth.authResponse
        },
    },
    methods: {
        show() {
            this.active = !this.active
        },
        getURLInfo(){
            const {userId} = this.$route.params;
            const {fullPath} = this.$route;
            this.token = fullPath.split("token=")[1];
            this.userId = userId;
            // this.token = token;
        },
        async confirmEmail(){
            console.log(this.token)
            await this.$store.dispatch('auth/actConfirmEmail', {
                id: this.userId,
                token: this.token
            })
            this.loading = false;
        }
    },
    mounted() {
        console.log('mounted reset password', this.$route)
        this.getURLInfo();
        this.confirmEmail();

    }
}
</script>
