import {validateEmail} from "@/utils/helperFunctions";

export default{
    data() {
        return {
            formSubmitted: false,
            errors: {
                email: ''
            },
            emailError: false,
        }
    },
    computed:{
        emailIsValid(){
            return !this.emailError;
        }
    },
    methods: {
        validateEmailForm({email}) {
            this.formSubmitted = true;
            if(validateEmail(email)){
                this.emailError = false;
                this.errors.email = '';
            } else {
                this.emailError = true;
                this.errors.email = 'Please enter a valid email';
            }
        },

    }
}
