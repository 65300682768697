<script>

import terms from "@/data/terms.json";

export default {
    name: "terms",
    data(){
        return {
            terms: terms.terms
        }
    }
}
</script>

<template>
    <div class="container">
        <div class="m-t-50 text-center">
            <h2>Terms and Conditions of Sale</h2>
            <p>Abbott Terms and Conditions for Sale of the FreeStyle Libre System to Pharmacy Accounts</p>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <div class="mb-3">
                    <b>Effective Date of the Terms:</b> 9th December 2022
                </div>
                <div class="mb-5">
                    <b>Last Updated:</b> 9th December 2022
                </div>
            </div>
            <div class="col-12">
                <div class="row mb-4" v-for="(term, idx) in terms" :key="idx + 'term'">
                    <div class="d-flex gap-2 mb-3">
                        <span>{{idx + 1}}. </span>
                        <b>{{ term.title }}</b>
                        <span class="text-justify">{{term.description}}</span>
                    </div>
                    <div v-for="(point, idxP) in term.items" :key="idxP + 'point'" class="d-flex gap-2 mb-3">
                        <span v-if="term.items.length > 1">{{idx + 1}}.{{idxP + 1}} </span>
                        <span v-html="point" class="text-justify"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
