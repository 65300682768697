import {createRouter, createWebHistory} from "vue-router"
import Body from '../components/body';
import Default from '../pages/dashboard/defaultPage.vue';
import Products from "@/pages/products/products.vue";
import login_one from "@/pages/authentication/login_one.vue";
import register from "@/pages/authentication/register.vue";
import reset_password from "@/pages/authentication/reset_password.vue";
import userSettings from "@/pages/user/userSettings.vue";
import settings from "@/pages/settings/settings.vue";
import unlock from "@/pages/authentication/unlock.vue";
import error404 from "@/pages/error/error404.vue"
import store from '@/store/index'
import edit from "@/pages/user/edit.vue";
import account from "@/pages/user/account.vue";
import setting_table from "@/pages/settings/setting_table.vue";
import single_product from "@/pages/products/single_product.vue";
import user_notifications from "@/pages/user/user_notifications.vue";
import faq from "@/pages/faq/faq.vue";
import terms from "@/pages/terms.vue";
import contact from "@/pages/contact.vue";
import master_data from "@/pages/masterdata/master_data.vue";
import edit_masterdata from "@/pages/masterdata/edit_masterdata.vue";
import validation_status from "@/pages/masterdata/validation_status.vue";
import custErrorLogs from "@/pages/custErrorLogs.vue";
import shop_list from "@/pages/shop/shop_list.vue";
import checkout from "@/pages/shop/checkout.vue";
import orderErrorLogs from "@/pages/orderErrorLogs.vue";
import orders from "@/pages/products/orders.vue";
import address_validation_status from "@/pages/masterdata/address_validation_status.vue";
import chargeable_activities from "@/pages/chargeable_activities.vue";
import lock_screen from "@/pages/authentication/lock_screen.vue";
import dashboard_kit from "@/pages/products/dashboard_kit.vue";

const routes =[
    {
        path: '/',
        component: Body,
        children: [
            {
                path: '',
                name: 'defaultRoot',
                component: Default,
                meta: {
                    title: 'Dashboard'
                }
            },
            {
                path: 'chargeable-activities',
                name: 'chargeableActivities',
                component: chargeable_activities,
                meta: {
                    title: 'Chargeable Activities'
                }
            },
            {
                path: 'faq',
                name: 'FAQ',
                component: faq,
                meta: {
                    title: 'FAQ'
                }
            },
            {
                path: 'master-data',
                name: 'Master Data',
                children: [
                    {
                        path: '',
                        name: 'MasterData',
                        component: master_data,
                        meta: {
                            title: 'Customer Master Data'
                        }
                    },
                    {
                        path: 'validation-status',
                        name: 'ValidationStatus',
                        component: validation_status,
                        meta: {
                            title: 'Customer Validation Status'
                        }
                    },
                    {
                        path: 'address-validation-status',
                        name: 'AddressValidationStatus',
                        component: address_validation_status,
                        meta: {
                            title: 'Address Validation Status'
                        }
                    },
                    {
                        path: ':customerId',
                        name: 'Edit MasterData',
                        component: edit_masterdata,
                        meta: {
                            title: 'Edit Customer Master Data'
                        }
                    },
                ]
            },
            {
                path: 'products',
                name: 'products',
                children: [
                    {
                        path: '',
                        name: 'Products',
                        component: Products,
                        meta: {
                            title: 'Products',
                        }
                    },
                    {
                        path: 'kit',
                        name: 'DashboardKit',
                        component: dashboard_kit,
                        meta: {
                            title: 'Dashboard Kit',
                        }
                    },
                    {
                        path: ':product',
                        component: single_product,
                        name: 'Single Product',
                        meta: {
                            title: 'Single Product',
                        }
                    }
                ]
            },
            {
                path: 'settings',
                children: [
                    {
                        path: '',
                        name: 'Settings',
                        component: settings,
                        meta: {
                            title: 'Settings',
                        }
                    },
                    {
                        path: ':setting',
                        component: setting_table,
                        name: 'Settings Table',
                        meta: {
                            title: 'Settings Table',
                        }
                    }
                ]
            },
            {
                path: 'shopping-cart',
                name: 'Shopping List',
                component: shop_list,
                meta: {
                    title: 'Shopping List'
                }
            },
            {
                path: 'checkout',
                name: 'Checkout',
                component: checkout,
                meta: {
                    title: 'Checkout'
                }
            },
            {
                path: 'order-history',
                name: 'Order History',
                component: orders,
                meta: {
                    title: 'Order History'
                }
            },
            {
                path: 'user',
                children: [
                    {
                        path: 'settings',
                        name: 'User Settings',
                        component: userSettings,
                        meta: {
                            title: 'User Settings',
                        }
                    },
                    {
                        path: 'edit',
                        redirect: 'user/create',
                        children: [
                            {
                                path: ':userEmail',
                                name: 'User Edit',
                                component: edit,
                                meta: {
                                    title: 'User Editor',
                                }
                            }
                        ],
                    },
                    {
                        path: 'create',
                        name: 'User Create',
                        component: edit,
                        meta: {
                            title: 'Create New User'
                        }
                    }
                ]
            },

            {
                path: 'account',
                children: [
                    {
                        path: '',
                        name: 'User Account',
                        component: account,
                        meta: {
                            title: 'User Account',
                        }
                    },
                    {
                        path: 'notification-dashboard',
                        name: 'Notification Settings',
                        component: user_notifications,
                        meta: {
                            title: 'User Notifications',
                        }
                    },
                ]
            },
            {
                path: 'support',
                name: 'Support',
                component: contact,
                meta: {
                    title: 'Support'
                }
            },
            {
                path: 'customer-error-logs',
                name: 'Customer Error Logs',
                component: custErrorLogs,
                meta: {
                    title: 'Customer Error Logs'
                }
            },
            {
                path: 'order-error-logs',
                name: 'Order Error Logs',
                component: orderErrorLogs,
                meta: {
                    title: 'Order Error Logs'
                }
            }
        ]
    },
    // {
    //     path: '/',
    //     component: Body,
    //     children: [
    //         {path: '/products', name: 'products', component: Products}
    //     ]
    // },
    {
        path: '/auth',
        children: [
            {
                path: 'login',
                name: 'Login',
                component: login_one,
                meta: {
                    title: 'Login | Polar Speed',
                }
            },
            {
                path: 'register',
                name: 'Register',
                component: register,
                meta: {
                    title: 'Register | Polar Speed',
                }
            },
            {
                path: 'email/:email',
                name: 'Reset Password',
                component: reset_password,
                meta: {
                    title: 'Reset Password | Polar Speed',
                }
            },
            {
                path: ':userId',
                name: 'Set New Password',
                component: unlock,
                meta: {
                    title: 'Set New Password | Polar Speed',
                }
            },
            {
                path: 'lock-screen',
                name: 'Lock Screen',
                component: lock_screen,
                meta: {
                    title: 'Lock Screen | Polar Speed'
                }
            }
        ]
    },
    {
        path: '/terms-and-conditions',
        name: 'Terms And Conditions',
        component: terms,
        meta: {
            title: 'Terms and Conditions'
        }
    },
    { path: '/:pathMatch(.*)', component: error404 }
]
const router= createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    const user = !!store.state.auth.loggedUser;
    if(to.meta?.title)
        document.title = to.meta.title;

    if(to.path === '/terms-and-conditions' ||to.path === '/support')
        return next();

    const masterDataPermissions = store.getters["auth/masterDataPermissions"];

    const rolePaths = [
        {path: '/user/settings', reqPermission: 3},
        {path: '/settings', reqPermission: 3},
        {path: '/customer-error-logs', reqPermission: 3},
        {path: '/master-data/validation-status', reqPermission: 3},
        {path: '/chargeable-activities', reqPermission: 3},
        {path: '/master-data', reqPermission: 0},
        {path: '/account/notification-dashboard', reqPermission: 3},
    ]

    const mainRolePaths = [
        {path: 'settings', reqPermission: 3},
        {path: 'products', reqPermission: 1},
    ]

    const splitPath = to.path.split("/");

    if(splitPath[1] === 'auth'){
        if(splitPath[2] === 'email'){
            store.dispatch('auth/actLogOut', {noRedirect: true})
            return next();
        }
        if(user){
            return next('/')
        }
        return next();
    }

    if(user){
        const userPermission = store.getters["auth/loggedPermission"] || 0;
        mainRolePaths.forEach(item => {
            if(item.path === splitPath[1]){
                console.log(userPermission, 'userPermission')
                if(userPermission < item.reqPermission)
                    return next('/');
            }
        })

        for(const p of rolePaths){
            if(to.path === p.path){
                if(p.masterDataPermissions){
                    if(!p.masterDataPermissions.canViewClientAddress){
                        return next('/')
                    }
                }
                if(p.reqPermission > userPermission)
                    return next('/');
            }
        }
        return next();
    }

    next('/auth/login');
});

export default router
