<script>
export default {
    name: "UserTitle",
    props: ['error', 'showValidation', 'modelValue'],
    data(){
        return {
            titleOptions: [
                'Ms',
                'Miss',
                'Mr',
                'Mrs',
            ],
        }
    },
    methods:{
        handleSelectChange(e){
            this.$emit('update:model-value', e.target.value);
        }
    }
}
</script>

<template>
    <label class="col-form-label" for="title">Title*</label>
    <select id="title"
            :value="modelValue"
            @change="handleSelectChange"
            :class="['form-select', (showValidation ? error ? 'is-invalid' : 'is-valid' : '')]"
            required="">
        <option selected="" disabled="" value="">Title...</option>
        <option v-for="opt in titleOptions" :key="opt">{{ opt }}</option>
    </select>
    <div class="invalid-feedback">{{ error }}</div>
</template>

<style scoped>

</style>
