<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media align-items-center">
        <div class="rounded-circle bg-primary d-flex position-relative" style="width: 40px; height: 40px;">
            <i class="fa fa-user-md position-absolute" style="font-size: 26px;left: 10px;top: 5px;"/>
        </div>
<!--      <img style="width: 40px;" src="@/assets/images/user/user.png" alt="" />-->
      <div class="media-body">
        <span>{{ `${loggedUser?.firstName} ${loggedUser?.lastName}` }}</span>
        <p class="mb-0 font-roboto">
            {{ loggedUser?.role }} <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li v-for="link in links" v-show="loggedPermission >= link.permission">
          <router-link :to="link.path">
              <vue-feather :type="link.icon"/>
              <span>{{ link.title }} </span>
          </router-link>
      </li>
      <li @click="handleLockScreen">
        <vue-feather type="lock"></vue-feather><span>LOCK SCREEN</span>
      </li>
      <li @click="handleLogOut">
        <vue-feather type="log-in"></vue-feather><span class="">LOG OUT</span>
      </li>
    </ul>
  </li>
</template>

<script>
// import firebase from 'firebase';
// import UserAuth from '../../auth/js/index';

import {mapGetters} from "vuex";

export default {
    name: 'Profile',
    methods: {
        handleLogOut: function () {
            this.$store.dispatch('auth/actLogOut');
        },
        handleLockScreen(){
            this.$store.dispatch('auth/actLockScreen');
            // const user = {...this.loggedUser};
            // this.$store.dispatch('auth/actLogOut', {lockScreen: true});
            // localStorage.clear();
            // localStorage.setItem('lockScreenUser', JSON.stringify({
            //     email: user.email,
            //     title: `${user?.title}`,
            //     lastName: `${user?.lastName}`,
            // }))
            // this.$router.push('/auth/lock-screen');
        }
    },
    computed:{
        ...mapGetters({
            loggedPermission: "auth/loggedPermission"
        }),
        loggedUser(){
            return this.$store.state.auth.loggedUser;
        },
        links(){
            return [
                {icon: 'user', title: 'Account', path: '/account', permission: 0},
                {icon: 'alert-triangle', title: 'Customer Interface Log Table', path: '/customer-error-logs', permission: 3},
                {icon: 'alert-triangle', title: 'Sales Order Interface Log Table', path: '/order-error-logs', permission: 3},
                {icon: 'activity', title: 'Chargeable Activities', path: '/chargeable-activities', permission: 3},
                {icon: 'settings', title: 'Settings', path: '/settings', permission: 3},
            ]
        }
    }
};
</script>
<style>
.profile-dropdown{
    width: 220px !important;
    left: -40px !important;
}
</style>
