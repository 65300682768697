<script>
import Breadcrumbs from "@/components/bread_crumbs.vue";
import {FormWizard, TabContent} from 'vue3-form-wizard'
import 'vue3-form-wizard/dist/style.css'
import {addressInputs} from "@/constants/masterData";
import SettingsInput from "@/components/settings/SettingsInput.vue";
import {mapActions, mapState} from "vuex";
import InvoiceAddresCheckout from "@/components/shop/InvoiceAddresCheckout.vue";
import BasketSummary from "@/components/shop/BasketSummary.vue";
import DlvAddressCheckout from "@/components/shop/DlvAddressCheckout.vue";
import FinishOrder from "@/components/shop/FinishOrder.vue";
import OrderSummary from "@/components/shop/OrderSummary.vue";
import api from "@/utils/api";
import {useToast} from "vue-toastification";
export default {
    name: "checkout",
    components: {
        OrderSummary,
        FinishOrder,
        DlvAddressCheckout,
        BasketSummary, InvoiceAddresCheckout, SettingsInput, Breadcrumbs, FormWizard, TabContent},
    data(){
        return {
            wizardTabs: [
                {title: 'Invoice Address', icon: 'user', tab: 0},
                {title: 'Delivery Address', icon: 'truck', tab: 1},
                {title: 'Review Order', icon: 'money', tab: 2}
            ],
            currentStep: 0,
            formWizard: null,
            deliveryValid: false,
            completeValid: false,
            deliveryAddress: {},
            order: {
                comments: '',
                customerRefNo: ''
            },
            successOrder: false
        }
    },
    computed:{
        ...mapState({
            shoppingCart: state => state.product.shoppingCart,
            loggedUser: state => state.auth.loggedUser
        }),
        nextDisabled(){
            if(this.currentStep === 1){
                return !this.deliveryValid;
            }
            if(this.currentStep === 2){
                return !this.completeValid;
            }
            return false
        }
    },
    methods:{
        ...mapActions({
            actGetCart: 'product/actGetCart'
        }),
        handleChange(val){
            console.log()
            if(val > this.currentStep){
                this.$refs.formWizard.nextTab();
            } else {
                this.$refs.formWizard.prevTab();
            }
            this.currentStep = val;
            return true;
        },
        async handleComplete(){
            console.log('complete')
            try {
                const {data} = await api.post('/SaleOrderHeader', {
                    "shoppingCartId": this.shoppingCart.shoppingCartId,
                    ...this.order
                })

                useToast().success('Order created successfully');
                this.successOrder = true;
                await this.actGetCart(this.loggedUser.id)
            } catch (e) {

            }
        }
    }
}
</script>

<template>
<div>
    <Breadcrumbs main="Shopping Cart" main-path="/shopping-cart" diff-main="Checkout" title="Checkout" />
    <div class="card">
        <div class="card-body">

            <FormWizard ref="formWizard" hide-buttons v-if="shoppingCart && !successOrder" @onComplete="handleComplete" color="#007cc2" stepSize="sm" >
                <tab-content v-for="{title, icon, tab} in wizardTabs"
                                                        :key="icon" :title="title" :icon="`fa fa-${icon}`">
                    <div class="d-flex gap-3" style="min-height: 480px">
                        <invoice-addres-checkout v-if="tab === 0"/>

                        <dlv-address-checkout v-if="tab === 1"
                                              :deliveryAddress="deliveryAddress"
                                              @setDeliveryAddress="(add) => deliveryAddress = add"
                                              @validate="(val) => deliveryValid = val"/>

                        <finish-order v-if="tab === 2"
                                      :order="order"
                                      @updateOrder="({val, key}) => order[key] = val"
                                      @validate="(val) => completeValid = val"/>
                        <order-summary class="border p-3 rounded"/>
                    </div>
                </tab-content>
                <div class="d-flex mt-5">
                    <button v-if="currentStep > 0" @click="handleChange(currentStep - 1)" class="btn btn-primary">
                        Back
                    </button>
                    <button v-if="currentStep < 2" :disabled="nextDisabled" @click="handleChange(currentStep + 1)" class="btn btn-primary ml-auto">
                        Next
                    </button>
                    <button :disabled="nextDisabled" v-else @click="handleComplete" class="btn btn-success ml-auto">
                        Finish
                    </button>
                </div>
            </FormWizard>
            <div v-if="successOrder" class="d-flex flex-column justify-content-center align-items-center gap-3">
                <h5>You have successfully set an order!</h5>
                <router-link class="btn btn-primary" to="/products">
                    Continue Shopping
                </router-link>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>

</style>
