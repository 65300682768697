<script>
import api from "@/utils/api";
import {useToast} from "vue-toastification";
import PolarLoader from "@/components/PolarLoader.vue";
import Pagination from "@/components/uiKits/Pagination.vue";

export default {
    name: "faq",
    components: {Pagination, PolarLoader},
    data(){
        return {
            faqs: [],
            loading: false,
            paginationInfo: null,
            pageNumber: 1
        }
    },
    methods:{
        handlePageChange(page){
            this.pageNumber = page;
            this.getFAQ();
        },
        async getFAQ(){
            this.loading = true;
            try {
                const {data: {items, ...rest}} = await api.get('/faq', {
                    params: {
                        pageNumber: this.pageNumber
                    }
                });

                this.faqs = items;
                this.paginationInfo = rest;
            }catch (e) {
                useToast().error(e.response?.data?.errors[0]?.description || 'Something went wrong')
            }
            this.loading = false;
        }
    },
    created() {
        this.getFAQ();
    }
}
</script>

<template>
    <div class="mb-5">
        <Breadcrumbs diffMain="FAQ" main="Dashboard" mainPath="/" title="FAQ" />
        <div class="container">
            <div class="faq-wrap">
                <div class="row">
                    <div class="col-12">
                        <div class="card bg-primary">
                            <div class="card-body">
                                <div class="media faq-widgets">
                                    <div class="media-body">
                                        <h3>FAQs</h3>
                                        <p>
                                           Find below the most frequent questions and their answers. If you can't find what
                                           you're looking for, you are pleased to contact our <a class="text-white fw-bold" href="/support">Support Team</a>.

                                        </p>
                                    </div>
                                    <vue-feather type="file-text"></vue-feather>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="header-faq">
                            <h5 class="mb-0">Frequent Questions and Answers</h5>
                        </div>
                        <div class="default-according style-1 position-relative" id="faqAccordion" style="min-height: 300px">
                            <div class="row">
                                <div v-for="faq in faqs" :key="faq.id" class="col-12 mb-3">
                                    <div class="card">
                                        <div class="card-header align-items-center" :id="`faqHeading${faq.id}`">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link d-flex align-items-center gap-3"
                                                        data-bs-toggle="collapse"
                                                        :data-bs-target="`#faqCollapse${faq.id}`"
                                                        aria-expanded="false"
                                                        :aria-controls="`faqCollapse${faq.id}`">
                                                    <vue-feather type="help-circle"></vue-feather>
                                                    {{ faq.title }}
                                                </button>
                                            </h5>
                                        </div>
                                        <div class="collapse"
                                             :id="`faqCollapse${faq.id}`"
                                             :aria-labelledby="`faqHeading${faq.id}`">
                                            <div class="card-body text-justify">
                                                {{faq.description}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <polar-loader v-if="loading">
                                Loading
                            </polar-loader>
                        </div>
                        <div class="mb-5 mt-3">
                            <pagination v-if="paginationInfo" :pagination-info="paginationInfo" @pageChanged="handlePageChange"/>
                        </div>
                    </div>

                </div>
                <div>

                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
