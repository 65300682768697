<script>
import Pagination from "@/components/uiKits/Pagination.vue";
import PolarLoader from "@/components/PolarLoader.vue";
import PolarSearch from "@/components/uiKits/PolarSearch.vue";
import api from "@/utils/api";
import {useToast} from "vue-toastification";
import {
    colTitle,
    cUSettings,
    customClass,
    dateTypes,
    getDisplayInfo,
    transformDisplayData
} from "@/constants/tableSettings";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import SearchBy from "@/components/uiKits/SearchBy.vue";
import CreateEditSettings from "@/components/settings/CreateEditSettings.vue";
import {format} from 'date-fns'
import {mapActions} from "vuex";
// import {checkIfDate} from "@/utils/helperFunctions";

export default {
    name: "TableSettings",
    props: ['settingType', 'dateFormat'],
    components: {CreateEditSettings, SearchBy, ConfirmModal, PolarSearch, PolarLoader, Pagination},
    data(){
        return {
            loading: false,
            showModal: false,
            tableData: [],
            cUSettings,
            currentSetting: null,
            params: {
                searchKey: '',
                columnName: '',
                isDescending: true,
                pageNumber: 1,
                pageSize: 8
            },
            pagination: {
                "pageNumber": 1,
                "totalPages": 1,
                "totalCount": 1,
                "hasPreviousPage": false,
                "hasNextPage": false
            },
        }
    },
    computed:{
        displayKeys(){
            return getDisplayInfo(this.settingType.api)
        },
        cUS(){
            return cUSettings[this.settingType.api]
        }
    },
    methods:{
        ...mapActions({
            actUpdateKitItems: 'settings/actUpdateKitItems'
        }),
        customTdClass(key){
            if(customClass[this.settingType.api]) {
                console.log('keyyyyyy', key)
                return customClass[this.settingType.api][key] || '';
            }
            return '';
        },
        colTitles(key){
            return colTitle(key, this.settingType.title);
        },
        async getTableInformation(){
            this.loading = true;
            try {
                const {data} = await api.get(`/${this.settingType.api}`, {
                    params: this.params
                });
                const {items, ...rest} = data;
                if(transformDisplayData[this.settingType.api]){
                    this.tableData = transformDisplayData[this.settingType.api].transform(items);
                    console.log(this.tableData, 'this.tableData')
                } else {
                    this.tableData = items;
                }
                this.pagination = rest;
            } catch (e) {
                useToast().error(e.response.data?.title);
            } finally {
                this.loading = false;
            }
        },
        handlePaginationEvent(pageNo){
            this.params.pageNumber = pageNo;
            this.getTableInformation();
        },
        handleSort(key){
            if(this.params.columnName === key){
                this.params.isDescending = !this.params.isDescending
            } else {
                this.params.isDescending = true;
            }
            this.params.columnName = key;
            this.params.pageNumber = 1;
            this.getTableInformation();
        },
        async handleDelete(){
            if(this.currentSetting){
                const {id} = this.currentSetting
                try {
                    await api.delete(`${this.settingType.api}/${id}`);
                    useToast().success('Setting deleted successfully');
                    this.tableData = this.tableData.filter(s => s.id !== id);
                } catch (e) {
                    // useToast().error(e.response.data?.title)
                } finally {
                    this.currentSetting = null;
                }
            }
        },
        setCurrentSetting(setting){
                console.log(setting, 'data to edit')
            if(this.settingType.api === 'Kit'){
                this.actUpdateKitItems(setting.childItems);
            }
            this.currentSetting = setting;
        },
        handleModalShow(){
            if(this.settingType.api === 'Kit' && !this.currentSetting){
                this.actUpdateKitItems([]);
            }
            this.showModal = true;
        },
        handleModalHide(){
            if(this.currentSetting){
                this.currentSetting = null;
            }
            this.showModal = false;
        },
        checkIfDate(dateToCheck, key){
            return cUSettings[this.settingType.api]?.[key]?.type === 'date' || dateTypes[key]?.type === 'date';
        },
        formatDate(date){
            return format(date, this.dateFormat || 'dd MMMM yyyy')
        }
    },
    mounted() {
        if(this.cUS?.isDefault){
            this.params.columnName = 'isDefault'
        }
        this.getTableInformation();
        const createSettingModal = document.getElementById('createSettingModal');

        createSettingModal.addEventListener('shown.bs.modal', this.handleModalShow)
        createSettingModal.addEventListener('hidden.bs.modal', this.handleModalHide)
    },
    beforeUnmount() {
        const createSettingModal = document.getElementById('createSettingModal');

        createSettingModal.removeEventListener('shown.bs.modal', this.handleModalShow)
        createSettingModal.removeEventListener('hidden.bs.modal', this.handleModalHide)
    }
}
</script>

<template>
    <div class="modal fade" id="deleteSettingModal" role="dialog"
         aria-labelledby="deleteSettingModal" aria-hidden="true">
        <ConfirmModal @confirmed="handleDelete">
            <template #message>
                Are you sure you want to delete this setting?
            </template>
        </ConfirmModal>
    </div>
    <div class="modal fade" id="createSettingModal" role="dialog"
         aria-labelledby="createSettingModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <div>
                        <h5 class="modal-title">{{ `${currentSetting ? 'Edit' : 'Create new'} ${settingType.title}`}}</h5>
                        <!--                        <p>Enter required information</p>-->
                    </div>
                    <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <transition name="fadeHeight">
                    <create-edit-settings v-if="showModal"
                                          :settingType="settingType"
                                          @settingCreated="handlePaginationEvent(1)"
                                          :editSetting="currentSetting"
                    />
                </transition>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="card position-relative overflow-hidden">
            <div class="card-header d-flex align-items-center gap-2">
                <div class="d-flex w-100 align-items-center">
                    <PolarSearch v-model="params.searchKey" style="max-width: 400px;" @search="getTableInformation"/>
<!--                    <SearchBy v-model="params.columnName" :options="displayKeys"/>-->
                </div>
                <slot name="newButton">
                    <button v-if="settingType.CUD[0]" :class="['btn btn-primary ml-auto d-flex align-items-center gap-2']"
                            data-bs-toggle="modal"
                            data-bs-target="#createSettingModal">
                        <i class="fa fa-plus"/>
                        New
                    </button>
                </slot>
            </div>
            <div class="card-body" style="min-height: 565px;">
                <div class="table-responsive add-project h-100 stickyLastCol">
                    <table class="table table-hover card-table table-vcenter text-nowrap">
                        <thead>
                            <tr>
                                <th v-for="(key, idx) in displayKeys" :key="key" :class="['fw-bold', {'text-center': idx > 1}]">
                                    <div @click="handleSort(key)" :class="['p-1 cursor-pointer max-w-min text-capitalize', {'mx-auto': idx > 1}]">
                                        <slot :name="`title-${key}`">
                                            {{ colTitles(key) }}
                                        </slot>
                                        <i class="fa fa-sort"/>
                                    </div>
                                </th>
                                <th v-if="settingType.CUD[1] || settingType.CUD[2]" class="fw-bold text-center">
                                    <div class="d-flex align-items-center justify-content-center">
                                        Actions
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="data in tableData" :key="data.id">
                                <td v-for="(key, idx) in displayKeys" :class="[{'text-center': idx > 1}, (customTdClass(key) || 'defaultTd')]" :key="`${data.id}|${key}`">
                                    <div v-if="(typeof(data[key]) === 'boolean' || !data[key]) && typeof(data[key]) !== 'number'"
                                         :class="['badge p-2 w-100 max-w-min', (data[key] ? 'badge-success' : 'badge-warning')]"
                                         style="max-width: max-content;">
                                        <div class="f-w-700 max-w-min">
                                            <slot :name="`booleanCell-${key}`" :cellData="data[key]">
                                                {{data[key] ? 'YES' : (typeof(data[key]) === 'boolean' ? 'NO' : `No ${colTitles(key)}`)}}
                                            </slot>
                                        </div>
                                    </div>
                                    <div v-else-if="checkIfDate(data[key], key)">
                                        {{ formatDate(data[key]) }}
                                    </div>
                                    <div v-else v-html="data[key]"></div>
                                </td>
                                <td>
                                    <slot name="actions" :rowData="data">
                                        <div class="d-flex gap-2 mx-auto w-100 justify-content-center">
                                            <button v-if="settingType.CUD[1]" @click="setCurrentSetting(data)"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#createSettingModal"
                                                    class="btn btn-primary btn-sm">
                                                <i class="fa fa-pencil"></i>
                                                Edit
                                            </button>
                                            <button v-if="settingType.CUD[2]"
                                                    class="btn btn-danger btn-sm"
                                                    @click="setCurrentSetting(data)"
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#deleteSettingModal">
                                                <i class="fa fa-trash"></i>
                                                Delete
                                            </button>
                                            <!--                                        <div class="badge badge-light-primary" v-if="!settingType.CUD[1] && !settingType.CUD[2]">-->
                                            <!--                                            No Actions-->
                                            <!--                                        </div>-->
                                        </div>
                                    </slot>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer pagination-padding">
                <pagination :pagination-info="pagination" @pageChanged="handlePaginationEvent"/>
            </div>
            <polar-loader v-if="loading">
                Loading Table Settings
            </polar-loader>
        </div>
    </div>
</template>

<style scoped>

table th:last-child{
    height: 55px;
}

.defaultTd div{
    max-width: 310px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.textWrap div {
    max-width: 500px;
    width: 100%;
    min-width: 300px;
    text-wrap: wrap;
}

</style>
