<script>
import PolarLoader from "@/components/PolarLoader.vue";
import Pagination from "@/components/uiKits/Pagination.vue";
import PolarSearch from "@/components/uiKits/PolarSearch.vue";
import api from "@/utils/api";
// import {errorToast} from "@/utils/helperFunctions";
import {format} from "date-fns";
import {hardCodeApi} from "@/constants/tableSettings";
import SimpleTable from "@/components/order/SimpleTable.vue";
import ProggressButton from "@/components/ProggressButton.vue";
import {useToast} from "vue-toastification";
import {downloadReport} from "@/utils/helperFunctions";

const badgeItem = (color, title) => `<div class="badge p-2 w-100 max-w-max badge-${color || 'success'}">
                                        <div class="f-w-700">
                                            ${title}
                                        </div>
                                    </div>`

export default {
    name: "validation_status",
    components: {ProggressButton, SimpleTable, PolarSearch, Pagination, PolarLoader},
    data(){
        return{
            params: {
                searchKey: '',
                columnName: 'customerMasterDataExpiryDateUtc',
                isDescending: true,
                pageNumber: 1,
                pageSize: 8,
                isUserValidated: null,
                customerStatus: null,
                usersExpireNext2Weeks: null,
                customersExpireNext2Weeks: null,
            },
            displayColumns:[
                {key: 'customerMasterDataId', title: 'Customer Id'},
                {key: 'organizationName', title: 'Company Name'},
                {key: 'userEmail', title: 'User Email'},
                {key: 'customerMasterDataStatus', title: 'Customer Account Validated', value: (item) => {
                    const currStatus = hardCodeApi.StatusOptions.find(status => status.id === item.customerMasterDataStatus)
                    if(currStatus)
                        return badgeItem(currStatus.color, currStatus.name);
                    return 'Unkown';
                }},
                {key: 'customerMasterDataExpiryDateUtc', title: 'Customer Expiration Validation Date', type: 'date'},
                {key: 'accountNumValidatedOnUtc', title: 'Customer Account Validation Date', type: 'date'},
                {key: 'userValidatedOnUtc', title: 'User Validation Date', type: 'date'},
                {key: 'isUserValidated', title: 'User Validated', type: 'boolean', value: (item) => {
                        const info = item.isUserValidated ? {color:'success', title: 'YES'} : {color:'warning', title: 'NO'}
                        return badgeItem(info.color, info.title)
                    }},
            ],
            validationStatus: [],
            pagination: {
                "pageNumber": 1,
                "totalPages": 1,
                "totalCount": 0,
                "hasPreviousPage": false,
                "hasNextPage": false
            },
            loading: true,
            isValidatingUser: []
        }
    },
    methods:{
        handleSearch(){
            this.params.pageNumber = 1;
            this.getValidations();
        },
        handlePaginationEvent(page){
            this.params.pageNumber = page;
            this.getValidations();
        },
        handleSort(column){
            if(column === this.params.columnName){
                this.params.isDescending = !this.params.isDescending;
            }
            this.params.columnName = column;
            this.params.pageNumber = 1;
            this.getValidations();
        },
        formatDate(date){
            return format(date, 'dd MMMM yyyy')
        },
        prepareForDisplay(items){
            return items.map(item => {

            })
        },
        async getValidations(){
            try {
                this.loading = true;
                const {data} = await api.get('/CustomerValidationStatus', {
                    params: {
                        ...this.params,
                        customerStatus: (this.params.customerStatus?.id || null)
                    }
                })
                const {items, ...rest} = data;
                this.validationStatus = items;
                this.pagination = rest;
            } catch (e) {
                // errorToast(e)
            } finally {
                this.loading = false
            }
        },
        handleFilterChange(filter, value){
            this.params[filter] = value;
            this.params.pageNumber = 1;
            this.getValidations();
        },
        handleStatusChange(opt){
            this.params.customerStatus = opt;
            this.params.pageNumber = 1;
            this.getValidations();
        },
        handleCustomerExpire(param){
            if(this.params[param]){
                this.params[param] = null
            } else {
                this.params[param] = true;
            }
            this.params.pageNumber = 1;
            this.getValidations();
        },
        async handleDownloadValidationReport(){
            try {
                const {data} = await api.post('/CustomerValidationStatus/download');
                console.log(data)
                downloadReport({
                    data: data.fileContents,
                    type: data.contentType,
                    fileName: data.fileDownloadName
                })
            } catch (e) {
                console.log(e, 'eeeeee')
            }
        },
        async handleValidateUser({isUserValidated, userId}){
            if(isUserValidated) return;
            try {
                this.isValidatingUser.push(userId);
                const {data} = await api.post(`/user/validateUser/${userId}`);
                useToast().success('User validated with success');
                await this.getValidations();
                // let user = this.validationStatus.find(c => c.userId === userId)
                // user.isUserValidated = !isUserValidated;
                const idx = this.isValidatingUser.find(id => id === userId);
                this.isValidatingUser.splice(idx, 1);
            } catch (e){

            }
        }
    },
    computed:{
        cStatusOptions(){
            return [
                {id: null, name: 'All Customer Status'},
                ...hardCodeApi.StatusOptions
            ];
        }
    },
    mounted() {
        this.getValidations()
    }
}
</script>

<template>
    <div>

        <Breadcrumbs diff-main="Validation Status" main="Customer Master Data" main-path="/master-data" title="Validation Status" />
        <div class="container">
            <div class="row">
                <div class="card position-relative overflow-hidden">
                    <div class="card-header d-flex flex-column justify-content-center gap-2">
                        <div class="d-flex gap-2 flex-wrap w-100 align-items-center justify-content-between">
                            <PolarSearch v-model="params.searchKey" style="max-width: 400px;" @search="handleSearch"/>
                            <button class="btn btn-success" @click="handleDownloadValidationReport"> <i class="fa fa-download"></i> Download Customer Validation Report</button>
                        </div>
                        <div class="d-flex gap-2">
                            <div v-for="filter in [{key: 'isUserValidated', title: 'User Validated'}]" class="dropdown" :key="filter.key">
                                <button class="btn filterSelect" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {{filter.title}} - {{ params[filter.key] ? 'Yes' : 'No' }}
                                    <i class="fa fa-caret-down m-l-5"></i>
                                </button>
                                <ul class="dropdown-menu">
                                    <li @click="handleFilterChange(filter.key, true)"><span class="dropdown-item">Yes</span></li>
                                    <li @click="handleFilterChange(filter.key, false)"><span class="dropdown-item">No</span></li>
                                </ul>
                            </div>
                            <div class="dropdown">
                                <button class="btn filterSelect" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {{params.customerStatus?.name || 'Customer Account Status'}}
                                    <i class="fa fa-caret-down m-l-5"></i>
                                </button>
                                <ul class="dropdown-menu">
                                    <li v-for="opt in cStatusOptions" :key="opt.id"
                                        @click="handleStatusChange(opt)">
                                        <span class="dropdown-item">{{opt.name}}</span>
                                    </li>
                                </ul>
                            </div>
                            <button @click="handleCustomerExpire('customersExpireNext2Weeks')"
                                    :class="['btn', (params.customersExpireNext2Weeks ? 'btn-primary' : 'btn-white border-primary border')]"
                                    style="padding: 12px 10px;">Customer That Expire in The Next 2 Weeks</button>
                            <button @click="handleCustomerExpire('usersExpireNext2Weeks')"
                                    :class="['btn', (params.usersExpireNext2Weeks ? 'btn-primary' : 'btn-white border-primary border')]"
                                    style="padding: 12px 10px;">Users That Expire in The Next 2 Weeks</button>
                        </div>
                    </div>
                    <div class="card-body" style="min-height: 565px;">
                        <simple-table :columns="displayColumns" :data="validationStatus" :actions="true" class="stickyLastCol">
                            <template #columnHead="{column: col}">
                                <div @click="handleSort(col.key)">
                                    {{ col.title }}
                                    <i class="fa fa-sort"/>
                                </div>
                            </template>
                            <template #columnRow="{tableData: data, column: col}">
                                <div v-if="col.value" v-html="col.value(data)"/>
                                <div v-else-if="col.type === 'date'">
                                    {{ data[col.key] ? formatDate(data[col.key]) : 'No date'}}
                                </div>
                                <div v-else-if="!data[col.key]" :class="['badge py-2 px-3 w-100 max-w-max badge-warning']">
                                    No {{ col.title }}
                                </div>
                                <div v-else>
                                    {{data[col.key]}}
                                </div>
                            </template>
                            <template #actions="{data: {isUserValidated, userId}}">
                                <proggress-button @click="handleValidateUser({isUserValidated, userId})"
                                                  :loading="isValidatingUser.includes(userId)"
                                                  :disabled="!userId"
                                                  :active="true"
                                >
                                    Validate User
                                </proggress-button>
                            </template>
                        </simple-table>
                    </div>
                    <div class="card-footer pagination-padding">
                        <pagination :pagination-info="pagination" @pageChanged="handlePaginationEvent"/>
                    </div>
                    <polar-loader v-if="loading">
                        Loading Customer Validation Status
                    </polar-loader>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.filterSelect{
    border-color: #efefef;
    background-color: #fff;
    font-size: 14px;
    padding: 12px 10px;
    box-shadow: none;
}

.dropdown span {
    cursor: pointer;
}

</style>
