<script>
import SettingsInput from "@/components/settings/SettingsInput.vue";
import {addressInputs} from "@/constants/masterData";
import {useVuelidate} from "@vuelidate/core";
import api from "@/utils/api";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {useToast} from "vue-toastification";
import ContactView from "@/components/masterdata/ContactView.vue";
import SimpleTable from "@/components/order/SimpleTable.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import AddContact from "@/components/masterdata/AddContact.vue";
import PolarLoader from "@/components/PolarLoader.vue";

export default {
    name: "InvoiceAddress",
    props: ['invoiceAddress', 'editInvoiceAddress'],
    components: {PolarLoader, AddContact, ConfirmModal, SimpleTable, ContactView, SettingsInput},
    setup(){
        return {
            v$: useVuelidate()
        }
    },
    data(){
        return {
            editInvoiceContact: false,
            communicationType: 1,
            newInvoice: null,
            newStatementContact: {
                id: -1,
                description: '',
                purpose: '',
                locatorExtension: null,
                transactionType: 0,
                communicationType: 2,
                d365ElectronicAddressID: '',
                isStatement: false,
                isCustom: false,
                isPrimary: false,
                isPrivate: false,
                isMobile: false,
                locator: ''
            },
            currEmail: null,
            emailDeleteId: null,
            editEmailId: null
        }
    },
    watch:{
        editInvoiceContact(){
            this.$emit('editInvoiceContact', this.editInvoiceContact)
        }
    },
    computed:{
        ...mapGetters({
            loggedPermission: 'auth/loggedPermission',
            masterDataPermissions: 'auth/masterDataPermissions',
        }),
        canEditInvoice(){
            return this.loggedPermission > 2;
        },
        addressInputs(){
            return addressInputs
        },
        communicationOptions(){
            const transTitle = `Invoice Contact`
            return [
                {type: 1, title: `${transTitle} Phone`},
                { type: 2, title: `${transTitle} Email`},
            ]
        },
        invoiceStatementContact(){
            return this.newInvoice?.contacts.find(c => c.isCustom)
        },
        currentInvoiceContact(){
            return this.newInvoice?.contacts.find(c => c.communicationType === this.communicationType)
        },
        currentCommunicationInputs(){
            const inputs = this.communicationType === 1 ? addressInputs.createPhoneContact :addressInputs.createEmailContact;
            return [
                {label: 'Purpose', type: 'null', key: 'purpose', notRequired: true},
                ...inputs
            ];
            // return inputs.filter(i => i.key !== 'description');
        },
        invoiceInputs(){
            return [
                {label: 'Purpose', type: 'null', key: 'purpose', notRequired: true},
                ...addressInputs.invoiceAddress,
            ];
        },
        statementInputs(){
            return [
                {label: 'Custom Email Address for Statement', type: 'boolean', disabled: this.invoiceStatementContact?.isCustom, key: 'isCustom', notRequired: true},
                {
                    label: 'Email Address for Statement',
                    type: this.invoiceStatementContact?.isCustom ? 'null' : this.newStatementContact?.isCustom ? 'string' : 'null',
                    key: 'locator',
                    notRequired: !this.invoiceStatementContact?.isCustom && !this.newStatementContact?.isCustom
                }
            ]
        },
        emailContacts(){
            return this.newInvoice?.contacts.filter(c => c.communicationType !== 1).map(item => {
                const {purpose, ...rest} = item;
                return {
                    purpose: item.isCustom ? 'Statement' : this.invoiceStatementContact ? 'Invoice' : 'Invoice, Statement',
                    ...rest
                }
            }) || []
        }
    },
    methods:{
        ...mapActions({
            actUpdateCustomerAddress: 'masterdata/actUpdateCustomerAddress',
            actDeleteContact: 'masterdata/actDeleteContact',
        }),
        ...mapMutations({
            mutAddNewContact: 'masterdata/mutAddNewContact'
        }),
        handleAddressChange({key, value}){
            this.newInvoice[key] = value;
            this.monitorRelations(key);
        },
        handleStatementContact({key, value}){
            this.newStatementContact[key] = value;
        },
        monitorRelations(key){
            let nextKey = ''
            for(const data of this.addressInputs.invoiceAddress){
                console.log('checking data to default', data.key)
                if(nextKey === data.relation){
                    console.log('setting NEXT data to default', key)
                    this.newInvoice[data.key] = null
                } else if(data.relation === key){
                    console.log('setting data to default', data.key)
                    this.newInvoice[data.key] = null;
                    nextKey = data.key;
                }
            }
        },
        handleContactChange({key, value}){
            let found = this.newInvoice.contacts.find(c => c.id === this.currentInvoiceContact.id);
            found[key] = value
            console.log(found, key, value, 'kanpi')

        },
        handleTabChange(type){
            if(!this.editInvoiceContact){
                this.communicationType = type
            }
        },
        handleEditContact(){
            this.editInvoiceContact = !this.editInvoiceContact;
        },
        async handleSaveEmail(statementEmail){
            const result = await this.v$.$validate();
            if(result){
                const email = statementEmail?.id ? statementEmail : this.currEmail;
                const {id, d365ElectronicAddressID, ...rest} = email;
                if(email.isCustom){
                    console.log('making is statement')
                    email.isStatement = true;
                }
                console.log(email, 'making is statement')
                try {
                    if(id < 0){
                        const {data} = await api.post('/Contact', rest);
                        this.mutAddNewContact({...email, id: data});
                    } else {
                        await api.put(`/Contact/${id}`, rest);
                        console.log(email, 'emailllll')
                        this.mutAddNewContact(email);
                    }
                    useToast().success('Contact updated with success')
                    this.cloneInvoice();
                    this.dismissCreateContact();
                } catch (e) {
                }
            }
        },
        async handleSaveContact(){
            const result = await this.v$.$validate();
            if(result){
                const {id,d365ElectronicAddressID, ...rest} = this.currentInvoiceContact;
                try {
                    const {data} = await api.put(`/Contact/${id}`, rest);
                    useToast().success('Contact updated with success')
                    this.mutAddNewContact(this.currentInvoiceContact);
                    this.handleEditContact();
                } catch (e) {
                }
            }
        },
        getAddressPayload(){
            const {
                contacts,
                accountum,
                d365AddressLocationID,
                transactionType,
                users,
                ...rest
            } = this.newInvoice

            return {
                payload:{
                    ...rest,
                    customerMasterDataId: accountum,
                    userIds: users.map(u => u.id)
                },
                rawData: this.newInvoice
            }
        },
        async handleSaveAddress(){
            const result = await this.v$.$validate();
            console.log('workeeed', this.v$.$errors)
            if(result){
                try {
                    await this.actUpdateCustomerAddress(this.getAddressPayload());
                    if(this.invoiceStatementContact?.id === this.newStatementContact?.id && this.invoiceStatementContact?.locator !== this.newStatementContact?.locator) {
                        await this.handleSaveEmail(this.newStatementContact);
                    } else if(this.newStatementContact?.id < 0 && this.newStatementContact?.locator) {
                        await this.handleSaveEmail(this.newStatementContact);
                    }
                    this.$emit('addressSaved')
                } catch (e) {
                    // errorToast(e)
                }
            }
        },
        emitInterface() {
            this.$emit("interface", {
                handleSaveAddress: () => this.handleSaveAddress(),
                cloneInvoice: () => this.cloneInvoice(),
            });
        },
        handleCancel(){
            this.cloneInvoice();
            this.handleEditContact();
        },
        cloneInvoice(){
            console.log(this.invoiceAddress, 'this.invoiceAddress')
            this.newInvoice = JSON.parse(JSON.stringify(this.invoiceAddress));
            if(this.invoiceStatementContact){
                this.newStatementContact = JSON.parse(JSON.stringify(this.invoiceStatementContact));
            } else {
                this.newStatementContact.addressId = this.invoiceAddress.id
            }
        },
        async handleEmailDelete(id){
            if(id){
                this.emailDeleteId = id
            } else if(this.emailDeleteId) {
               await this.actDeleteContact({
                   addressId: this.newInvoice.id,
                   contactId: this.emailDeleteId
               })
                this.cloneInvoice();
            }
        },
        handleEditEmail(email){
            console.log(email, 'emailllll')
            this.currEmail = email
        },
        dismissCreateContact(){
            document.getElementById('dismissCreateContact').click();
        }
    },
    mounted() {
        this.cloneInvoice();
        this.emitInterface();
    }
}
</script>

<template>
    <div :key="0">
        <div class="modal fade" id="deleteEmailModal" role="dialog"
             aria-labelledby="deleteEmailModal" aria-hidden="true">
            <ConfirmModal @confirmed="handleEmailDelete">
                <template #message>
                    Are you sure you want to delete this contact?
                </template>
            </ConfirmModal>
        </div>
        <div class="modal fade" id="editCreateEmail" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            {{currEmail?.id < 0 ? 'Create new' : 'Edit'}} invoice contact email
                        </h5>
                        <button id="dismissCreateContact" class="btn-close max-w-max" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <AddContact v-if="currEmail" v-model="currEmail" :no-title="true"/>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary max-w-max" type="button" data-bs-dismiss="modal">Cancel</button>
                        <button class="btn btn-danger max-w-max"
                                type="button"
                                @click="handleSaveEmail">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="newInvoice">
            <div v-for="input in invoiceInputs"
                 :class="[(input.col ? input.col : 'col-sm-6 col-lg-4'),'d-flex flex-column justify-content-end']"
                 :key="input.key">
                <settings-input
                    :disabled="!editInvoiceAddress || input.disabled"
                    :settingsData="newInvoice"
                    :model-value="newInvoice[input.key]"
                    @update:model-value="(val) => handleAddressChange({key: input.key, value: val})"
                    :input="input"
                />
            </div>
            <div v-for="input in statementInputs"
                 :class="[(input.col ? input.col : 'col-sm-6 col-lg-4'),'d-flex flex-column justify-content-end']"
                 :key="input.key">
                <settings-input
                    :disabled="!editInvoiceAddress || input.disabled"
                    :settingsData="newStatementContact"
                    :model-value="newStatementContact?.[input.key]"
                    @update:model-value="(val) => handleStatementContact({key: input.key, value: val})"
                    :input="input"
                />
            </div>
        </div>
        <div class="d-flex gap-3 pb-3 mb-3 mt-4 pt-3 align-items-end">
            <ul class="nav nav-tabs w-100">
                <li v-for="nav in communicationOptions" class="nav-item">
                    <div @click="handleTabChange(nav.type)" :class="['nav-link', {'font-primary active': communicationType === nav.type}, {'cursor-pointer': !editInvoiceContact}]">
                        {{nav.title}}
                    </div>
                </li>
            </ul>
            <button @click="handleEditContact" :disabled="editInvoiceAddress || communicationType !== 1"
                    v-if="!editInvoiceContact && canEditInvoice"
                    class="fadeInLeft max-w-max h-max ml-auto btn btn-sm btn-outline-primary d-flex gap-2 align-items-center">
                <i class="fa fa-pencil"></i> Contact
            </button>
            <div v-else-if="canEditInvoice"  class="d-flex gap-3 ml-auto">
                <button @click="handleSaveContact" class="max-w-max d-flex h-max btn btn-sm btn-success fadeInLeft">
                    Save
                </button>
                <button @click="handleCancel" class="max-w-max h-max btn btn-sm btn-light fadeInLeft">
                    Cancel
                </button>
            </div>
        </div>
        <div v-if="newInvoice?.contacts">
            <div v-if="communicationType !== 1 && canEditInvoice">
                <simple-table :columns="currentCommunicationInputs"
                              class="stickyLastCol"
                              :actions="true"
                              :data="emailContacts">
                    <template #columnHead="{column}">
                        {{column.label}}
                    </template>
                    <template #columnRow="{tableData, column: {key, type}}">
                        <div v-if="type !== 'boolean'">
                            {{tableData[key] || 'N/A'}}
                        </div>
                        <div v-else :class="['badge', (tableData[key] ? 'badge-success' : 'badge-danger')]">
                            <span class="f-w-700">{{tableData[key] ? 'YES' : 'NO'}}</span>
                        </div>
                    </template>
                    <template #actions="{data}">
                        <div class="d-flex gap-2 justify-content-center">
                            <button class="btn btn-primary text-center max-w-min"
                                    data-bs-toggle="modal"
                                    data-bs-target="#editCreateEmail"
                                    @click="handleEditEmail(data)">
                                <i class="fa fa-pencil"></i>
                            </button>
                            <button @click="handleEmailDelete(data.id)"
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteEmailModal"
                                    class="btn btn-danger text-center max-w-min">
                                <i class="fa fa-trash"></i>
                            </button>
                        </div>
                    </template>
                </simple-table>
                <div v-if="!emailContacts.length" class="d-flex justify-content-center mt-4">
                    <h5>There are no contacts</h5>
                </div>
                <button class="btn btn-primary text-center my-3"
                        data-bs-toggle="modal"
                        data-bs-target="#editCreateEmail"
                        @click="handleEditEmail({
                            id: -1,
                            communicationType: 2,
                            isPrivate: false,
                            addressId: newInvoice.id,
                            isPrimary: false
                        })">
                    <i class="fa fa-plus"></i>
                    Add New Email
                </button>
            </div>
            <div v-else class="row">
                <div v-for="input in currentCommunicationInputs"
                     class="col-sm-6 col-lg-4 d-flex flex-column justify-content-end"
                     :key="input.key">
                    <settings-input
                        :disabled="!editInvoiceContact || input.disabled"
                        :settingsData="currentInvoiceContact"
                        :model-value="currentInvoiceContact[input.key]"
                        @update:model-value="(val) => handleContactChange({key: input.key, value: val})"
                        :input="input"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
