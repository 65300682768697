<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import BasketSummary from "@/components/shop/BasketSummary.vue";
import SettingsInput from "@/components/settings/SettingsInput.vue";
import api from "@/utils/api";

export default {
    components: {SettingsInput, BasketSummary},
    setup(){
        const basketColumns = [
            {title: 'Product', key: 'prodInfo'},
            // {title: 'Description', key: ''},
            {title: 'Unit Price', key: 'price'},
            {title: 'QTY', key: 'qty'},
            {title: 'Sub Total', key: 'totalPrice'},
        ]

        return {
          basketColumns
        }
    },
    name: "BasketList",
    data(){
        return {
            test: 0,
            loadingLine: []
        }
    },
    computed:{
        ...mapState({
            shoppingCart: state => state.product.shoppingCart
        }),
        ...mapGetters({
            gCartLines: "product/gCartLines",
            gSetting: "settings/gSetting",
            gProductPageLink: "product/gProductPageLink"
        }),
        currency(){
            const currencies = this.gSetting('Currency');
            if(currencies){
                const currency = currencies.find(c => c.id === this.shoppingCart?.currencyId)
                if(currency)
                    return currency.code;
            }
            return 'EUR';
        }
    },
    methods:{
        ...mapActions({
            actGetAllApiData: "settings/actGetAllApiData",
            actGetLinePrice: 'product/actGetLinePrice',
            actUpdateLineQuantity: 'product/actUpdateLineQuantity',
            actDeleteCartLine: 'product/actDeleteCartLine'
        }),
        ...mapMutations({
            mutSetLineKey: 'product/mutSetLineKey'
        }),
        async handleQtyChange({target: {value}}, {id, productId}){
            this.loadingLine.push(id);
            if(value){
                const parsed = JSON.parse(value)
                // this.mutSetLineKey({id, value: parsed, key: 'qty'})
                // await this.actGetLinePrice({productId, customerMasterDataId: this.shoppingCart.customerMasterDataId, quantity: parsed})
                await this.actUpdateLineQuantity({lineId: id, shoppingCartId:this.shoppingCart.shoppingCartId, newQuantity: parsed})
            }
            this.removeLineFromLoading(id);

        },
        removeLineFromLoading(id){
            const idx = this.loadingLine.findIndex(lineId => lineId === id);
            this.loadingLine.splice(idx, 1);
        },
        getApiData(){
            this.actGetAllApiData(['Currency']);
        },
        async handleRemoveLine(id){
            this.loadingLine.push(id);
            await this.actDeleteCartLine(id);
            this.removeLineFromLoading(id);
        },
        async handleCheckout(){
            try {
                await api.post(`/ShoppingCartSummary/checkout/${this.shoppingCart.shoppingCartId}`);
                this.$router.push('/checkout');
            } catch (e) {

            }
        }
    },
    created() {
        this.getApiData();
    }
}
</script>

<template>
    <div class="card">
        <div class="card-body">
            <div class="row" v-if="shoppingCart?.shoppingCartLines.length">
                <div class="col-12 col-md-8 col-xl-9 mb-3">
                    <div class="table-responsive add-project">
                        <table class="table table-hover card-table table-vcenter text-nowrap">
                            <thead>
                            <tr>
                                <th v-for="(column, idx) in basketColumns" :key="idx" :class="['fw-bold', {'text-center': idx > 1}]">
                                    <div :class="['p-1 text-capitalize', {'mx-auto': idx > 1}]">
                                        {{ column.title }}
                                    </div>
                                </th>
                                <th class="fw-bold text-center">
                                    Actions
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="line in gCartLines" :key="line.id" :class="[{'trLoading': loadingLine.includes(line.id)}]">
                                <td v-for="({key, title}, idx) in basketColumns" :class="[{'text-center': idx > 1}]" :key="`${idx}|tr`">
                                    <div v-if="key === 'prodInfo'" class="d-flex gap-2 align-items-center min-w-max">
                                        <router-link :to="gProductPageLink(line.productId)">
                                            <img :src="line.urlImageThumb" class="img-fluid bg-img" style="width: 80px" :alt="line.productName"/>
                                        </router-link>
                                        <div>
                                            {{line.productName}}
                                        </div>
                                    </div>

                                    <div v-else-if="key === 'qty'">
                                        <input
                                            style="width: 100px"
                                            class="form-control"
                                            type="number"
                                            :readonly="line.isChildItem"
                                            :value="line.qty"
                                            @change="(e) => handleQtyChange(e, line)"
                                        />
                                    </div>
                                    <div v-else>
                                        <span class="f-w-600" v-if="key === 'price' || key === 'totalPrice'">{{currency}}</span> {{line[key]}}
                                    </div>
                                </td>
                                <td class="text-center">
                                    <button v-if="!line.isChildItem" @click="handleRemoveLine(line.id)" class="btn btn-sm btn-danger">
                                        <i class="fa fa-trash"></i>
                                        Remove
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-flex mt-3 w-100 align-items-end">
                        <router-link to="/products">
                            <i class="fa fa-arrow-circle-left"></i>
                            Continue Shopping
                        </router-link>
                        <button @click="handleCheckout" class="ml-auto btn btn-success">Checkout</button>
                    </div>
                </div>
                <basket-summary class="col-12 col-md-4 col-xl-3"/>
            </div>
            <div v-else class="d-flex flex-column justify-content-center align-items-center gap-3">
                <h5>There are no products in your shopping cart</h5>
                <router-link class="btn btn-primary" to="/products">
                    Continue Shopping
                </router-link>
            </div>
        </div>
    </div>
</template>

<style scoped>
.trLoading {
    pointer-events: none;
    filter: blur(4px);
}
</style>
