import api from "@/utils/api";
import {useToast} from "vue-toastification";
import CustomToast from "@/components/uiKits/CustomToast.vue";
import router from "@/router";
import {errorToast} from "@/utils/helperFunctions";
// import {errorToast} from "@/utils/helperFunctions";
const toast = useToast()

const state = {
    singleProduct: null,
    currentCustomer: null,
    currQuantity: 1,
    shoppingCart: null
}

const getters = {
    gCartCurrency: (state, getters, rootState) => {
        const currencies = rootState.settings.settingsApiData?.Currency
        if(state.shoppingCart && currencies){
            return currencies.find(c => c.id === state.shoppingCart.currencyId)?.code || '-'
        }
        return '-';
    },
    gProductPageLink: (state) => (productId) => `/products/${productId}&&${state.shoppingCart.customerMasterDataId}`,
    gCartLines: (state) => {
        if(!state.shoppingCart) return [];
        return state.shoppingCart.shoppingCartLines;
    },
    gCartTotal: (state) => {
        if(state.shoppingCart){
            return state.shoppingCart.shoppingCartTotal
        }
        return '---'
    },
    gCartItems: (state) => {
        return state.shoppingCart?.shoppingCartLines.length || 0
    },
}

const mutations = {
    mutSetSingleProduct: (state, data) => {
        // console.log(data, 'dataaa', id)
        state.singleProduct = data;
    },
    mutSetProductCustomer: (state, cust) => {
        state.currentCustomer = cust
    },
    mutQuantity: (state, value) => {
        state.currQuantity = value;
    },
    mutSetPrice: (state, price) => {
       if(state.singleProduct)
           state.singleProduct.price = price
    },
    mutSetShoppingCart: (state, cart) => {
        state.shoppingCart = cart
    },
    mutDeleteCartLine: (state, id) => {
        state.shoppingCart.shoppingCartLines = state.shoppingCart.shoppingCartLines.filter(line => {
            return line.id !== id;
        })
    },
    mutCalculateTotal: (state) => {
        let newTotal = 0;
        state.shoppingCart.shoppingCartLines.forEach(l => {
            newTotal+= l.totalPrice
        })
        state.shoppingCart.shoppingCartTotal = newTotal;
    },
    mutSetLineKey: (state, {id, value, key}) => {
        let line = state.shoppingCart.shoppingCartLines.find(l => l.id === id);
        if(line) line[key] = value;
    }
}

const actions = {
    actSetSingleProduct: async ({commit}, id) => {
        try {
            const {data} = await api.get('/productMasterData')
            commit('mutSetSingleProduct', {data, id});
        } catch (e) {
            // errorToast(e)
        }
    },
    actGetPrice: async ({commit}, {productId, customerKey, quantity}) => {
        if(!quantity){
            commit('mutSetPrice', 0);
            return;
        }
        try {
            const {data} = await api.get(`/ProductMasterData/getPrice/${productId}`, {
                params: {
                    customerMasterDataId: customerKey,
                    quantity
                }
            })
            commit('mutSetSingleProduct', data);
        } catch (e) {
            // errorToast(e);
            commit('mutSetPrice', 0);
        }

    },
    actGetLinePrice: async ({commit}, {customerMasterDataId, quantity, productId, lineId}) => {
        try {
            const {data} = await api.get(`/ProductMasterData/getPrice/${productId}`, {
                params: {
                    customerMasterDataId,
                    quantity
                }
            })
            console.log({data})
            if(data.price){
                commit('mutSetLineKey', {id: lineId, key: 'price', value: data.price})
            }
        } catch (e) {

        }

    },
    actAddToCart: async ({commit, dispatch, rootState}, {hasKit, ...payload}) => {
        try {
            const { data } = await api.post('/shoppingCart', payload)
            toast.success('Product added to cart');
            if(hasKit){
                setTimeout(() => {
                    toast.warning("This product is part of a kit. Other ancillary\n" +
                        "items will be added to the shopping cart")
                }, 200)

            }
            dispatch('actGetCart', rootState.auth.loggedUser.id)
        } catch (e) {

        }
    },
    actRepeatOrder: async ({commit, dispatch, rootState}, saleOrderId) => {
        try {
            const {data} = await api.post(`/ShoppingCart/repeatOrder/${saleOrderId}`);
            dispatch('actGetCart', rootState.auth.loggedUser.id);
            toast.success({
                component: CustomToast,
                props: {
                    message: 'Order added to cart. <span style="text-decoration: underline; cursor: pointer">Click here to continue to checkout</span>'
                },
                listeners: {
                    'toastClicked': () => router.push('/shopping-cart')
                }
            });
        } catch (e) {

        }
    },
    actGetCart: async ({commit}, userId) => {
        try {
            const {data} = await api.get(`/ShoppingCartSummary/getByUser/${userId}`, {
                customError: true
            })
            console.log(data, 'actGetCart')
            commit('mutSetShoppingCart', data);
        } catch (e) {
            commit('mutSetShoppingCart', null);
            const {errors, status, title} = e.response?.data || {errors: [{description: 'Something wrong happened'}]};
            if(errors[0]?.code !== 'ShoppingCartSummary.NotFound'){

                // errorToast(e);
            }
        }
    },
    actDeleteCartLine: async ({commit, dispatch, rootState}, id) => {
        try {
            const {data} = await api.delete(`/ShoppingCartLine/${id}`);
            await dispatch('actGetCart', rootState.auth.loggedUser.id)
            // commit('mutDeleteCartLine', id);
            commit('mutCalculateTotal');
            toast.success('Product is removed from cart')
        } catch (e) {

        }
    },
    actUpdateLineQuantity: async ({commit, dispatch, rootState}, payload) => {
        try {
            const {data} = await api.put(`/ShoppingCartLine`, payload);
            await dispatch('actGetCart', rootState.auth.loggedUser.id)
        } catch (e) {

        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
