import {validateEmail, validatePassword} from "@/utils/helperFunctions";

export default{
    data() {
        return {
            formSubmitted: false,
            errors: {
                password: '',
                confirmPassword: '',
                // email: ''
            },
            passwordError: false,
            confirmPasswordError: false,
            emailError: false,
        }
    },
    computed:{
        formIsValid(){
            return !this.passwordError && !this.confirmPasswordError && !this.emailError;
        }
    },
    methods: {
        validatePasswordForm({password, confirmPassword, email}) {
            console.log('validating form password confirmPassword', password.length, confirmPassword)
            this.formSubmitted = true;
            if (!validatePassword(password)) {
                this.passwordError = true;
                this.errors.password = 'Please note that the password must be a minimum of 12 characters (7 lower case, 1 upper case, 2 numbers and 2 symbols).';
            } else {
                this.passwordError = false;
                this.errors.password = '';
            }
            if (confirmPassword !== password || !confirmPassword) {
                this.confirmPasswordError = true;
                this.errors.confirmPassword = 'Passwords should be the same';
            } else {
                if(this.passwordError){
                    this.confirmPasswordError = true;
                } else {
                    this.confirmPasswordError = false;
                    this.errors.confirmPassword = '';
                }
            }
            if(validateEmail(email)){
                this.emailError = false;
                this.errors.email = '';
            } else {
                this.emailError = true;
                this.errors.email = 'Please enter a valid email';
            }
        },

    }
}
