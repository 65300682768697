import {validateEmail, validatePhoneNumber} from "@/utils/helperFunctions";

export default{
    data() {
        return {
            errors: {
                firstName: '',
                lastName: '',
                email: '',
                title: '',
                phoneNumber: ''
            },
            formSubmitted: false,
            firstError: false,
            lastError: false,
            emailError: false,
            phoneNumberError: false,
            titleError: false,
        }
    },
    computed:{
        formIsValid(){
            return !this.titleError && !this.firstError && !this.lastError && !this.emailError && !this.phoneNumberError;
        }
    },
    methods: {
        validateForm({title, firstName, lastName, email, phoneNumber}) {
            this.formSubmitted = true;
            if (!title) {
                this.titleError = true;
                this.errors.title = 'Title is required';
            } else {
                this.titleError = false;
                this.errors.title = '';
            }
            if (firstName.length < 3 || firstName > 10) {
                this.firstError = true;
                this.errors.firstName = 'First name should be longer than 3 characters and less than 10!';
            } else {
                this.firstError = false;
                this.errors.firstName = '';
            }
            if (lastName.length < 3 || lastName.length > 10) {
                this.lastError = true;
                this.errors.lastName = 'Last name should be longer than 3 characters and less than 10!';
            } else {
                this.lastError = false;
                this.errors.lastName = '';
            }
            if (phoneNumber) {
                if(!validatePhoneNumber(phoneNumber)){
                    this.phoneNumberError = true;
                    this.errors.phoneNumber = 'Phone number is invalid';
                } else {
                    this.phoneNumberError = false;
                    this.errors.phoneNumber = '';
                }
            } else {
                this.phoneNumberError = false;
                this.errors.phoneNumber = '';
            }
            if(validateEmail(email)){
                this.emailError = false;
                this.errors.email = '';
            } else {
                this.emailError = true;
                this.errors.email = 'Please enter a valid email';
            }
        },

    }
}
