<script>

import SimpleTable from "@/components/order/SimpleTable.vue";
import tab from "bootstrap/js/src/tab";
import {format} from "date-fns";
//
// "documentType": 0,
//     "documentLink": "https://psdevelopmentstorage.blob.core.windows.net/dynamics-dev/O2C/salesorder_confirmation/SO058028&PO000033&CD_000014&WP_C000065&SO058028-15&19_03_2024.pdf?sv=2018-03-28&sr=b&sig=uZjsFA%2FtQjA4LyhQHDFsNjvu5q38hDVxHWWexwHqI%2FQ%3D&se=2044-03-20T00%3A00%3A00Z&sp=r",
//     "wpSalesId": "PO000033",
//     "d365OrderId": "SO058028",
//     "wpCustomerMasterDataId": "WP_C000065",
//     "d365CustomerId": "Nova-000000021",
//     "docDate": "2024-03-19T00:00:00",
//     "docNo": "SO058028-15",
//     "hasValue": true
export default {
    name: "DocuRefModal",
    components: {SimpleTable},
    props: ['docuRefs'],
    computed:{
        tab() {
            return tab
        },
        isOrder(){
            return !!this.docuRefs[0]?.wpSalesId
        },
        docuRefUI(){
            let extra = []
            if(this.isOrder){
                extra = [
                    {key: 'wpSalesId', title: 'Sales Order Portal ID'},
                    {key: 'd365OrderId', title: 'Sales Order D365 ID'},
                ]
            }
            return [
                ...extra,
                {key: 'wpCustomerMasterDataId', title: 'Portal Customer ID'},
                {key: 'd365CustomerId', title: 'D365 Customer ID'},
                {key: 'documentType', title: 'Document Type'},
                {key: 'docDate', title: 'Document Date'},
                {key: 'docNo', title: 'Document No.'},
                {key: 'documentLink', title: 'Document URL'},
            ]
        },
        docTypes(){
            return {
                0: 'Sales Order Confirmation',
                1: 'Sales Order Delivery Note',
                2: 'Sales Order Invoice Note',
                3: 'Customer Statement',
            }
        }
    },
    methods:{
        formatDate(date){
            console.log(date, 'dateeee')
            if(date)
                return format(date, 'dd MMMM yyyy, HH:mm');
            return 'No Date'
        }
    }
}
</script>

<template>
    <div class="modal fade" id="docsInfo">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4>{{isOrder ? 'Order Documents' : 'Customer Statements'}}</h4>
                    <button class="btn-close max-w-max" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="default-according style-1" id="docuRefAccordion">
                            <div class="card mb-4">
                                <div
                                    class="card-header cursor-pointer bg-primary"
                                    style="padding: 20px !important;"
                                    data-bs-toggle="collapse"
                                    :data-bs-target="`#docuRef`"
                                    aria-expanded="true"
                                    :aria-controls="`docuRef`"
                                >
                                    <div class="d-flex">
                                        <div>
                                            <div class="d-flex align-items-center">
                                                <i class="fa fa-tag"></i>
                                                <h6 class="card-title">{{isOrder ? 'Order Documents' : 'Customer Statements'}}</h6>
                                            </div>
                                        </div>
                                        <button class="btn max-w-max btn-white ml-auto" style="height: fit-content">
                                            <i class="fa fa-eye"></i>
                                            Details
                                        </button>
                                    </div>

                                </div>
                                <div :id="`docuRef`"
                                     class="collapse show"
                                     :aria-labelledby="`docuRef`">
                                    <div class="card-body">
                                        <simple-table :columns="docuRefUI" :data="docuRefs">
                                            <template #columnHead="{column}">
                                                {{column.title}}
                                            </template>
                                            <template #columnRow="{tableData, column: {key, title}}">
                                                <div v-if="key === 'documentType'">
                                                    {{docTypes[tableData.documentType]}}
                                                </div>
                                                <div v-else-if="key === 'docDate'">
                                                    {{ formatDate(tableData.docDate) }}
                                                </div>
                                                <a :href="tableData.documentLink" target="_blank" v-else-if="key === 'documentLink'">
                                                    View File
                                                </a>
                                                <div v-else>
                                                    {{tableData[key] || 'N/A'}}
                                                </div>
                                            </template>
                                        </simple-table>
<!--                                        <simple-table v-if="order" :columns="orderUI" :data="[order]">-->
<!--                                            <template #columnHead="{column}">-->
<!--                                                {{orderUILabels(column)}}-->
<!--                                            </template>-->
<!--                                        </simple-table>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
