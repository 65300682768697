<script>
import api from "@/utils/api";
import {mapState} from "vuex";
import SettingsInput from "@/components/settings/SettingsInput.vue";
import {defaultAddressInputs} from "@/constants/masterData";

export default {
    name: "DlvAddressCheckout",
    components: {SettingsInput},
    props: ['deliveryAddress'],
    data(){
        return {
            dlvAddress: [],
            loading: true,
            currOption: null,
        }
    },
    computed: {
        ...mapState({
            shoppingCart: state => state.product.shoppingCart
        }),
        dlvInputs(){
            const inputKeys = [
                'd365AddressLocationID',
                'addressValidToUtc',
                'addressValidFromUtc',
            ]
            const filtered = defaultAddressInputs.filter(input => {
                return !inputKeys.includes(input.key)
            })

            return [
                {type: 'string', key: 'phone', label: 'Telephone'},
                {type: 'string', key: 'email', label: 'Email'},
                ...filtered
            ]

        },
    },
    methods:{
        setDlvData(data){
            let phone, email;
            for(const contact of data.contacts){
                if(contact.communicationType === 1){
                    phone = contact.locator
                } else {
                    email = contact.locator
                }
            }
            return {...data, phone, email}
        },
        async getDeliveryAddress(){
            try {
                const {data} = await api.get(`/CustomerMasterData/getDeliveryAddresses/${this.shoppingCart.customerMasterDataId}`)
                this.dlvAddress = data;
            } catch (e) {

            } finally {
                this.loading = false;
            }
        },
        async handleChange({target: {value}}){
            if(value){
                const id = JSON.parse(value);
                // this.currOption = id;
                const address = this.dlvAddress.find(a => a.id === id);
                try {
                    const {data} = await api.put('/ShoppingCartSummary/setDeliveryAddress', {
                        "shoppingCartId": this.shoppingCart.shoppingCartId,
                        "deliveryAddressId": id
                    })

                    this.$emit('setDeliveryAddress', this.setDlvData(address));
                    this.$emit('validate', true);
                } catch (e) {
                    this.$emit('validate', false);
                }
            }
        }
    },
    created() {
        this.getDeliveryAddress();
    }
}
</script>

<template>
    <div class="flex-grow-1">
        <div v-if="!loading && !dlvAddress.length" class="d-flex h-100 flex-column justify-content-center align-items-center gap-3">
            <h5 class="font-warning">There are no delivery addresses or they are invalid!</h5>
        </div>
        <div class="row" v-else>
            <div class="col-6">
                <div class="form-group">
                    <label class="col-form-label" for="selAddress">Delivery to selected address</label>
                    <select id="selAddress"
                            @change="handleChange"
                            class="form-select">
                        <option :selected="!deliveryAddress?.id" disabled="" value="">Please select an address</option>
                        <option v-for="opt in dlvAddress"
                                :selected="opt.id === deliveryAddress?.id"
                                :value="opt.id"
                                :key="opt.id">
                            {{  opt.branchName }}
                        </option>
                    </select>
                </div>
            </div>
            <settings-input v-for="input in dlvInputs"
                            :class="['col-6', {'d-none': !deliveryAddress[input.key]}]"
                            :key="input.key"
                            :disabled="true"
                            :input="input"
                            :model-value="deliveryAddress[input.key]"
                            :settings-data="deliveryAddress"
            />
        </div>
    </div>
</template>

<style scoped>

</style>
