import api from "@/utils/api";
import JsonViewer from "json-viewer-js";
import {useToast} from "vue-toastification";
import {addMonths} from 'date-fns'
const toast = useToast();

const addressDateExpCalculate = (newAddress) => {
    if(newAddress.status === 1){
        newAddress.addressExpiryDateUtc = null;
    } else {
        if(!newAddress.addressCheckDateUtc){
            newAddress.addressCheckDateUtc = new Date();
        }
        newAddress.addressExpiryDateUtc = addMonths(newAddress.addressCheckDateUtc, 6);
    }
    return newAddress;
}

const state = {
    customerMasterData: null,
    editAddressModal: {
        inputs: null,
        data: null,
        title: null,
        type: null
    }
}

const getters = {

}

const mutations = {
    mutUpdateAddress: (state, newAddress) => {
        const idx = state.customerMasterData.addresses.findIndex(a => a.id === newAddress.id);

        state.customerMasterData.addresses.splice(idx, 1, addressDateExpCalculate(newAddress));
    },
    mutDeleteAddress: (state, id) => {
        state.customerMasterData.addresses = state.customerMasterData.addresses.filter(a => a.id !== id);
    },
    mutEditAddress: (state, {key, value}) => {
        console.log({key, value})
        state.editAddressModal.data[key] = value;
    },
    mutCloneEditAddress: (state, editAddress) => {
        const clone = JSON.parse(JSON.stringify(editAddress));
        console.log({clone})
        if(clone?.data){
            if(!clone.data.addressValidFromUtc && !clone.data.addressValidToUtc){
                clone.data.addressValidFromUtc = new Date()
                clone.data.addressValidToUtc = new Date('12 31 2099')
            }
        }

        state.editAddressModal = clone;
    },
    mutAddNewAddress: (state, address) => {
        console.log({address}, 'dataaaaaaa')
        if(!state.customerMasterData.addresses){
            state.customerMasterData.addresses = [];
        }
        if(address.length){
            const calcAddrs = address.map(add => addressDateExpCalculate(add));
            console.log('calcAddrs::', calcAddrs)

            state.customerMasterData.addresses = [...state.customerMasterData.addresses, ...calcAddrs]
            console.log(state.customerMasterData.addresses, 'state.customerMasterData.addressesstate.customerMasterData.addresses')
        } else {
            state.customerMasterData.addresses = [...state.customerMasterData.addresses, addressDateExpCalculate(address)]
        }
    },
    mutAddNewContact: (state, contacts) => {
        let newContacts = contacts.length ? contacts : [contacts]
        for(const contact of newContacts){
            const found = state.customerMasterData.addresses.find(addr => addr.id === contact.addressId);
            if(found){
                const contactExist = found.contacts.findIndex(c => c.id === contact.id);
                if(contactExist >= 0){
                    found.contacts.splice(contactExist, 1, contact);
                } else {
                    found.contacts.push(contact);
                }
            }
        }

    },
    mutDeleteContact: (state, {addressId, contactId}) => {
        let found = state.customerMasterData.addresses.find(addr => addr.id === addressId);
        if(found){
            const contactExist = found.contacts.findIndex(c => c.id === contactId);
            found.contacts.splice(contactExist, 1);
        }
    },
    mutMasterData: (state, value) => {
        state.customerMasterData = value;
    },
    mutMasterDataByKey: (state, {key, value}) => {
        // const el = document.getElementById('data')
        // el.innerHTML = ''
        state.customerMasterData[key] = value;
        // new JsonViewer({
        //     container: el,
        //     data: JSON.stringify(state.customerMasterData),
        //     theme: 'light',
        //     expand: true
        // });
    },
    mutMasterAddress: (state, {key, value, id}) => {
        let addr = state.customerMasterData.addresses.find(a => a.id === id);
        addr[key] = value;
    },
    mutMasterContact: (state, {key, value, addressId, id}) => {
        let addr = state.customerMasterData.addresses.find(a => a.id === addressId);
        if(addr){
            let contact = addr.contacts.find(c => c.id === id);
            contact[key] = value;
        }
    }
}

const actions = {
    actCustomerMasterData: async ({commit}, customerId) => {
        console.log('actCustomerMasterData')
        try {
            const {data} = await api.get(`/CustomerMasterData/${customerId}`);
            commit('mutMasterData', data);
        } catch (e) {
            // errorToast(e)
        }
    },
    actUpdateCustomerMasterData: async ({commit}, masterData) => {
        const {id, ...rest} = masterData;
        try {
            const {data} = await api.put(`/CustomerMasterData/${id}`, rest);
            toast.success('Customer Master Data updated successfully')
            console.log('dataaaaaaaa:::',data)
        } catch (e) {
            // errorToast(e)
        }
    },
    actUpdateCustomerAddress: async ({commit}, {payload, rawData}) => {
        const {id, ...rest} = payload;
        try {
            const {data} = await api.put(`/address/${id}`, rest)
            commit('mutUpdateAddress', rawData)
            toast.success('Address updated with success');
            return 'success';
        } catch (e) {
            // errorToast(e)
        }
    },
    actCreateNewCustomerAddress: async ({commit}, {payload, users}) => {
        try {
            const {data} = await api.post('/address', payload);
            commit('mutAddNewAddress', {
                id: data,
                ...payload,
                accountum: payload.customerMasterDataId,
                users
            })
            toast.success('Address created with success')
            return 'success';
        } catch (e) {
            // errorToast(e)
        }
    },
    actDeleteAddress: async ({commit}, id) => {
        try {
                const {data} = await api.delete(`/address/${id}`);
                commit('mutDeleteAddress', id)
        } catch (e) {
            // errorToast(e)
        }
    },
    actGetAddresses: async ({commit, rootState}, id) => {
        try {
            const customerMasterDataId = rootState.auth.loggedUser.customerMasterDataId;
            const {data} = await api.get(`/CustomerMasterData/getAddresses/${customerMasterDataId}`);
            console.log(data, 'dataaa')
            if(data.length) commit('mutAddNewAddress', data)
        } catch (e) {

        }
    },
    actDeleteContact: async ({commit}, payload) => {
        try {
            await api.delete(`/contact/${payload.contactId}`);
            commit('mutDeleteContact', payload)
        } catch (e) {

        }
    },
    actGetOtherUsersMd: async ({commit, rootState, state}) => {
        try {
            const customerMasterDataId = rootState.auth.loggedUser.customerMasterDataId;
            const {data: {invoiceAddress, ...rest}} = await api.get(`/CustomerMasterData/otherUsers/${customerMasterDataId}`);
            commit('mutMasterData', {
                ...state.customerMasterData,
                ...rest
            })
            if(invoiceAddress) commit('mutAddNewAddress', invoiceAddress);
        } catch (e) {

        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
