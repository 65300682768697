<script>
export default {
    name: "ChildItemCounter",
    props: ['modelValue', 'update:modelValue'],
    methods:{
        decreaseCount(){
            if(this.modelValue > 0){
                this.handleUpdate(this.value - 1)
            }
        },
        increaseCount(){
            this.handleUpdate(this.value + 1)
        },
        handleUpdate(value){
            this.$emit('update:modelValue', value);
            this.$emit('updated')
        }
    },
    computed:{
        value(){
            return Number(this.modelValue) || 0
        }
    }
}
</script>

<template>
    <div class="media">
        <div class="media-body">
            <div class="qty-box">
                <div class="input-group">
                    <span class="input-group-prepend">
                        <button
                            class="btn quantity-left-minus"
                            type="button"
                            data-type="minus"
                            @click="decreaseCount"
                            data-field="">
                            -
                        </button>
                    </span>
                    <input
                        class="form-control input-number p-0"
                        type="text"
                        name="quantity"
                        :value="value"
                        @input="(e) => handleUpdate(e.target.value)"
                    />
                    <span class="input-group-prepend">
                        <button
                            class="btn quantity-right-plus"
                            type="button"
                            data-type="plus"
                            @click="increaseCount"
                            data-field="">
                            +
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

.qty-box .input-group .btn {
    padding: 0 7px;
    margin: auto;
    background-color: #F7F6FF !important;
    border-color: #F7F6FF !important;
    border-radius: 100%;
    font-size: 14px;
    color: #2F2F3B;
}

.qty-box span{
    background-color: white;
    display: flex;
}
</style>
