<script>
import {mapActions, mapMutations} from "vuex";

export default {
    name: "UserNotifications",
    props: {
        notification: {
            type: Object,
            default: () => {
                return {
                    title: 'Message',
                    description: 'Message description',
                    color: 4,
                    order: 1,
                    isDisplayed: false,
                }
            }
        },
        display: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        options(){
            let color, icon;
            switch (this.notification.color) {
                case 1:
                    color = 'alert-success';
                    icon = 'fa-check';
                    break;
                case 2:
                    color = 'alert-primary';
                    icon = 'fa-info-circle';
                    break;
                case 3:
                    color = 'alert-warning';
                    icon = 'fa-warning'
                    break;
                case 4:
                    color = 'alert-danger';
                    icon = 'fa-warning'
                    break;
                default:
                    color = 'alert-secondary';
                    icon = 'fa-bell'
            }
            return {color, icon};
        }
    },
    methods: {
        ...mapMutations({
            mutNotificationState: 'notifications/mutNotificationState',
            mutDisplay: 'notifications/mutDisplay',
        }),
        ...mapActions({
            actUpdateNotification: 'notifications/actUpdateNotification',
            actDeleteNotification: 'notifications/actDeleteNotification'
        }),
        handleEdit(){
            this.mutNotificationState({key: 'currentNotification', value: this.notification});
        },
        async handleDisplayChange(){
            const {title, description, color, isDisplayed, id} = this.notification;
            await this.actUpdateNotification({
                notification: {
                    title,
                    description,
                    color,
                    isDisplayed: !isDisplayed
                },
                id
            })
        },
        handleDelete(){
            this.$emit('delete', this.notification.id);
        }
    }
}
</script>

<template>
    <div :id="notification.id" :class="['alertPadding alert gap-2 d-flex align-items-center', options.color, {'inverse': !display}]" role="alert">
        <i :class="['fa fs-4 d-flex', options.icon]"></i>
        <div class="px-2">
            <h5 class="alert-heading">{{notification.title}}</h5>
            <p>{{notification.description}}</p>
        </div>
        <div v-if="!display" class="d-flex ml-auto align-items-center gap-2">
            <button type="button"
                    @click="handleDisplayChange"
                    :class="['shadow btn btn-sm', (notification.isDisplayed ? 'btn-success' : 'btn-dark')]">
                <i :class="['fa p-0 position-relative h-auto m-0', (notification.isDisplayed ? 'fa-eye bg-success' : 'fa-eye-slash bg-dark')]"></i>
            </button>
            <button @click="handleEdit"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#createSettingModal"
                    class="btn btn-primary shadow btn-sm">
                <i class="fa fa-pencil p-0 bg-primary position-relative h-auto m-0"></i>
            </button>
            <button @click="handleDelete"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteNotificationModal"
                    class="btn btn-danger btn-sm shadow">
                <i class="fa fa-trash bg-danger p-0 position-relative h-auto m-0"></i>
            </button>
        </div>
    </div>
</template>

<style scoped>
.alert.inverse i:before {
    align-self: center;
}

.alert.inverse {
    padding: 5px 20px 5px 65px !important;
    border-radius: 5px 0 0 5px;
}
</style>
