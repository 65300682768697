<script>
import Setting_table from "@/pages/settings/setting_table.vue";
import TableSettings from "@/components/settings/TableSettings.vue";
import api from "@/utils/api";
import {useToast} from "vue-toastification";

export default {
    name: "custErrorLogs",
    components: {TableSettings, Setting_table},
    methods:{
        async handleResend(id){
            try {
                const {data} = await api.post(`/CustomerMasterData/resend/${id}`);
                useToast().success('Action completed successfully')
            } catch (e) {

            }
        }
    }
}
</script>

<template>
    <div>
        <Breadcrumbs main="Customer Interface Log Table" title="View List" />
        <div class="container">
            <table-settings
                dateFormat="dd MMMM yyyy, HH:mm"
                :setting-type="{title: 'Customer Error Logs', api: 'CustomerMasterDataDynamics', CUD: [0,1,0]}"
            >
                <template #title-isSuccess="{key}">
                    Status
                </template>
                <template #booleanCell-isSuccess="{cellData}">
                    {{cellData ? 'Processed' : 'Pending'}}
                </template>
                <template #actions="{rowData: {customerMasterDataId, isSuccess}}">
                    <button class="btn btn-outline-primary" @click="handleResend(customerMasterDataId)">
                        Resend
                    </button>
                </template>
            </table-settings>
        </div>
    </div>
</template>

<style scoped>

</style>
