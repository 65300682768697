<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
    name: "SmallProductview",
    props: ['cartLine'],
    data(){
        return {
            loading: false
        }
    },
    computed:{
        ...mapState({
            shoppingCart: state => state.product.shoppingCart
        }),
        ...mapGetters({
            gCartCurrency: "product/gCartCurrency",
            gProductPageLink: 'product/gProductPageLink'
        }),
        inputModel:{
            get(){
                return this.cartLine.qty;
            },
            set(val){
                this.handleQuantityChange(val)
            }
        }
    },
    methods:{
        ...mapActions({
            'actDeleteCartLine': "product/actDeleteCartLine",
            'actUpdateLineQuantity': "product/actUpdateLineQuantity",
        }),
        deleteLine(){
            this.actDeleteCartLine(this.cartLine.id)
        },
        handleQuantityCounter(val){
            const newQuantity = this.cartLine.qty + val;
            this.handleQuantityChange(newQuantity);
        },
        async handleQuantityChange(val){
            if(!val) return
            this.loading = true;
            // this.mutQuantity(val);

            await this.actUpdateLineQuantity({lineId: this.cartLine.id, shoppingCartId: this.shoppingCart.shoppingCartId, newQuantity: val})
            this.loading = false;
        }
    }
}
</script>

<template>
    <div class="media">
        <router-link :to="gProductPageLink(cartLine.productId)">
            <img
                class="img-fluid b-r-5 me-3 img-60"
                :src="cartLine.urlImageThumb"
                alt=""
            />
        </router-link>
        <div class="media-body">
            <span>{{ cartLine.productName }}</span>
            <div class="qty-box">
                <div class="input-group">
                  <span class="input-group-prepend">
                    <button
                        class="btn quantity-left-minus"
                        type="button"
                        :disabled="loading"
                        data-type="minus"
                        data-field=""
                        @click="handleQuantityCounter(-1)"
                    >
                      -
                    </button>
                  </span>
                    <input
                        class="form-control input-number"
                        :disabled="loading"
                        type="text"
                        name="quantity"
                        v-model.number="inputModel"
                    /><span class="input-group-prepend">
                    <button
                        class="btn quantity-right-plus"
                        :disabled="loading"
                        type="button"
                        data-type="plus"
                        data-field=""
                        @click="handleQuantityCounter(1)"
                    >
                      +
                    </button></span
                >
                </div>
            </div>
            <h6 class="mb-0">{{gCartCurrency}} {{ cartLine.price }}</h6>
        </div>
        <div class="close-circle">
            <a @click="deleteLine" class="cursor-pointer bg-danger"><vue-feather type="x"></vue-feather></a>
        </div>
        <h6 class="font-primary mb-0 mt-auto">{{gCartCurrency}} {{ cartLine.totalPrice }}</h6>
    </div>

</template>

<style scoped>

</style>
