<template>
        <div class="card">
            <div class="card-body">
                <div class="product-page-details">
                    <h3>{{ singleProduct?.name }}</h3>

                </div>
                <div :class="['product-price animated', fadeClass]" :key="currPrice">
                    {{singleProduct?.currencyCode}} {{ currPrice }}
                </div>
                <hr />
                <p>
                    {{ singleProduct?.description}}
                </p>
                <hr />
                <div class="col-10 col-sm-6 col-md-8 col-xl-6">
                    <div v-for="d in displayData" class=" mb-2 d-flex gap-2 justify-content-between">
                        <div class="fw-bold">
                            {{d.title}} :
                        </div>
                        <div v-if="d.title === 'Availability'"
                             :class="singleProduct ? singleProduct[d.key] ? 'font-danger' : 'font-success' : ''">
                            {{singleProduct ? singleProduct[d.key] ? 'Out of stock' : 'In stock' : ''}}
                        </div>
                        <div v-else>
                            {{singleProduct && singleProduct[d.key] ? singleProduct[d.key] : '---'}}
                        </div>
                    </div>
                </div>
                <hr />
                <div class="my-auto d-flex justify-content-between">
                    <fieldset class="qty-box">
                        <div class="input-group bootstrap-touchspin">
                            <button :disabled="loading" @click="handleQuantityCounter(-1)" class="btn btn-primary btn-square bootstrap-touchspin-down"
                                    type="button">
                                <i class="fa fa-minus"></i></button>
                            <input class="touchspin text-center form-control"
                                   v-model.number="inputModel"
                                   type="text">
                            <button :disabled="loading" @click="handleQuantityCounter(1)" class="btn btn-primary btn-square bootstrap-touchspin-up"
                                    type="button">
                                <i class="fa fa-plus"></i></button>
                        </div>
                    </fieldset>
                    <button :disabled="!singleProduct?.price || singleProduct.psdwP_OutOfStock || loading" @click="handleAddToCart" class="btn btn-primary">
                        <i class="fa fa-shopping-basket me-1"></i>
                        Add To Cart
                    </button>
                </div>
            </div>
        </div>
</template>
<script>
import {mapActions, mapMutations, mapState} from "vuex";
export default {
    name: "ProductDetails",
    data(){
        return {
            loading: false,
            fadeClass: 'fadeInUp'
        }
    },
    computed: {
        ...mapState({
            singleProduct: state => state.product.singleProduct,
            currQuantity: state => state.product.currQuantity,
            currentCustomer: state => state.product.currentCustomer,
        }),
        inputModel:{
            get(){
                return this.currQuantity;
            },
            set(val){
                this.handleQuantityChange(val)
            }
        },
        currPrice(){
            if(this.singleProduct?.price){
                return this.singleProduct.price;
            }
            return ' No Price'
        },
        displayData(){
            return [
                {title: 'Barcode', key: 'barcode'},
                {title: 'Code', key: 'barcode'},
                {title: 'SKU', key:'d365FO_Item_Number_SKU'},
                {title: 'Unit', key: 'unitOfMeasureSymbol'},
                {title: 'Availability', key:'psdwP_OutOfStock'}
            ]
        }
    },
    methods:{
        ...mapMutations({
            mutQuantity: "product/mutQuantity"
        }),
        ...mapActions({
            actGetPrice: "product/actGetPrice",
            actAddToCart: 'product/actAddToCart'
        }),
        async handleAddToCart(){
            this.loading = true;
            await this.actAddToCart({
                "customerMasterDataId": this.currentCustomer,
                "productId": this.singleProduct.productId,
                "quantity": this.currQuantity,
                hasKit: this.singleProduct.hasKit
            })
            this.loading = false;
        },
        handleQuantityCounter(val){
            const newQuantity = this.currQuantity + val;
            this.handleQuantityChange(newQuantity);
        },
        async handleQuantityChange(val){
            this.loading = true;
            if(val > this.currQuantity){
                this.fadeClass = 'fadeInUp'
            } else {
                this.fadeClass = 'fadeInDown'
            }
            this.mutQuantity(val);
            await this.actGetPrice({productId: this.singleProduct.productId, customerKey: this.currentCustomer, quantity: this.currQuantity})
            this.loading = false;
        }
    }
};
</script>
<style scoped>

.qty-box {
    border-radius: 5px;
    overflow: hidden;
    input {
        width: 74px;
        max-width: 74px;
        background-color:transparent;
        border: none !important;
    }
    .input-group{
        justify-content: space-between;
        align-items:center;
        background-color:transparent;
        border-radius:5px;
        border: 1px solid #ecf3fa;
        overflow:hidden;
    }
    .btn{
        background-color:transparent;
        border: none;
        &.bootstrap-touchspin-down {
            border-right: 1px solid;
            [dir="rtl"] & {
                border-right: unset;
                border-left: 1px solid;
            }
        }
        &.bootstrap-touchspin-up {
            border-left: 1px solid;
            [dir="rtl"] & {
                border-left: unset;
                border-right: 1px solid;
            }
        }
    }
}
</style>
