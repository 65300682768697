<template>
    <div class="card position-relative overflow-hidden">
        <polar-loader v-if="loading">
            {{loadingTitle }}
        </polar-loader>
        <div class="card-header d-flex" v-if="!userDontExist">
            <h3 class="card-title mb-0">{{ creatingUser ? 'Create User' : 'Edit User' }}</h3>
            <div v-if="!creatingUser" class="ml-auto fw-bold font-primary fs-6">
                {{ userTitle }}
            </div>
        </div>
        <div class="card-header d-flex align-items-center" v-else>
            <h5 class="card-title mb-0 font-danger">User with email: <span class="text-lowercase">{{$route.params.userEmail}} doesn't exist!</span> </h5>
        </div>
        <div class="card-body" v-if="!userDontExist">
            <form class="theme-form" novalidate="">
                <div class="d-flex justify-content-between mb-3">
                    <h4>Account Information</h4>
                    <button type="button" v-if="!userInformation?.isEmailConfirmed && routeEmail" @click="handleEmailResend" class="btn btn-primary">Resend Confirmation Email</button>
                </div>
                <div class="row">
                        <settings-input
                            :class="[(input.customClass || 'col-12 col-sm-6')]"
                            v-for="input in accountInputs" :key="input.key"
                            :settings-data="userInformation"
                            :input="input"
                            @update:model-value="(val) => regulatePharmacist(input.key, val)"
                            v-model="userInformation[input.key]"
                        />
                </div>
                <hr class="mt-4 mb-4">
                <h4 class="pb-3 mb-0">Permissions</h4>
                <div class="row">
                    <div class="col-12 col-md-5 col-lg-4 mb-3">
                        <label>
                            User Permissions
                        </label>
                        <label v-for="perm in permissions" :key="perm.key" class="d-block cursor-pointer" :for="perm.key">
                            <input @change="handlePermissionChange(perm.key, perm.importance)" class="checkbox_animated" :id="perm.key" type="checkbox" :checked="userInformation.permission[perm.key]">
                            {{perm.title}}
                        </label>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <label for="userRole">
                            User Role
                        </label>
                        <select v-model="userInformation.role" class="form-select" style="width: 170px;" id="userRole">
                            <option v-for="role in roles" :key="role.key">{{role.title}}</option>
                        </select>
                    </div>
                    <div class="col-12 col-md-3 col-lg-4 mb-3">
                        <label for="isActive">
                            User Status
                        </label>
                        <label :class="['d-block', {'cursor-pointer': canModify}]" for="isActive">
                            <input v-model="userInformation.isActive" :disabled="!canModify" class="checkbox_animated" id="isActive" type="checkbox">
                            Is Active
                        </label>
                    </div>
                </div>
            </form>
        </div>
        <div class="card-footer d-flex gap-2" v-if="!userDontExist">
            <button class="btn btn-primary ml-auto" @click="updateCreateUser">
                {{ creatingUser ? 'Create User' : 'Update User' }}
            </button>
            <button class="btn btn-light" @click="handleCancelEdit">Cancel</button>
        </div>
    </div>
</template>
<script>
import PolarLoader from "@/components/PolarLoader.vue";
import api from "@/utils/api";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import createUserValidation from "@/mixins/validations/createUserValidation";
import UserTitle from "@/components/user/UserTitle.vue";
import SettingsInput from "@/components/settings/SettingsInput.vue";
import {useVuelidate} from "@vuelidate/core";
import {email, helpers, minLength} from "@vuelidate/validators";
import {validatePhoneNumber} from "@/utils/helperFunctions";

export default {
    components: {SettingsInput, UserTitle, PolarLoader},
    mixins: [createUserValidation],
    setup(){
        return {
            v$: useVuelidate()
        }
    },
    data(){
        return {
            userDontExist: false,
            permissions: [
                {title: 'Can view client address' , key: 'canViewClientAddress', importance: 1},
                {title: 'Can edit client address' , key: 'canEditClientAddress', importance: 2},
                {title: 'Can create new client address' , key: 'canCreateNewClientAddress', importance: 3},
            ],
            userInformation: {
                "id": null,
                "title": "",
                "firstName": "",
                "lastName": "",
                "phoneNumber": "",
                "phonePrefix": null,
                "email": "",
                "permission": {
                    "canViewClientAddress": false,
                    "canEditClientAddress": false,
                    "canCreateNewClientAddress": false
                },
                "role": "",
                "customerMasterDataId": "",
                "pharmacistRegistrationNumber": "",
                "isRegisteredPharmacist": false
            },
            loading: false,
            loadingTitle: ''
        }
    },
    computed:{
        ...mapState({
            // routeEmail: state => state.user.routeEmail
        }),
        ...mapGetters({
            roles: "auth/userRoles",
            canModifyUser: 'auth/canModifyUser'
        }),
        routeEmail(){
            console.log(this.$route, 'routeeee');
            return this.$route.params.userEmail;
        },
        accountInputs() {
            const disabled = !this.userInformation?.isEmailConfirmed && !this.creatingUser;
            return [
                {label: 'Title', key: 'title', type: 'select', api: 'Title', disabled},
                {label: 'First Name', key: 'firstName', type: 'string', validation: {minLength: minLength(3)}, disabled},
                {label: 'Last Name',
                    customClass: 'col-12 col-lg-6',
                    key: 'lastName', type: 'string', validation: {minLength: minLength(3)}, disabled},
                {label: 'Prefix', type: 'searchSelect', api: 'CountryPrefix', key: 'phonePrefix', trackKey: 'prefix', disabled,
                    displayKey: (countryInfo) => {
                        if(countryInfo){
                            return ` ${countryInfo.prefix}`
                        }
                        return ''
                    },
                    displayOption: (countryInfo) => {
                        return `${countryInfo.country} ${countryInfo.prefix}`
                    },
                    customClass: 'col-3 col-lg-2 p-r-0',
                    filterKeys: ['country', 'prefix']
                },
                {
                    disabled,
                    label: 'Contact Number',
                    customClass: 'col-9 col-lg-4 p-l-0',
                    key: 'phoneNumber',
                    type: 'string',
                    validation: {validatePhoneNumber: helpers.withMessage('Invalid phone number', validatePhoneNumber)}
                },
                {label: 'Email', key: 'email', type: 'string', validation: {email}},
                {
                    disabled,
                    label: 'Customer ID',
                    key: 'customerMasterDataId',
                    type: 'select',
                    api: 'CustomerMasterData',
                    displayKey: 'id',
                    notRequired: true
                },
                {
                    disabled,
                    label: 'User Validation',
                    key: 'status',
                    api: 'Validations',
                    type: 'select',
                    valueType: 'number',
                    notRequired: true
                },
                {
                    label: 'Validated On',
                    key: 'validatedOnUtc',
                    type: 'date',
                    disabled: true,
                    notRequired: true
                },
                {
                    label: 'Expiry Date',
                    key: 'expiryDateUtc',
                    type: 'date',
                    disabled: true,
                    notRequired: true
                },
                {label: 'Registration Number', key: 'pharmacistRegistrationNumber', type: 'string', notRequired: !this.userInformation.isRegisteredPharmacist, immediate: true},
                {
                    label: 'Is Registered to Buy Pharmaceuticals',
                    type: 'boolean',
                    key: 'isRegisteredPharmacist',
                    disabled
                },
            ]
        },
        canModify(){
            return this.canModifyUser(this.userInformation)
        },
        userTitle(){
            if(this.userInformation?.id){
                const {title, firstName, lastName} = this.userInformation
                return `${title}. ${firstName} ${lastName}`;
            }
            return 'Loading...'
        },
        creatingUser(){
            return !this.routeEmail;
        }
    },
    methods:{
        ...mapActions({
            actUpdateUser: 'user/actUpdateUser',
            actResendEmailUser: 'user/actResendEmailUser',
            actCreateUser: 'user/actCreateUser',
        }),
        ...mapMutations({
            mutSetState: 'user/mutSetState'
        }),
        handleEmailResend(){
            this.actResendEmailUser(this.userInformation.id)
        },
        handlePermissionChange(key, importance){
            this.userInformation.permission[key] = !this.userInformation.permission[key];
            for(const perm of this.permissions){
                // if(perm.importance === 1){
                //     this.userInformation.permission[perm.key] = importance > perm.importance
                // }
                if(key !== perm.key){
                    if(perm.key === 'canEditClientAddress'){
                        if(importance < perm.importance){
                            this.userInformation.permission[perm.key] = importance > perm.importance;
                        }
                    } else {
                        this.userInformation.permission[perm.key] = importance > perm.importance;
                    }
                }
            }

        },
        async getUserByEmail(){
            try {
                this.mutSetState({
                    key: 'selectedUserEmail',
                    value: email
                })
                this.loadingTitle = 'Loading user'
                this.loading = true;
                const {data} = await api.get(`/user/${this.routeEmail}`)
                this.userInformation = data;
            } catch (e) {
                this.userDontExist = true;
            } finally {
                this.loading = false;
            }
        },
        async updateCreateUser(){
            // this.validateForm(this.userInformation)
            const result = await this.v$.$validate();
            console.log(result)
            if(result){
                if(this.creatingUser) {
                    this.loadingTitle = 'Creating user'
                    this.loading = true;

                    const {id, permission, ...rest} = this.userInformation;
                    const createUser = {...permission, ...rest};

                    console.log('createUser:::', createUser)
                    const result = await this.actCreateUser(createUser);
                    this.loading = false;
                    console.log(result, 'resultiiii')
                    if(result !== 'error'){
                        this.$emit('userCreated', result)
                    }
                } else {
                    this.loadingTitle = 'Updating user'
                    this.loading = true;
                    await this.actUpdateUser(this.userInformation)
                    this.loading = false;
                    this.$emit('editCanceled')
                }
            }


        },
        handleCancelEdit(){
            this.$emit('editCanceled')
        },
        regulatePharmacist(key, val){
            if(key === 'isRegisteredPharmacist'){
                if(!val){
                    this.userInformation.pharmacistRegistrationNumber = '';
                }
            } else if(key === 'pharmacistRegistrationNumber') {
                this.userInformation.isRegisteredPharmacist = !!val
            }
        }
    },
    mounted() {
        if(this.creatingUser){
            this.userInformation = {
                "id": this.id,
                "title": "",
                "firstName": "",
                "lastName": "",
                "phoneNumber": "",
                "email": "",
                "permission": {
                    "canViewClientAddress": false,
                    "canEditClientAddress": false,
                    "canCreateNewClientAddress": false
                },
                "role": "Regular User",
                "pharmacistRegistrationNumber": "",
                "isRegisteredPharmacist": false
            }
        } else {
            this.getUserByEmail();
        }
    },
    // watch:{
    //     userInformation: {
    //         handler(newVal, oldVal) {
    //             console.log({newVal, oldVal})
    //             // if (this.formSubmitted) {
    //             //     this.validateForm(this.userInformation)
    //             // }
    //         },
    //         deep: true
    //     }
    // }
}
</script>

<style scoped>

</style>
