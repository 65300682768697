<script>
import {mapGetters, mapState} from "vuex";

export default {
    name: "OrderSummary",
    computed:{
        ...mapState({
            shoppingCart: state => state.product.shoppingCart
        }),
        ...mapGetters({
            gSetting: "settings/gSetting",
            gProductPageLink: "product/gProductPageLink"
        }),
        currency(){
            const currencies = this.gSetting('Currency');
            if(currencies){
                const currency = currencies.find(c => c.id === this.shoppingCart?.currencyId)
                if(currency)
                    return currency.code;
            }
            return 'EUR';
        },
        summaryInfo(){
            return [
                {title: 'Sub Total', key: 'subTotalWithoutVAT', value: `${this.currency} ${this.shoppingCart?.subTotalWithoutVAT}`},
                {title: 'VAT', key: 'vat'},
                {title: 'Total (Inc. VAT)', key: 'shoppingCartTotal', value: `${this.currency} ${this.shoppingCart?.shoppingCartTotal}`},
            ]
        },
    },
    methods: {
        prodInfo(product){
            return [
                {title: 'Quantity', value: product.qty},
                {title: 'Price', value: `${this.currency} ${product.price}`},
            ]
        }
    }
}
</script>

<template>
    <div class="filter-block w-100" style="max-width: 350px">
        <h4>Order Summary</h4>
        <div v-if="shoppingCart">
            <div v-for="line in shoppingCart.shoppingCartLines" class="border-bottom mb-2 border-bottom-info" :key="line.id + 'line'">
                <div class="d-flex gap-2 align-items-center min-w-max">
                    <router-link :to="gProductPageLink(line.productId)">
                        <img :src="line.urlImageThumb" class="img-fluid bg-img" style="width: 80px" :alt="line.productName"/>
                    </router-link>
                    <ul>
                        <li v-for="{title, value} in prodInfo(line)" :key="title" class="prdInfo">
                            <div class="min-w-max">
                                {{title}}:
                                <span class="f-w-600">{{value}}</span>
                            </div>
                        </li>
                    </ul>
                    <div style="margin-left: auto; padding-top: 10px;">
                        Total: <br>
                        <span class="f-w-600">{{currency}} {{ line.totalPrice }}</span>
                    </div>
                </div>
            </div>
            <ul class="mt-5">
                <li v-for="{key, title, value} in summaryInfo" :key="key">
                    <div class="d-flex gap-1 mb-2 pb-2 border-bottom border-bottom-info">
                        <div class="min-w-max">
                            {{title}}:
                        </div>
                        <div class="min-w-max ml-auto fw-bold">
                            {{value || shoppingCart[key]}}
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<style scoped>
.prdInfo div{
    font-size: 11px;
}
</style>
