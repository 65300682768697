<script>
import Breadcrumbs from "@/components/bread_crumbs.vue";
import BasketList from "@/components/shop/BasketList.vue";

export default {
    name: "shop_list",
    components: {Breadcrumbs, BasketList}
}
</script>

<template>
    <div>
        <Breadcrumbs main="Shopping Cart" title="View Shopping Cart" />
        <div class="container">
            <BasketList/>
        </div>
    </div>
</template>

<style scoped>

</style>
