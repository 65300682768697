import {createStore} from 'vuex'

import layout from './modules/layout';
import menu from './modules/menu';
import auth from './modules/auth';
import masterdata from './modules/masterdata';
import user from './modules/user';
import notifications from './modules/notifications';
import settings from './modules/settings';
import product from './modules/product';

export default createStore({
  state: {
    langIcon: '',
    langLangauge: '',
    isActive:false,
    globalLoader: false
  },

  getters:{
    langIcon: (state)=>{ return state.langIcon},
    langLangauge:(state)=>{return state.langLangauge},
  },
  mutations: {
      changeLang (state, payload) {
        localStorage.setItem('currentLanguage', payload.id);
        localStorage.setItem('currentLanguageIcon', payload.icon);
        state.langIcon = payload.icon || 'flag-icon-us'
        state.langLangauge = payload.id || 'EN'
        // window.location.reload();
      },
      change(state){
        state.isActive = !state.isActive
      },
      mutGlobalLoader: (state, value) => {
        state.globalLoader = value;
      }
    },
    actions: {
      setLang ({ commit }, payload) {
        commit('changeLang', payload);
      },
      actGlobalLoader: ({commit}, value) => {
        commit('mutGlobalLoader', value);
      }
    },
    modules: {
      layout,
      menu,
      settings,
      user,
      notifications,
      product,
      masterdata,
      auth
    }
});

