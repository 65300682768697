<template>
  <div class="page-title">
    <div class="row">
      <div class="col-6">
        <h3>{{diffMain || main}}</h3>
      </div>
      <div class="col-6">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active">
                <router-link :to="{ path: '/' }">
                    <svg class="stroke-icon">
                        <use href="@/assets/svg/icon-sprite.svg#stroke-home"></use>
                    </svg>
                </router-link>
            </li>
            <li :class="['breadcrumb-item', {'active': !title}]" v-if="main">
                <router-link :to="mainPath" v-if="mainPath">
                    {{main}}
                </router-link>
                <span v-else>{{main}}</span>
            </li>
            <li class="breadcrumb-item active" v-if="title">
<!--                <router-link :to="titlePath" v-if="titlePath">-->
<!--                    {{title}}-->
<!--                </router-link>-->
                <span>{{title}}</span>
            </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    components: {},
    props: {
      title: {
        default: 'home'
      },
      main: {
        default: ''
      },
      mainPath: {
        default: ''
      },
      titlePath: {
        default: ''
      },
      diffMain: {
          default: ''
      }
    }
  };
</script>
