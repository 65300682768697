<script>
import PolarSearch from "@/components/uiKits/PolarSearch.vue";
import api from "@/utils/api";
import {useToast} from "vue-toastification";
import Pagination from "@/components/uiKits/Pagination.vue";
import PolarLoader from "@/components/PolarLoader.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import AddressView from "@/components/masterdata/AddressView.vue";
import EditMasterDataModal from "@/components/masterdata/EditMasterDataModal.vue";
import MasterdataAddress from "@/components/masterdata/MasterdataAddress.vue";
import SettingsInput from "@/components/settings/SettingsInput.vue";
import {addressInputs} from "@/constants/masterData";
import {hardCodeApi} from "@/constants/tableSettings";
import InvoiceAddress from "@/components/masterdata/InvoiceAddress.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

export default {
    name: "masterData",
    components: {
        ConfirmModal,
        InvoiceAddress,
        SettingsInput,
        MasterdataAddress, EditMasterDataModal, AddressView, PolarLoader, Pagination, PolarSearch},
    data(){
        return{
            loading: false,
            selectedIds: [],
            params: {
                searchKey: '',
                columnName: '',
                isDescending: true,
                pageNumber: 1,
                pageSize: 8
            },
            masterData: [],
            pagination: {
                "pageNumber": 1,
                "totalPages": 1,
                "totalCount": 1,
                "hasPreviousPage": false,
                "hasNextPage": false
            },
            displayColumns: [
                {key:'id', title: 'ID'},
                {key:'organizationName', title: 'Company Partnership/Sole Trader Name'},
                {key: 'companyRegistrationNumber', title: 'Registration Number'},
                {key: 'status', title: 'Status' }
            ],
            statusOptions: {
                1: 'In Check',
                2: 'Checked Successfully',
                3: 'Check Not Passed',
                4: 'Canceled',
                5: 'Closed'
            }
        }
    },
    computed:{
        ...mapState({
            loggedUser: state => state.auth.loggedUser,
            customerMasterData: state => state.masterdata.customerMasterData
        }),
        ...mapGetters({
            loggedPermission: "auth/loggedPermission",
            masterDataPermissions: "auth/masterDataPermissions",
        }),
        paymentsDefaults: () => [
            {label: 'Customer Account', type: 'null', key: 'customerMasterDataId'},
            {label: 'Payment Terms', type: 'null', key: 'paymentTermName'},
            {label: 'Payment Mode', type: 'null', key: 'custPaymModeName'},
        ],
        allAPIs(){
            let apis = [];
            const allInputs = {...addressInputs}
            const addedOnce = {}
            for(const key in allInputs){
                for(const input of allInputs[key]){
                    if(input.api && !hardCodeApi[input.api] && !addedOnce[input.api]){
                        addedOnce[input.api] = true;
                        apis.push({url: input.api, params: input.params || null})
                    }
                }
            }
            return apis;
        },
        checkIfDeliveryAddr(){
            return this.customerMasterData?.addresses?.find(a => a.transactionType === 1) || false
        },
        invoiceAddress(){
            return this.customerMasterData?.addresses?.find(a => a.transactionType === 0) || null
        },
    },
    methods:{
        ...mapActions({
            actGetAddresses: 'masterdata/actGetAddresses',
            actGetAllApiData: 'settings/actGetAllApiData',
            actGetOtherUsersMd: 'masterdata/actGetOtherUsersMd',
            actCustomerMasterData: 'masterdata/actCustomerMasterData',
        }),
        async getAddresses(){
            this.loading = true;
            await this.actGetAddresses();
            this.loading = false;
        },
        async getMasterData(){
            try {
                this.loading = true;
                const {data} = await api.get('/CustomerMasterData', {
                    params: this.params
                })
                const {items, ...rest} = data;
                console.log(rest, 'rest')
                this.pagination = rest;
                this.masterData = data.items;
            } catch (e) {
                // const {data} = e.response
                // useToast().error((data?.errors ? data?.errors[0]?.description : null) || 'Something went wrong');
            } finally {
                this.loading = false;
            }
        },
        handleSort(key){
            if(key === this.params.columnName){
                this.params.isDescending = !this.params.isDescending;
            }
            this.params.columnName = key;
            this.params.pageNumber = 1;
            this.getMasterData();
        },
        handleSearch(){
            this.params.pageNumber = 1;
            this.getMasterData();
        },
        handlePaginationEvent(page){
            this.params.pageNumber = page;
            this.getMasterData();
        },
        async handleCMDDelete(){
            try {
                await api.delete(`/CustomerMasterData`, {
                    data: {
                        ids: this.selectedIds
                    }
                });
                useToast().success("Selected master data are anonymized successfully",{icon: 'fa fa-check'});
                this.masterData = this.masterData.filter(data => !this.selectedIds.includes(data.id));
            } catch (e) {
                // const {errors, status, title} = e.response.data
                // errorToast(e)
            }
        }
    },
    async mounted() {
        console.log(this.allAPIs, 'allAPIs')
        console.log({loggedPermission: this.loggedPermission})
        if(this.loggedPermission > 2) {
            await this.getMasterData();
        } else {
            await this.actGetAllApiData(this.allAPIs);
            await this.actGetOtherUsersMd();
            if(this.masterDataPermissions.canViewClientAddress){
                await this.getAddresses();
            }
            // this.actCustomerMasterData(this.loggedUser.customerMasterDataId)
        }
    },
}
</script>

<template>
    <div>
        <div class="modal fade" id="deleteCMDModal" role="dialog"
             aria-labelledby="deleteCMDModal" aria-hidden="true">
            <ConfirmModal @confirmed="handleCMDDelete">
                <template #message>
                    Are you sure you want to anonymize this user?
                </template>
            </ConfirmModal>
        </div>
    <Breadcrumbs main="Customer Master Data" title="Customer Master Data List" />
    <div class="container">
        <div class="row">
            <div class="card position-relative overflow-hidden" v-if="loggedPermission > 2">
                <div class="card-header d-flex align-items-center gap-2">
                    <div class="d-flex w-100 align-items-center justify-content-between">
                        <PolarSearch v-model="params.searchKey" style="max-width: 400px;" @search="handleSearch"/>
                        <button class="btn btn-danger btn-sm"
                                v-if="selectedIds.length > 0"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#deleteCMDModal">
                            <i class="fa fa-user-secret me-2"></i>
                            Anonymize
                        </button>
                    </div>
                </div>
                <div class="card-body" style="min-height: 565px;">
                    <div class="table-responsive stickyLastCol add-project h-100">
                        <table class="table table-hover card-table table-vcenter text-nowrap">
                            <thead>
                            <tr>
                                <th v-for="(col, idx) in displayColumns" :key="col.key" :class="['fw-bold', {'text-center': idx >= 0}]">
                                    <div @click="handleSort(col.key)" :class="['p-1 cursor-pointer max-w-min', {'mx-auto': idx > 0}]">
                                        {{ col.title }}
                                        <i class="fa fa-sort"/>
                                    </div>
                                </th>
                                <th class="fw-bold text-center">
                                    Actions
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="data in masterData" :key="data.id">
                                <td>
                                    <input type="checkbox" v-model="selectedIds" class="checkbox_animated" :value="data.id">
                                </td>
                                <td v-for="(col, idx) in displayColumns" :class="[{'text-center': idx >= 0}]" :key="`${data.id}|${col.key}`">
                                    <div v-if="col.key === 'status'">
                                        {{ statusOptions[data.status] }}
                                    </div>
                                    <div v-else>
                                        {{data[col.key]}}
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex gap-2 mx-auto w-100 justify-content-center">
                                        <router-link :to="`master-data/${data.id}`" class="btn btn-primary btn-sm">
                                            <i class="fa fa-pencil"></i>
                                            Edit
                                        </router-link>

                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer pagination-padding">
                    <pagination :pagination-info="pagination" @pageChanged="handlePaginationEvent"/>
                </div>
                <polar-loader v-if="loading">
                    Loading Table Settings
                </polar-loader>
            </div>
            <div v-else class="col-12">
                <div class="theme-form card">
                    <div class="card-header cursor-pointer d-flex flex-wrap justify-content-between align-items-center">
                        <h4>Payment Defaults</h4>
                    </div>
                    <div class="card-body" v-if="customerMasterData">
                        <div class="row">
                            <div v-for="input in paymentsDefaults"
                                 :class="[(input.col ? input.col : 'col-md-6 col-lg-4'),'d-flex flex-column justify-content-end']"
                                 :key="input.key">
                                <settings-input
                                    :disabled="input.disabled"
                                    :settingsData="customerMasterData"
                                    :model-value="customerMasterData[input.key]"
                                    :input="input"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="theme-form card">
                    <div class="card-header cursor-pointer d-flex flex-wrap justify-content-between align-items-center">
                        <h4>Invoice Address</h4>
                    </div>
                    <div class="card-body">
                        <InvoiceAddress v-if="invoiceAddress"
                                        :invoiceAddress="invoiceAddress"
                                        :editInvoiceAddress="false"/>
                        <h5 v-else class="text-center">There is no invoice addresses</h5>

                    </div>
                </div>
                <div class="theme-form card">
                    <div class="card-header cursor-pointer d-flex flex-wrap justify-content-between align-items-center">
                        <h4>Delivery Addresses</h4>
                        <button class="btn btn-primary max-w-max" disabled>
                            <i class="fa fa-file"></i>
                            Customer Statements
                        </button>
                    </div>
                    <div class="card-body">
                        <masterdata-address v-if="checkIfDeliveryAddr"/>
                        <h5 v-else class="text-center">There are no delivery addresses</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>

</style>
