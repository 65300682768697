<template>
    <suspense>
        <div>
            <lock-screen-modal v-if="store.state.auth.loggedUser?.email"/>
            <router-view/>
        </div>
    </suspense>
</template>
<script setup>
import { useStore } from 'vuex';
import { useSignalR } from '@dreamonkey/vue-signalr';
import {useToast} from "vue-toastification";
import LockScreenModal from "@/components/lockScreen/LockScreenModal.vue";

const store = useStore();
store.dispatch('layout/set');

if(localStorage){
    const auth = localStorage.getItem('auth_info');
    const email = localStorage.getItem('log_email');
    const user = JSON.parse(localStorage.getItem('logged_user'));
    if(email && auth) {
        store.dispatch('auth/actGetLogUserData', email)
        // store.commit('auth/mutSetPermissions', user.role);
        if(user) {
            store.commit('auth/mutSetState', {
                key: 'loggedUser',
                value: user
            });

            if(user?.role === 'Admin' || user?.role === 'Customer Service'){
                const signalr = useSignalR();
                console.log('created connection')
                signalr.on('ReceiveLastUserRegistered', (notification) => {
                    store.dispatch('notifications/actGetWSNotifications', user.id);
                    useToast().success(`A new user was registered ${notification?.email}`)
                });
            }

            store.dispatch('product/actGetCart', user.id);

        }
    }

}

</script>

