<script>
import Pagination from "@/components/uiKits/Pagination.vue";
import PolarLoader from "@/components/PolarLoader.vue";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import EditUser from "@/components/user/EditUser.vue";
import PolarSearch from "@/components/uiKits/PolarSearch.vue";
import {format} from 'date-fns'
import {downloadReport, errorToast} from "@/utils/helperFunctions";
import api from "@/utils/api";
import ProggressButton from "@/components/ProggressButton.vue";
export default {
    components: {ProggressButton, PolarSearch, EditUser, ConfirmModal, Pagination, PolarLoader},
    data(){
        return {
            userUI: [
                {title: 'Name', key: 'fullName'},
                {title: 'Email', key: 'email'},
                {title: 'Last login', key: 'lastLoggedInUtc'},
                {title: 'Role', key: 'role'},
                {title: 'Pharmacist', key: 'isRegisteredPharmacist'},
                {title: 'Active', key: 'isActive'},
                // {title: 'Last Used', key: 'lastModifiedOnUtc'},
            ],
            settingStatus: [],
            selectedIds: [],
            search: '',
            loadingUsers: false,
            deleteId: null,
            currentSort: 'lastLoggedInUtc',
            isDescending: true
        }
    },
    computed:{
        ...mapState({
            users: state => state.user.users,
            paginationInfo: state => state.user.usersPagination,
        }),
        ...mapGetters({
            canModifyUser: "auth/canModifyUser",
            canEditUser: "auth/canEditUser",
            permission: "auth/loggedPermission"
        }),
        usersDisplay(){
            if(!this.users) return [];
            return this.users.map((u) => {
                return {
                    ...u,
                    lastLoggedInUtc: u.lastLoggedInUtc ? format(u.lastLoggedInUtc, 'dd MMMM yyyy, HH:mm') : 'No Activity',
                    canModify: this.canModifyUser(u),
                    canEdit: this.canEditUser(u),
                }
            })
        },

    },
    methods:{
        ...mapActions({
            getUsers: 'user/actGetAllUsers',
            setStatus: 'user/actSetUserStatus',
            deleteUser: 'user/actDeleteUser'
        }),
        ...mapMutations({
            mutSetState: 'user/mutSetState'
        }),
        async changeUserStatus({id, isActive, canModify}){
            console.log({canModify})
            if(canModify){
                this.settingStatus.push(id);
                await this.setStatus({id, isActive: !isActive});
                const idx = this.settingStatus.findIndex(i => i === id);
                console.log('user status::', idx)
                this.settingStatus.splice(idx, 1);
            }
        },
        checkIfUserStatusChanging(id){
            return this.settingStatus.findIndex(i => i === id) >= 0;
        },
        async handleGetUsers(pageNumber){
            this.loadingUsers = true;
            await this.getUsers({
                searchKey: this.search,
                pageNumber,
                pageSize: 8,
                columnName: this.currentSort,
                isDescending: this.isDescending
            });
            this.loadingUsers = false;
        },
        handlePaginationEvent(page){
            this.handleGetUsers(page);
        },
        async handleUserDelete(){
            if(this.deleteId) {
                await this.deleteUser([this.deleteId]);
                this.deleteId = null;
            } else if(this.selectedIds.length) {
                await this.deleteUser(this.selectedIds);
                this.selectedIds = [];
            }
        },
        handleUserSelect(email){
            if(!email){
                this.$router.push(`/user/create`);
            } else {
                this.$router.push(`/user/edit/${email}`);
            }
        },
        handleSort(key){
            console.log('key:::', key);
            const finalKey = key === 'fullName' ? 'firstName' : key
            if(finalKey === this.currentSort){
                this.isDescending = !this.isDescending;
            } else {
                this.currentSort = finalKey;
            }
            this.handleGetUsers(1);
        },
        async downloadReport(){
            try {
                const {data} = await api.post('/User/generateUsersReport');
                downloadReport({
                    data: data.fileContents,
                    type: data.contentType,
                    fileName: data.fileDownloadName
                })
                console.log(data)
            } catch (e) {
                console.log(e, 'eeeeee')
                // errorToast(e)
            }
        }
    },
    mounted(){
        this.handleGetUsers(1);
    },
}

</script>

<template>
    <div>
        <Breadcrumbs main="User Settings" title="Manage Users" />
        <div class="container">
            <div class="row">
                    <div class="card position-relative overflow-hidden">
                        <div class="card-header d-flex justify-content-between flex-wrap align-items-center gap-2">
<!--                            <h4 class="card-title mb-0">Users List</h4>-->
                            <PolarSearch v-model="search" @search="handleGetUsers(1)" style="max-width: 400px;"/>
                            <div class="d-flex flex-wrap gap-2">
                                <button class="btn btn-danger btn-sm"
                                        v-if="selectedIds.length > 0"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteUserModal">
                                    <i class="fa fa-user-secret me-2"></i>
                                    Anonymize
                                </button>
                                <button class="btn btn-success" @click="downloadReport"> <i class="fa fa-download"></i> Download Account Report</button>
                                <button :disabled="permission < 3" class="btn btn-primary" @click="handleUserSelect(null)">
                                    <i class="fa fa-plus"/>
                                    New User
                                </button>
                            </div>
                        </div>
                        <div class="card-body" style="min-height: 565px;">
                            <div class="table-responsive add-project stickyLastCol">
                                <table class="table table-hover card-table table-vcenter text-nowrap">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th v-for="(ui, idx) in userUI" :key="ui.key" :class="['fw-bold', {'text-center': idx > 2}]">
                                                <div @click="handleSort(ui.key)" :class="['p-1 cursor-pointer max-w-min', {'mx-auto': idx > 2}]">
                                                    {{ ui.title }}
                                                    <i class="fa fa-sort"/>
                                                </div>
                                            </th>
                                            <th class="text-center fw-bold">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="user in usersDisplay" :key="user.id">
                                            <td>
                                                <input type="checkbox" v-model="selectedIds" class="checkbox_animated" :value="user.id">
                                            </td>
                                            <td v-for="(ui, idx) in userUI" :key="`${user.id}|${ui.key}`" :class="[{'text-center': idx > 2}]">
                                                <div v-if="ui.key === 'isRegisteredPharmacist'"
                                                     :class="['badge p-2 w-100 max-w-max', (user[ui.key] ? 'badge-success' : 'badge-warning')]">
                                                    <div class="f-w-700">
                                                        {{ user[ui.key] ? 'YES' : 'NO' }}
                                                    </div>
                                                </div>
                                                <div v-else-if="ui.key !== 'isActive'">
                                                    {{ user[ui.key] }}
                                                </div>
                                                <proggress-button v-else
                                                                  :disabled="!user.canModify || !user.isEmailConfirmed"
                                                                  :loading="checkIfUserStatusChanging(user.id)"
                                                                  :active="user.isActive"
                                                                  @click="changeUserStatus(user)"
                                                />
<!--                                                <div v-else-->
<!--                                                     :class="[-->
<!--                                                        'progress w-100 mx-auto text-center position-relative h-auto',-->

<!--                                                     ]"-->
<!--                                                     @click="changeUserStatus(user)"-->
<!--                                                     style="max-width: 120px;">-->
<!--                                                    <button-->
<!--                                                        :class="[-->
<!--                                                        'btn btn-sm progress-bar w-100',-->
<!--                                                            {'btn-success': user.isActive},-->
<!--                                                            {'btn-danger': !user.isActive},-->
<!--                                                            {'disabled cursor-default': !user.canModify || !user.isEmailConfirmed},-->
<!--                                                            {'progress-bar-animated progress-bar-striped disabled': checkIfUserStatusChanging(user.id)},-->
<!--                                                        ]"-->
<!--                                                         role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">-->
<!--                                                        {{user.isActive ? 'Active' : 'Inactive'}}-->
<!--                                                    </button>-->
<!--                                                </div>-->
                                            </td>
                                            <td>
                                                <div class="d-flex gap-2 h-100 justify-content-center">

                                                    <button @click="handleUserSelect(user.email)"
                                                            :disabled="!user.canEdit" class="btn btn-primary btn-sm">
                                                        <i class="fa fa-pencil"></i>
                                                    </button>
                                                    <button class="btn btn-danger btn-sm"
                                                            :disabled="!user.canModify"
                                                            @click="() => deleteId = user.id"
                                                            type="button"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#deleteUserModal">
                                                        <i class="fa fa-user-secret"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="card-footer pagination-padding">
                            <pagination :pagination-info="paginationInfo" @pageChanged="handlePaginationEvent"/>
                        </div>
                        <polar-loader v-if="loadingUsers">
                            Loading Users
                        </polar-loader>
                    </div>
            </div>
        </div>
        <div class="modal fade" id="deleteUserModal" role="dialog"
             aria-labelledby="deleteUserModal" aria-hidden="true">
            <ConfirmModal @confirmed="handleUserDelete">
                <template #message>
                    Are you sure you want to anonymize this user?
                </template>
            </ConfirmModal>
        </div>
    </div>
</template>
