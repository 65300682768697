<script>
export default {
    name: "PolarLoader",
    props: ['classes']
}
</script>

<template>
    <div class="blur w-100 h-100 absoluteCenter" style="z-index: 4"></div>
    <div :class="[(classes ? classes : ''), 'absoluteCenter w-100 h-100']" style="background-color: black;opacity: 30%; pointer-events: none; z-index: 7">
        <div class="d-flex align-items-center justify-content-center w-100 h-100">
            <div style="height: min-content;">
                <h5 class="mb-0 text-center text-white" style="font-weight: bold">
                    <slot>Registering your account</slot>
                </h5>
                <div class="loader-box">
                    <div :class="'loader-30'"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
