import {validateEmail} from "@/utils/helperFunctions";

export default{
    data() {
        return {
            formSubmitted: false,
            errors: {
                password: '',
                email: ''
            },
            passwordError: false,
            confirmPasswordError: false,
            emailError: false,
        }
    },
    computed:{
        loginFormIsValid(){
            return !this.passwordError && !this.emailError;
        }
    },
    methods: {
        validateLoginForm({password, email}) {
            console.log('validating login form password email', password, email)
            this.formSubmitted = true;
            if (password.length < 8) {
                this.passwordError = true;
                this.errors.password = 'Please note that the password must be a minimum of 12 characters (7 lower case, 1 upper case, 2 numbers and 2 symbols).';
            } else {
                this.passwordError = false;
                this.errors.password = '';
            }

            if(validateEmail(email)){
                console.log('valid email')
                this.emailError = false;
                this.errors.email = '';
            } else {
                console.log('invalid email')
                this.emailError = true;
                this.errors.email = 'Please enter a valid email';
                console.log(this.errors.email)
            }
        },
    }
}
