<script>
import {mapState} from "vuex";
import PolarLoader from "@/components/PolarLoader.vue";
import ForgetPassword from "@/components/auth/ForgetPassword.vue";
import {validatePassword} from "@/utils/helperFunctions";

export default {
    name: "lock_screen",
    components: {ForgetPassword, PolarLoader},
    data(){
        return{
            loading: false,
            active: true,
            showForgetPassword: false,
            passwordError: false,
            user: {
                email: "",
                password: ""
            }
        }
    },
    computed:{
        ...mapState({
            'loggedUser': state =>  state.auth.loggedUser
        }),
        userHello(){
            return `${this.user.title}. ${this.user.lastName}`;
        }
    },
    methods:{
        async handleSignIn(){
            const isValid =  validatePassword(this.user.password)
            if(isValid){
                this.loading = true;
                this.passwordError = false;
                await this.$store.dispatch('auth/actLogin', {email: this.user.email, password: this.user.password});
                if(this.loggedUser?.email){
                    this.$router.push('/')
                } else {
                    this.loading = false;
                }
            } else {
                this.passwordError = true;
            }

        },
        getSetStorageUser(){
            const localUser = localStorage.getItem('lockScreenUser');
            if(localUser){
                this.user = JSON.parse(localUser);
            } else {
                this.$router.push('/login');
            }
        },
        handleAnotherAccount(){
            localStorage.clear();
            this.$router.push('/auth/login');
        }
    },
    created() {
        this.getSetStorageUser();
    }
}
</script>

<template>
    <div class="container-fluid">
        <div class="row">
            <div class="p-0">
                <div class="login-card">
                    <div>
                        <div>
                            <div class="logo text-center">
                                <img class="img-fluid for-light" src="../../assets/images/logo/polar-logo.png" alt="polars-speed" />
                            </div>
                        </div>
                        <polar-loader v-if="loading">
                            Logging in
                        </polar-loader>
                        <div :class="['login-main position-relative overflow-hidden']">
                            <forget-password :prop-email="user.email" v-if="showForgetPassword" @back="() => showForgetPassword = false">
                                Back to lock screen
                            </forget-password>
                            <form v-else class="theme-form" novalidate @submit.prevent="handleSignIn">
                                <div class="rounded-circle bg-primary d-flex position-relative mx-auto" style="width: 100px; height: 100px;">
                                    <i class="fa fa-user-md absoluteCenter" style="font-size: 56px;"/>
                                </div>
                                <div class="text-center my-4">
                                    <div class="fs-5">
                                        Hi! {{userHello}}
                                    </div>
                                    <p class="mt-2">
                                        Enter your password to access <br> the platform.
                                    </p>
                                </div>
                                <div class="form-group">
                                    <label for="password" class="col-form-label">Password*</label>
                                    <div class="form-input position-relative">
                                        <input v-model="user.password"
                                               :class="[
                                            'form-control',
                                       ]"
                                               :type="active ? 'password' : 'text'"
                                               name="password"
                                               placeholder="*********" />
                                        <div class="show-hide"
                                             style="padding-right: 10px;">
                                            <span :class="active?'show':'hide'" @click="active = !active"></span>
                                        </div>
                                    </div>
                                    <div class="invalid-feedback d-block" v-if="passwordError">
                                        Please note that the password must be a minimum of 12 characters (7 lower case, 1 upper case, 2 numbers and 2 symbols).
                                    </div>
                                </div>
<!--                                <div class="form-group">-->
<!--                                    <label class="col-form-label">Please confirm*</label>-->
<!--                                    <div class="g-recaptcha" data-sitekey="6Ld0zGUpAAAAAMH9pLCYHazentnuWtkROsXT01N2"></div>-->
<!--&lt;!&ndash;                                    <div class="invalid-feedback d-block">{{captchaError}}</div>&ndash;&gt;-->
<!--                                </div>-->
                                <p class="mb-2 mt-0 text-end">
                                    <a class="cursor-pointer" @click="() => showForgetPassword = true">
                                        Forgot password?
                                    </a>
                                </p>
                                <div class="form-group mb-0">
                                    <!--                                <div class="checkbox p-0">-->
                                    <!--                                    <input id="checkbox1" type="checkbox" />-->
                                    <!--                                    <label class="text-muted" for="checkbox1">Remember password</label>-->
                                    <!--                                </div>-->
                                    <!--                                <router-link to="/">-->
                                    <button class="btn btn-primary btn-block w-100"
                                            type="submit">
                                        Sign in
                                    </button>
                                    <!--                                </router-link>-->
                                </div>
                                <!--                            <h6 class="text-muted mt-4 or">Or Sign in with</h6>-->
                                <!--                            <div class="social mt-4">-->
                                <!--                                <div class="btn-showcase"><a class="btn btn-light" href="#" ><vue-feather class="txt-linkedin" type="linkedin"></vue-feather> LinkedIn </a><a class="btn btn-light" href="#" ><vue-feather class="txt-twitter" type="twitter"></vue-feather>twitter</a><a class="btn btn-light" href="#" ><vue-feather class="txt-fb" type="facebook"></vue-feather>facebook</a></div>-->
                                <!--                              </div>-->
                                <p class="mt-4 mb-0 text-center">
                                    <a @click.prevent="handleAnotherAccount" href="">
                                        Login with another account
                                    </a>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>

</style>
