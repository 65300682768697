<script>
import SettingsInput from "@/components/settings/SettingsInput.vue";
import {mapGetters, mapMutations, mapState} from "vuex";
import api from "@/utils/api";
// import {errorToast} from "@/utils/helperFunctions";
import LocatorBadge from "@/components/masterdata/LocatorBadge.vue";
import AddContact from "@/components/masterdata/AddContact.vue";
import { useVuelidate } from '@vuelidate/core'
import {useToast} from "vue-toastification";

import "vue-multiselect/dist/vue-multiselect.css"


export default {
    name: "EditMasterDataModal",
    setup () {
        return { v$: useVuelidate() }
    },
    data(){
        return {
            assignedUsers: null,
            usersMasterData: [],
            editingContact: false,
            currContact: null,
            newContactId: 0
        }
    },
    components: {AddContact, LocatorBadge, SettingsInput},
    computed:{
        ...mapState({
            editAddressModal: state => state.masterdata.editAddressModal,
            customerMasterData: state => state.masterdata.customerMasterData,
            loggedUser: state => state.auth.loggedUser
        }),
        ...mapGetters({
            loggedPermission: "auth/loggedPermission"
        }),
        cloneData(){
            return this.editAddressModal.data
        },
        contactTypes(){
            const phone = [], email = [];
            if(this.cloneData?.contacts) {
                for (const c of this.cloneData.contacts) {
                    if (c.communicationType === 1) {
                        phone.push(c)
                    } else {
                        email.push(c)
                    }
                }
            }
            return {phone, email}
        },
        inputs(){
            return this.editAddressModal.inputs
        },
        usersModel:{
            get(){
                return this.cloneData?.users || []
            },
            set(value){
                this.mutEditAddress({
                    key: 'users',
                    value
                })
            }
        },
    },
    methods:{
        ...mapMutations({
            mutEditAddress: "masterdata/mutEditAddress",
            mutAddNewContact: "masterdata/mutAddNewContact",
        }),
        async handleCreateUpdateContact(){
            const result = await this.v$.$validate();
            console.log(this.v$)
            console.log(result)
            if(result){
                const {id,d365ElectronicAddressID, ...rest} = this.currContact;
                console.log('payload:::', rest, id);
                try {
                    const currContacts = this.cloneData.contacts || []
                    if(id >= 0){
                        const {data} = await api.put(`/Contact/${id}`, rest);
                        const filtered = currContacts.filter(c => c.id !== id);
                        filtered.push(this.currContact)
                        this.mutEditAddress({
                            key: 'contacts',
                            value: filtered
                        })
                        this.mutAddNewContact(this.currContact);
                        useToast().success('Contact updated with success');
                    } else {
                        let newId = id;
                        if(this.cloneData.id){
                            const {data} = await api.post('/Contact', rest);
                            newId = data;
                            useToast().success('Contact created with success');
                            this.mutAddNewContact({id: newId, ...rest});
                        }
                        this.mutEditAddress({
                            key: 'contacts',
                            value: [{id: newId, ...rest},...currContacts]
                        })
                    }
                    this.editingContact = false;
                    this.currContact = null;
                } catch (e) {
                    console.log('errror:::', e)
                    // errorToast(e)
                }
            }
        },
        handleChange(data){
            this.mutEditAddress(data);
            this.monitorRelations(data.key);
        },
        monitorRelations(key){
            let nextKey = ''
            for(const data of this.inputs){
                if(nextKey === data.relation){
                    this.mutEditAddress({key: data.key, value: null});
                } else if(data.relation === key){
                    console.log('setting data to default', key)
                    this.mutEditAddress({key: data.key, value: null});
                    nextKey = data.key;
                }
            }
        },
        handleSave(){
            console.log()
            if(this.editingContact){
                this.handleCreateUpdateContact();
            } else {
                this.$emit('save', this.cloneData)
            }
        },
        closeModal(){
            if(this.editingContact){
                this.editingContact = false;
            } else {
                document.getElementById('closeEditData').click();
                this.$emit('modal-close')
            }
        },
        async getMasterDataUsers(){
            if(this.loggedPermission > 2){
                try {
                    const {data} = await api.get(`/customerMasterData/getUsers/${this.customerMasterData.id}`);
                    this.usersMasterData = data;
                } catch (e) {
                    // errorToast(e);
                }
            } else {
                this.usersMasterData = [{
                    id: this.loggedUser.id,
                    email: this.loggedUser.email,
                    fullName: `${this.loggedUser.firstName} ${this.loggedUser.lastName}`
                }];
            }
        },
        async handleContactDelete(id){
            try {
                await api.delete(`/Contact/${id}`);
                this.mutEditAddress({
                    key: 'contacts',
                    value: this.cloneData.contacts.filter(c => c.id !== id)
                })
            } catch (e) {
                // errorToast(e);
            }

        },
        getDefaultContact(type){
            this.newContactId = this.newContactId - 1;
            const base = {
                "id": this.newContactId,
                "description": '',
                "isMobilePhone": !!type,
                "isPrimary": false,
                "isPrivate": false,
                "locator": '',
                "locatorExtension": '',
                "communicationType": type,
            }

            if(this.cloneData.id){
                return {
                    ...base,
                    "transactionType": this.cloneData.transactionType,
                    "d365ElectronicAddressID": null,
                    "addressId": this.cloneData.id
                }
            }
            return base;

        },
        handleAddContact(type){
            this.currContact = this.getDefaultContact(type)
            this.editingContact = true;
        },
        handleContactEdit(selContact){
            this.currContact = JSON.parse(JSON.stringify(selContact));
            this.editingContact = true
        },
        customLabel({fullName, firstName, lastName}){
            return fullName || `${firstName} ${lastName}`
        }
    },
    created() {
        this.getMasterDataUsers()
    }
}
</script>

<template>
    <div>
        <div class="modal-header">
            <h4 class="modal-title">
               Edit {{editAddressModal.title}}
            </h4>
            <button id="closeEditData" class="hidden" type="button" aria-label="Close" data-bs-dismiss="modal"></button>
            <button @click="closeModal" class="btn-close pr-3 max-w-max" type="button"></button>
        </div>
        <div class="modal-body">
            <transition name="fadeHeight">
                <div v-if="!editingContact">
                    <div v-if="cloneData" class="row">
                        <div class="col-12"><h5>Delivery Address</h5></div>
                        <div v-for="input in inputs"
                             :class="[(input.col ? input.col : 'col-sm-6 col-md-4'), 'mb-2 d-flex flex-column justify-content-end']"
                             :key="input.key">
                            <settings-input
                                :disabled="loggedPermission < input.reqPermission && !!input.reqPermission"
                                :settingsData="cloneData"
                                :model-value="cloneData[input.key]"
                                @update:model-value="(val) => handleChange({key: input.key, value: val})"
                                :input="input"
                            />
                        </div>
                    </div>
                    <div class="row my-5">
                        <div class="col-12"><h5>Add Contacts</h5></div>
                        <div class="col-12">
                            <label class="col-form-label">Create contacts by clicking the buttons below. Multiple contacts supported.</label>
                            <div class="d-flex gap-3 mt-2">
                                <button class="btn btn-outline-primary" @click="handleAddContact(1)">Add Contact Phone</button>
                                <button class="btn btn-outline-primary" @click="handleAddContact(2)">Add Contact Email</button>
                            </div>
                            <div class="mt-3">
                                <h6 v-if="contactTypes?.phone.length">Phone</h6>
                                <div class="d-flex flex-wrap gap-3 mb-2">
                                    <LocatorBadge v-for="c in contactTypes.phone" :key="c.id" @delete="handleContactDelete(c.id)" @selected="handleContactEdit(c)">
                                        {{c.locatorExtension}} {{c.locator}}
                                    </LocatorBadge>
                                </div>
                                <h6  v-if="contactTypes?.email.length">Email</h6>
                                <div class="d-flex flex-wrap gap-3">
                                    <LocatorBadge v-for="c in contactTypes.email" :key="c.id" @delete="handleContactDelete(c.id)" @selected="handleContactEdit(c)">
                                        {{c.locator}}
                                    </LocatorBadge>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12"><h5>User Details</h5></div>
                        <div class="col-12">
                            <label class="col-form-label" for="selectUsers">Select users to assign to this {{editAddressModal.title}}</label>
                            <multiselect
                                id="selectUsers"
                                v-model="usersModel"
                                :options="usersMasterData"
                                :multiple="true"
                                :custom-label="customLabel"
                                track-by="id"
                            />
                        </div>
                    </div>
                </div>
                <div v-else>
                    <AddContact v-model="currContact" />
                </div>
            </transition>
        </div>
        <div class="modal-footer">
            <button @click="closeModal" class="btn btn-light max-w-max" type="button">Cancel</button>
            <button class="btn btn-primary max-w-max" @click="handleSave">
                Save
            </button>
        </div>
        </div>
</template>

<style scoped>

</style>
