<script>
import SettingsInput from "@/components/settings/SettingsInput.vue";

export default {
    name: "FinishOrder",
    components: {SettingsInput},
    props: ['order'],
    data(){
        return {
            agreeCheck: false,
            inputs: [
                {label: 'Comments', type: 'textarea', key: 'comments', notRequired: true},
                {label: 'Customer Ref No', type: 'string', key: 'customerRefNo', notRequired: true},
            ]
        }
    },
    computed:{
        orderModel:{
            get(){
                return this.order
            },
            set(payload){
                this.$emit('updateOrder', payload)
            }
        }
    },
    watch:{
        agreeCheck(newVal){
            this.$emit('validate', newVal)
        }
    }

}
</script>

<template>
    <div class="flex-grow-1">
        <div class="row">
            <settings-input v-for="input in inputs"
                            class="col-6"
                            :key="input.key"
                            :input="input"
                            :model-value="orderModel[input.key]"
                            @update:model-value="(val) => orderModel = {val, key: input.key}"
            />

            <label class="d-block cursor-pointer mt-auto max-w-max">
                <input v-model="agreeCheck"
                       :class="['checkbox_animated']"
                       type="checkbox">
                Agree with
                <a href="/terms-and-conditions"
                              rel="noopener noreferrer"
                              target="_blank"
                              class="ms-2">
                        Terms and Conditions
                </a>
            </label>
        </div>
    </div>
</template>

<style scoped>

</style>
