<script>
import api from "@/utils/api";
import {colTitle, dynamicRelations, hardCodeApi} from "@/constants/tableSettings";
import Datepicker from "@vuepic/vue-datepicker";
import {format} from "date-fns";
import {useVuelidate} from '@vuelidate/core'
import {numeric, required} from '@vuelidate/validators';
import {mapMutations, mapState} from "vuex";
import DropdownSearch from "@/components/uiKits/DropdownSearch.vue";
import ChildItemCounter from "@/components/product/ChildItemCounter.vue";
// import Multiselect from "@/main";

export default {
    name: "SettingsInput",
    components:{ChildItemCounter, DropdownSearch, Datepicker},
    props: ['input', 'modelValue', 'settingsData', 'error', 'parentSelect', 'disabled'],
    setup () {
        return { v$: useVuelidate() }
    },
    data(){
        return {
            selectData: [],
            selectError: '',
            dynamicChildRelation: null
        }
    },
    validations(){
        if(this.inputIsDisabled){
            return {};
        }
        let finalValidationObject = {
            required: this.isNotRequired ? true : required,
            ...(this.input.type === 'number' ? {numeric} : {}),
            $autoDirty: true,
        }
        if(this.input.validation?.custom){
            finalValidationObject.custom = this.input.validation.custom;
            // return {
            //     modelValue: {custom: this.input.validation.custom}
            // }
        } else {

        }
        if(this.input.validation){
            finalValidationObject = {...finalValidationObject, ...this.input.validation}
            // return {
            //     modelValue: {required: this.input.notRequired ? true : required,...this.input.validation, $autoDirty: true, ...(this.input.type === 'number' ? {numeric} : {})}
            // }
        }
        return {modelValue: finalValidationObject};
        // if(!this.input.notRequired){
        //     return {
        //         modelValue: {required, $autoDirty: true, ...(this.input.type === 'number' ? {numeric} : {})}
        //     }
        // } else {
        //     return {
        //         modelValue: {...(this.input.type === 'number' ? {numeric} : {}),  $autoDirty: true}
        //     }
        // }
    },
    computed:{
        ...mapState({
            settingsApiData: state => state.settings.settingsApiData
        }),
        isNotRequired(){
            return this.input.notRequired || !!this.input.validation?.custom
        },
        inputIsDisabled(){
            if(!this.input.api && this.input.relation){
                console.log(this.settingsData[this.input.relation], 'this.settingsData[this.input.relation]')
                if(!this.settingsData[this.input.relation]){
                    return  true;
                }
            }
            return this.disabled || this.inputData.disabled || this.inputData.type === 'null';
        },
        inputData(){
            if(this.inputHasDynamicRelationApi){
                return this.inputHasDynamicRelationApi
            } else {
                return {...this.input, label: this.input.label ? `${this.input.label} ${this.isNotRequired || this.input.type === 'boolean' ? '' : '*'}` : ''}
            }
        },
        currSelectData(){
            return this.settingsApiData[this.inputData.api] ? this.settingsApiData[this.inputData.api] : (this.parentSelect || this.selectData);
        },
        selectOptions(){
            if(this.inputData.api && this.inputData.relation){
                // const parRel = this.settingsData[this.inputData.mainParentRelation];
                let filterData;
                if(typeof this.inputData.relation === 'object'){
                    let i, len = this.inputData.relation.length;
                    for (i=0; i<len; i++) {
                        const rel = this.inputData.relation[i];
                        const relData = this.settingsData[rel]
                        if(this.settingsData[rel]){
                            filterData = this.currSelectData.filter(d => d[rel] === relData)
                            this.selectError = filterData.length > 0 ? '' : `There are no options for the selected ${colTitle(rel)}`;
                            break;
                        } else if(i === len-1){
                            this.selectError = `Please select a ${colTitle( this.inputData.relation[len - 1])} or ${colTitle( this.inputData.relation[0])} before`;
                        }
                    }
                } else {
                    const selRel = this.settingsData[this.inputData.relation];
                    if(selRel) {
                        filterData = this.currSelectData.filter(d => d[this.inputData.relation] === selRel)
                        this.selectError = filterData.length > 0 ? '' : `There are no options for the selected ${colTitle( this.inputData.relation)}`;
                    } else {
                        this.selectError = `Please select a ${colTitle( this.inputData.relation)} before`;
                    }
                }

                return filterData;
            }
            console.log('currSelectData :::', this.currSelectData)
            return this.currSelectData;
        },
        selectLabel(){
            return this.selectError ||  `Select a ${this.inputData.label || colTitle(this.input.api)}`;
        },
        inputHasHardCodeApi(){
            if(this.input.api){
                return hardCodeApi[this.input.api];
            }
            return null;
        },
        inputHasDynamicRelationApi(){
            if(this.input.api){
                const dRelation = dynamicRelations[this.input.api];
                if(dRelation) {
                    const {parentKey, options} = dRelation
                    const option = options.find(o => o.id === this.settingsData[parentKey]) || options[0];
                    return {
                        ...option,
                        label: this.input.label,
                        key: this.input.api
                    };
                }
            }
            return null;
        },
        errorMessage(){
            if(this.inputIsDisabled){
                return '';
            }
            return this.v$.modelValue?.$errors[0]?.$message;
        },
        multiselectValues(){
            console.log('multiselectValues ::::', this.selectOptions, '::::',this.modelValue)
            if(!this.modelValue){
                return [];
            }
            return this.selectOptions.filter(opt => {
                if(this.inputData.trackBy){
                    return this.modelValue.findIndex(v => v[this.inputData.trackBy] === opt[this.inputData.trackBy]) >= 0
                }
                return this.modelValue.findIndex(v => (v?.id || v) === opt.id) >= 0
            })
        },
        searchSelectValue(){
            if(!this.modelValue){
                return null;
            }
            return this.selectOptions.find(opt => opt[this.inputData.trackKey] === this.modelValue)
        }
    },
    methods:{
        ...mapMutations({
            mutSettingsApiData: 'settings/mutSettingsApiData'
        }),
        setDynamicRelation(){
            for(const dR in dynamicRelations){
                if(dynamicRelations[dR].parentKey === this.input.key){
                    console.log('dynamic chidl', dynamicRelations[dR])
                    this.dynamicChildRelation = {
                        val: null,
                        key: dynamicRelations[dR].key
                    }
                }
            }
        },
        handleInput(e){
            if(this.input.immediate){
                this.handleChange(e)
            }
        },
        handleChange(e){
            let newValue = e?.target ? e.target.value : e;

            if(this.inputData.type === 'multiselect'){
                console.log(newValue, 'this.inputData')
                if(this.inputData.valueTransform){
                    newValue = this.inputData.valueTransform(newValue);
                } else {
                    newValue = newValue.map(v => v.id);
                }
                this.$emit('update:model-value', newValue);
                console.log(newValue, this.inputData.key, 'newValuenewValuenewValue')
                return;
            } else if(this.inputData.type === 'searchSelect'){
                console.log(newValue[this.inputData.trackKey] || newValue, 'newValue[this.inputData.trackKey] || newValue')
                this.$emit('update:model-value', newValue[this.inputData.trackKey] || newValue);
                console.log(newValue, this.inputData.key, 'newValuenewValuenewValue')
                return;
            }

            if(this.inputData.type === 'date'){
                newValue = newValue ? format(newValue, 'yyyy-MM-dd') : '';
            } else if(this.inputData.type === 'boolean'){
                console.log(newValue, 'n ewwwwwwwwwwww')
                newValue = !JSON.parse(newValue);
            } else if(this.inputData.type === 'number' || this.inputData.valueType === 'number'){
                try {
                    newValue = newValue ? JSON.parse(newValue) : newValue;
                } catch (e) {

                }
            }

            console.log(newValue, this.inputData.key, 'newValuenewValuenewValue')

            this.$emit('update:model-value', newValue);

            if(this.dynamicChildRelation){
                this.$emit('updateChildRelation', this.dynamicChildRelation)
            }

        },
        async getSelectOptions(){
            if(this.inputData.api){
                if(this.inputHasHardCodeApi){
                    this.selectData = this.inputHasHardCodeApi;
                } else {
                    if(!this.settingsApiData[this.inputData.api]){
                        console.log('getting', this.inputData.api)
                        const {data} = await api.get(`/${this.inputData.api}`, {
                            params: {
                                pageNumber: 1,
                                pageSize: 10000,
                                ...(this.inputData?.params || {})
                            }
                        })
                        this.selectData = data.items || data;
                        this.mutSettingsApiData({api: this.inputData.api, data: data.items || data})
                    }

                    if(this.parentSelect){
                        this.$emit('update:model-value', null);
                    }
                }
                this.$emit('fetchedSelectData', {data: this.currSelectData, input: this.inputData.key})
            }
        },
    },
    created() {
        this.setDynamicRelation();
    },
    mounted() {
        if(!this.parentSelect){
            this.getSelectOptions();
        }
    },
    watch:{
        inputData(){
            if(!this.parentSelect){
                this.getSelectOptions();
            }

            if(!this.inputData.label){

            }
        },
        modelValue(){
            console.log('watttchhh')
        }
    }
}
</script>

<template>
    <div class="d-flex flex-column">
        <div class="stnInput d-flex mt-auto">
            <div class="form-group" v-if="inputData.type === 'searchSelect'">
                 <label v-if="inputData.label" class="col-form-label" :for="inputData.key">{{inputData.label}}</label>
                <DropdownSearch :options="selectOptions"
                                :id="inputData.key"
                                :error="errorMessage"
                                :disabled="inputIsDisabled"
                                :displayOption="inputData.displayOption"
                                :display-key="inputData.displayKey"
                                :filter-keys="inputData.filterKeys"
                                @select="handleChange"
                                :model-value="searchSelectValue"
                />
            </div>
            <div v-else-if="inputData.type === 'multiselect'"
                 :id="`multiselect-${inputData.key}`"
                 class="form-group">
                 <label v-if="inputData.label" class="col-form-label" :for="inputData.key">{{inputData.label}}</label>
                <multiselect
                    open-direction="bottom"
                    :id="inputData.key"
                    @update:model-value="handleChange"
                    :model-value="multiselectValues"
                    :options="selectOptions"
                    :multiple="true"
                    :label="inputData.optionLabel"
                    :track-by="inputData.trackBy || 'id'">
                    <template v-if="inputData.key === 'kitItems'" #tag="{option}">
                        <div class="col-6 p-1">
                            <div class="d-flex gap-1 p-1 rounded-2 border-light align-items-center" style="border: 1px solid;">
                                <div class="rounded-2 overflow-hidden" style="width: 85px; height: 85px;">
                                    <img :src="option.urlImage" class="w-100 h-100" style="object-position: center; object-fit: cover" alt="product image">
                                </div>
                                <div class="d-flex flex-grow-1 flex-column gap-2">
                                    <b>
                                        {{option.itemId}}
                                    </b>
                                    <div class="d-flex gap-3 align-items-center justify-content-between">
                                        Child items
                                        <ChildItemCounter v-model="option.numberOfChildItems" @updated="handleChange(multiselectValues)"/>
                                    </div>
                                    <div class="d-flex gap-3 align-items-center justify-content-between">
                                        Master items
                                        <ChildItemCounter v-model="option.numberOfMasterItems" @updated="handleChange(multiselectValues)"/>
                                    </div>
                                </div>
                            </div>

                        </div>
<!--                        {{option}}-->
                    </template>
                    <template #option="{option}">{{option[inputData.optionLabel] || option.itemId}}</template>
                </multiselect>
            </div>

            <div v-else-if="inputData.api" class="form-group">
                 <label v-if="inputData.label" class="col-form-label" :for="inputData.key">{{inputData.label}}</label>
                <select :id="inputData.key"
                        @change="handleChange"
                        :name="inputData.label"
                        :disabled="inputIsDisabled"
                        :class="['form-select', {'is-invalid': errorMessage}]"
                        required="">
                    <option :selected="!modelValue" disabled="" value="">{{selectLabel}}</option>
                    <option v-for="opt in selectOptions"
                            :selected="(opt[inputData.selectKey] || opt.id) === modelValue"
                            :value="opt[inputData.selectKey] || opt.id"
                            :key="opt[inputData.selectKey] || opt.id">
                        {{  opt[inputData?.displayKey] || opt?.name || opt?.id || opt }}
                    </option>
                </select>
            </div>


             <label v-else-if="inputData.type === 'boolean'"
                   :for="inputData.key"
                   class="d-block cursor-pointer mt-auto max-w-max">
                <input :value="modelValue"
                       :disabled="inputIsDisabled"
                       :checked="modelValue"
                       @change="handleChange"
                       :class="['checkbox_animated', {'is-invalid': errorMessage}]"
                       :id="inputData.key"
                       type="checkbox">
                 <span v-if="inputData.label">{{inputData.label}}</span>
            </label>

            <div v-else-if="inputData.type === 'string' || inputData.type === 'number'" class="form-group">
                 <label v-if="inputData.label" class="col-form-label">{{inputData.label}}</label>
                <input :value="modelValue"
                       :name="inputData.label"
                       :disabled="inputIsDisabled"
                       @change="handleChange"
                       @input="handleInput"
                       :class="['form-control', {'is-invalid': errorMessage}]"
                       :type="'text'"
                       :placeholder="inputData.label || inputData.placeholder"
                />
            </div>
            <div v-else-if="inputData.type === 'date'" class="form-group">
                 <label v-if="inputData.label" class="col-form-label">{{inputData.label}}</label>
                <Datepicker
                    :disabled="inputIsDisabled"
                    :placeholder="inputData.placeholder || ''"
                    :format="input.format || 'yyyy-MM-dd'"
                    :enable-time-picker="false"
                    :auto-apply="true"
                    :model-value="modelValue"
                    @update:model-value="handleChange"
                />
                <!--                `${formSubmitted ? emailError ? 'is-invalid' : 'is-valid' : ''}`-->
                <!--                <div class="invalid-feedback">{{ errors.email }}</div>-->
            </div>

            <div v-else-if="inputData.type === 'textarea'" class="form-group">
                 <label v-if="inputData.label" class="col-form-label">{{inputData.label}}</label>
                <textarea
                    :disabled="inputIsDisabled"
                    :name="inputData.api"
                    :value="modelValue"
                    @change="handleChange"
                    :class="['form-control', {'is-invalid': errorMessage}]"
                    v-bind="inputData.textareaOpts"
                    :placeholder="inputData.label"
                />
                <!--                `${formSubmitted ? emailError ? 'is-invalid' : 'is-valid' : ''}`-->
                <!--                <div class="invalid-feedback">{{ errors.email }}</div>-->
            </div>
            <div v-else-if="inputData.type === 'null'" class="form-group">
                 <label v-if="inputData.label" class="col-form-label">{{inputData.label}}</label>
                <input disabled
                       :value="modelValue"
                       :class="['form-control disabled cursor-default']"
                       type="text"
                       required=""
                       :placeholder="inputData.label"
                />
            </div>
        </div>
        <div :class="['d-block', (errorMessage ? 'invalid-feedback' : 'vis')]">{{ errorMessage || 'E' }}</div>
    </div>
</template>

<style scoped>
select {
    color: #212529 !important;
}

.stnInput{
    min-height: 50px;
}

.stnInput .form-group {
    width: 100%;
}

.vis{
    visibility: hidden;
}

</style>

<style>
#multiselect-kitItems{
    .multiselect__tags-wrap{
        display: flex !important;
        flex-wrap: wrap;
        align-items: center;
    }

    .childItem{
        width: 50%;
    }

    #kitItems{
        padding: 12px 10px !important;
        width: 100% !important;
        position: relative !important;
    }

    .multiselect__placeholder{
        display: none !important;
    }
}

</style>
