<template>
  <router-link to="/">
    <img
      class="img-fluid for-light"
      style="max-width: 80%"
      src="../../assets/images/logo/polar-logo.png"
      alt=""
    />
<!--    <img-->
<!--      class="img-fluid for-dark"-->
<!--      style="max-width: 80%"-->
<!--      src="../../assets/images/logo/polar-logo.png"-->
<!--      alt=""-->
<!--    />-->
  </router-link>
</template>
<script>

  export default {
    name: 'Logo',
  };
</script>
