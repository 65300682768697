<script>
import {cUSettings, colTitle, transformDisplayData} from "@/constants/tableSettings";
import api from "@/utils/api";
import {useToast} from "vue-toastification";
import SettingsInput from "@/components/settings/SettingsInput.vue";
import {useVuelidate} from "@vuelidate/core";

export default {
    name: "CreateEditSettings",
    components: {SettingsInput},
    props: ['settingType', 'editSetting', 'useAsComponent'],
    setup(){
        return {
            v$: useVuelidate()
        }
    },
    data(){
        return {
            settingsData: null,
            formType: [],
            oldData: null,
            allSelectOptions: {}
        }
    },
    computed:{
        currCUS(){
            return cUSettings[this.settingType.api]
        }
    },
    methods: {
        removeAnyDisplayData(){
                const payloadObj = {}
                for(const key in this.currCUS){
                    payloadObj[key] = this.settingsData[key];
                }
                return payloadObj;
        },
        setDefaultValues(){
            for(const input of this.formType) {
                if (input.default !== '' && input.default !== undefined && !this.settingsData[input.key]) {
                    this.settingsData[input.key] = input.default;
                }
            }
        },
        async handleCreate(){
            const result = await this.v$.$validate();
            if(!result) return;
            this.setDefaultValues()
            const payload = this.removeAnyDisplayData();
            try {
                const id = this.editSetting?.id;
                if(id){
                    if (id === 'CustomerMasterData'){
                        // delete payload.vatEndpoint
                        payload.vatEndpoint = ""
                        console.log(payload,'his.settingType.api')

                    }
                    await api.put(`/${this.settingType.api}/${id}`, payload, {
                        customError: true
                    });
                } else {
                    await api.post(`/${this.settingType.api}`, payload, {
                        customError: true
                    });
                }
                this.closeModal();
                this.$emit('settingCreated')
                useToast().success(`${this.settingType.title} ${this.editSetting ? 'changed' : 'created'}`);
            } catch (e) {
                const {status, errors} = e.response.data;
                if(status === 409){
                    useToast().error(errors[0].description);
                } else {
                    useToast().error(e.response.data?.title || 'Something went wrong');
                }
            }
        },
        defineSettingsAndData(setDefault){
            const cUS = this.currCUS;
            const form = [];
            const data = {};
            const id = this.editSetting?.id;
            for(const key in cUS){
                const formInput = {
                    ...(id && cUS[key]?.editInput ? cUS[key].editInput : cUS[key]),
                    label: colTitle(key, this.settingType.title),
                    key
                }
                if(key === 'vatEndpoint' && id === 'CustomerMasterData')
                    formInput.type = 'null';

                console.log(formInput,'formInputformInput')


                form.push(formInput);

                if(!this.editSetting || setDefault){
                    data[key] = cUS[key].default;
                }
            }
            // if(this.)
            this.settingsData = this.editSetting && !setDefault ? {...this.editSetting} : data;
            this.formType = form;
        },
        closeModal(){
            this.defineSettingsAndData(true);
            document.getElementById('dismissCreateSettings').click();
        },
        handleDataChange({val, key}){
            // const { key } = input;
            // console.log(input, 'inputiiii')
            console.log({val, key}, this.currCUS[key]);
            this.settingsData[key] = val;
            if(typeof this.currCUS[key].relation === 'object'){
                const fullSelValue = this.allSelectOptions[key]?.find(opt => opt.id === this.settingsData[key])
                for(const parRel of this.currCUS[key].relation){
                    this.settingsData[parRel] = fullSelValue[parRel];
                }
            }
            this.monitorRelations(key);
        },
        monitorRelations(key){
            let nextKey = '';
            for(const sKey in this.currCUS){
                const relation = this.currCUS[sKey]?.relation;
                if(typeof relation === 'object'){
                    const fullSelValue = this.allSelectOptions[sKey]?.find(opt => opt.id === this.settingsData[sKey])
                    if(fullSelValue){
                        let hasRelValue = false;
                        for(const parRel of relation){
                            console.log('parRel', parRel, this.settingsData[parRel], fullSelValue[parRel])
                            if(this.settingsData[parRel] === fullSelValue[parRel] && !hasRelValue){
                                hasRelValue = true;
                            } else if(this.settingsData[parRel] && this.settingsData[parRel] !== fullSelValue[parRel]){
                                hasRelValue = true;
                                this.settingsData[sKey] = null;
                            }
                        }
                        if(!hasRelValue){
                            this.settingsData[sKey] = null;
                        }
                    }
                } else {
                    if(this.settingsData[sKey]){
                        if(nextKey === relation){
                            this.settingsData[sKey] = null;
                            console.log('setting next data to default', sKey)
                        } else if(relation === key){
                            console.log('setting data to default', sKey)
                            this.settingsData[sKey] = null;
                            nextKey = sKey;
                        }
                    }
                }
            }
        },
        handleSelectOptions({data, input}){
            this.allSelectOptions[input] = data;
        }
    },
    beforeUnmount() {
        this.defineSettingsAndData(true);
    },
    created() {
        this.defineSettingsAndData();
    }
}
</script>

<template>
        <div>
            <div class="modal-body">
                <div class="theme-form row">
                  <SettingsInput v-for="(input, idx) in formType"
                                 :key="input.key"
                                 :disabled="editSetting?.id && input.disableEdit"
                                 :class="[`${input.col || 'col-md-6'}`]"
                                 :settingsData="settingsData"
                                 :model-value="settingsData[input.key]"
                                 @fetchedSelectData="handleSelectOptions"
                                 @updateChildRelation="handleDataChange"
                                 @update:model-value="(val) => handleDataChange({val, key: input.key})"
                                 :input="input"
                  />
                </div>
            </div>
            <div class="modal-footer">
                <button id="dismissCreateSettings" class="btn btn-light" type="button" data-bs-dismiss="modal">Cancel</button>
                <button class="btn btn-primary" type="button" @click="handleCreate">{{ editSetting ? 'Edit' : 'Create' }}</button>
            </div>
        </div>
</template>

<style scoped>

</style>
