<script>
export default {
    name: "PolarSearch",
    props: ['modelValue', 'disabled', 'placeholder'],
    data(){
        return {
            typingTimeout: null,
        }
    },
    methods:{
        handleSearch(){
            if(this.typingTimeout) clearTimeout(this.typingTimeout);
            this.$emit('search');
        },
        handleUpdate(e){
            this.$emit('update:model-value', e.target.value);
        }
    },
    watch:{
        modelValue(){
            if(this.typingTimeout)
                clearTimeout(this.typingTimeout);

            this.typingTimeout = setTimeout(() => {
                this.handleSearch()
            }, 1000)
        }
    }
}
</script>

<template>
    <div class="search w-100">
        <div class="theme-form">
            <div class="form-group my-3">
                <div class="input-group">
                    <input :value="modelValue"
                           :disabled="disabled"
                           @input="handleUpdate"
                           @keyup.enter="handleSearch"
                           class="form-control"
                           type="text"
                           :placeholder="placeholder || 'Search'" />
                    <i class="fa fa-search"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
