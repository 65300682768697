<script>
import Setting_table from "@/pages/settings/setting_table.vue";
import TableSettings from "@/components/settings/TableSettings.vue";
import EditMasterDataModal from "@/components/masterdata/EditMasterDataModal.vue";
import OrderInfo from "@/components/order/SimpleTable.vue";
import MasterdataAddress from "@/components/masterdata/MasterdataAddress.vue";
import SettingsInput from "@/components/settings/SettingsInput.vue";
import OrderAccordion from "@/components/order/OrderAccordion.vue";
import {mapActions, mapGetters} from "vuex";
import DocuRefModal from "@/components/order/DocuRefModal.vue";

export default {
    name: "orders",
    components: {
        DocuRefModal,
        OrderAccordion,
        SettingsInput, MasterdataAddress, OrderInfo, EditMasterDataModal, TableSettings, Setting_table},
    data(){
        return{
            currOrder: null,
            docuRefs: []
        }
    },
    computed:{
        ...mapGetters({
            loggedPermission: 'auth/loggedPermission',
            isAdminOrCS: 'auth/isAdminOrCS'
        })
    },
    methods:{
        ...mapActions({
            actRepeatOrder: 'product/actRepeatOrder'
        }),
        handleView(data){
            this.currOrder = data;
            console.log(data)
        },
        disableDownload(docType, order){
            const {docuRefs} = order;
            if(docuRefs?.length){
                const ref = docuRefs.find(doc => doc.documentType === docType)
                if(ref){
                    return !ref.hasValue
                }
            }

            return true;
        },
        handleDownload(docType, order){
            const {docuRefs} = order;
            const ref = docuRefs.find(doc => doc.documentType === docType);
            console.log({ref})
            window.open(ref.documentLink, '_blank').focus();
            // const link = document.createElement('a');
            // link.href = window.URL.createObjectURL(ref.docuLink);
            // link.download = order.id;

            // Trigger the download
            // link.click();

            // Cleanup
            // window.URL.revokeObjectURL(link.href);
        },
        handleViewDocs({docuRefs}){
            this.docuRefs = docuRefs;
        },
        handleRepeatOrder(order){
            this.actRepeatOrder(order.id)
        }
    }
}
</script>

<template>
    <div>
        <docu-ref-modal :docu-refs="docuRefs"/>
        <div class="modal fade" id="orderInfo">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4>Order Details - Portal ID: {{currOrder?.id}}</h4>
                        <button class="btn-close max-w-max" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <order-accordion v-if="currOrder" :order="currOrder"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Breadcrumbs main="Order History" title="View orders history" />
        <div class="container-fluid">

        <table-settings
            :setting-type="{
                title: 'Order History',
                api: 'SaleOrderHeader',
                CUD: [1,1,1]
            }"
        >
            <template #newButton>
                    <router-link v-if="loggedPermission >= 1" to="/products" :class="['btn btn-success ml-auto min-w-max d-flex align-items-center gap-2']">
                        <i class="fa fa-plus"/>
                        New Order
                    </router-link>
                    <div v-else>

                    </div>
            </template>
            <template #actions="{rowData}">
                <div class="d-flex align-items-center gap-2">
                    <div data-bs-toggle="tooltip" data-bs-placement="top" title="Order Details">
                        <button class="btn btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#orderInfo"
                                @click="handleView(rowData)">
                            <i class="fa fa-eye"></i>
                        </button>
                    </div>
                    <div :data-bs-toggle="rowData.docuRefs?.length ? 'tooltip' : ''" data-bs-placement="top" title="Order Documents">
                        <button class="btn btn-success"
                                :disabled="!rowData.docuRefs?.length"
                                data-bs-toggle="modal"
                                data-bs-target="#docsInfo"
                                @click="handleViewDocs(rowData)">
                            <i class="fa fa-file-text"></i>
                        </button>
                    </div>
                    <button v-if="loggedPermission > 0" class="btn btn-primary" @click="handleRepeatOrder(rowData)">
                        <i class="fa fa-repeat"></i>
                    </button>
                </div>
            </template>
        </table-settings>
    </div>
    </div>
</template>

<style scoped>

</style>
