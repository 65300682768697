<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {addressInputs} from "@/constants/masterData";
import {format} from "date-fns";
import ContactView from "@/components/masterdata/ContactView.vue";
import EditMasterDataModal from "@/components/masterdata/EditMasterDataModal.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import {hardCodeApi} from "@/constants/tableSettings";
import {ref} from "vue";

export default {
    name: "AddressView",
    components: {ConfirmModal, EditMasterDataModal, ContactView},
    props: ['information'],
    setup(){
        const contactViewComp = ref()
    },
    data(){
        return{
            toggleAddressContacts: [],
            editModal: {
                inputs: null,
                data: null,
                title: null,
                type: null,
                deleteAddressId: null
            },
            notifyDelete: false
        }
    },
    computed:{
        ...mapState({
            customerMasterData: state => state.masterdata.customerMasterData,
            settingsApiData: state => state.settings.settingsApiData,
        }),
        ...mapGetters({
            masterDataPermissions: "auth/masterDataPermissions",
            isAdminOrCS: "auth/isAdminOrCS"
        }),
        deliveryAddresses(){
            const allAddr = []
            console.log(this.customerMasterData.addresses, 'this.customerMasterData.addresses')
            for(const addr of this.customerMasterData.addresses){
                if(addr.transactionType === 1){
                    allAddr.push(addr)
                    allAddr.push({contactRow: true, addressId: addr.id, contacts: addr.contacts})
                }
            }
            return allAddr;
        },
        addressInputs(){
            return [
                {label: 'Customer Account Id', type: 'null', key: 'accountum', notRequired: true},
                {label: 'Address Validation Status', type: 'select', api: 'Validations', key: 'status', valueType: 'number', reqPermission: 3},
                ...addressInputs.addresses,
            ];
        },
    },
    methods:{
        ...mapMutations({
            mutCloneEditAddress: "masterdata/mutCloneEditAddress"
        }),
        ...mapActions({
            actDeleteAddress: 'masterdata/actDeleteAddress'
        }),
        isAddressOpen(id){
            const idx = this.toggleAddressContacts.findIndex(i => i === id);
            return idx >=0;
        },
        handleToggleContact(id){
            const idx = this.toggleAddressContacts.findIndex(i => i === id);
            if(idx >= 0){
                this.toggleAddressContacts.splice(idx, 1);
            } else {
                this.toggleAddressContacts.push(id)
            }
        },
        handleEditAddress(data){
            console.log(data, 'dataaaa')
            this.mutCloneEditAddress({
                data: data,
                inputs: [
                    {label: 'Purpose', type: 'null', key: 'purpose', notRequired: true},
                    ...addressInputs.addresses,
                    {label: 'Address Validation Status', type: 'select', api: 'Validations', key: 'status', valueType: 'number', reqPermission: 3},
                ],
                title: `Delivery Address`,
                type: 'address'
            })
            // this.editModal.data = data;
            // this.editModal.inputs = addressInputs.addresses;
            // this.editModal.title = `Delivery Address`;
            // this.editModal.type = 'address'
        },
        // resetEditModal(){
        //     this.mutCloneEditAddress({
        //         data: null,
        //         inputs: null,
        //         title: null,
        //         type: null
        //     })
        // },
        handleAddressDel(id){
            this.deleteAddressId = id;
        },
        confirmAddressDelete(){
            this.actDeleteAddress( this.deleteAddressId)
        },
        handleNewAddress(){
            this.mutCloneEditAddress({
                data: {
                    transactionType: 1,
                    contacts: [],
                    users: []
                },
                inputs: addressInputs.addresses,
                title: `Delivery Address`,
                type: 'address'
            })
        },
        formatDate(date){
            return format(date, 'dd MMMM yyyy')
        },
        getInputData(input, data){
            if(this.settingsApiData[input.api]?.length){
                const opt = this.settingsApiData[input.api].find(o => o.id === data[input.key]);
                if(opt){
                    return opt.name || opt[input.displayKey]
                }
            } else if(hardCodeApi[input.api]) {
                const opt = hardCodeApi[input.api].find(o => o.id === data[input.key]);
                if(opt){
                    return opt.name || opt[input.displayKey]
                }
            }
            return data[input.key]
        },
        handleNotifyDeleteToChild(){
            this.notifyDelete = !this.notifyDelete
        }
    }
}
</script>

<template>
    <div>
        <div class="table-responsive add-project h-100 stickyLastCol">
            <table class="table table-hover card-table table-vcenter text-nowrap">
                <thead>
                <tr>
                    <th>
                        <div class="p-1 max-w-min text-capitalize">
                            <i class="fa fa-circle-o"></i>
                        </div>
                    </th>
                    <th v-for="(input, idx) in addressInputs" :key="input.key" :class="['fw-bold', {'text-center': idx > 1}]">
                        <div :class="['p-1 max-w-min text-capitalize', {'mx-auto': idx > 1}]">
                            {{ input.label }}
                        </div>
                    </th>
                    <th class="fw-bold text-center">
                        <div :class="['p-1 max-w-min text-capitalize mx-auto']">
                            Actions
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                    <template v-for="data in deliveryAddresses" :key="data.id">
                        <tr v-if="!data.contactRow">
                            <td>
                                <div class="p-1 max-w-min text-capitalize">
                                    <i :class="['fa fs-5 cursor-pointer', isAddressOpen(data.id) ? 'fa-minus-circle font-warning' : 'fa-plus-circle font-primary']" @click="handleToggleContact(data.id)"></i>
                                </div>
                            </td>
                            <td v-for="(input, idx) in addressInputs" :class="[{'text-center': idx > 1}]" :key="`${data.id}|${input.key}`">
                                <div v-if="input.type === 'boolean' || !data[input.key]" :class="['badge p-2 w-100', (data[input.key] ? 'badge-success' : 'badge-warning')]" style="max-width: max-content;">
                                    <div class="f-w-700">
                                        {{data[input.key] ? 'YES' : (input.type === 'boolean' ? 'NO' : `No ${input.label}`)}}
                                    </div>
                                </div>
                                <div v-else-if="input.type === 'date'">
                                    {{ formatDate(data[input.key]) }}
                                </div>
                                <div v-else-if="input.api">
                                    {{ getInputData(input, data) }}
                                </div>
                                <div v-else>
                                    {{ data[input.key]}}
                                </div>
                            </td>
                            <td>
                                <div class="d-flex gap-2 mx-auto w-100 justify-content-center">
                                    <button @click="handleEditAddress(data)"
                                            :disabled="!masterDataPermissions.canEditClientAddress"
                                            data-bs-toggle="modal"
                                            data-bs-target="#EditMasterDataModal"
                                            class="btn btn-primary btn-sm">
                                        <i class="fa fa-pencil"></i>
                                        Edit
                                    </button>
<!--                                    <button class="btn btn-danger btn-sm"-->
<!--                                            :disabled="!isAdminOrCS"-->
<!--                                            @click="handleAddressDel(data.id)"-->
<!--                                            type="button"-->
<!--                                            data-bs-toggle="modal"-->
<!--                                            data-bs-target="#deleteAddressModal">-->
<!--                                        <i class="fa fa-trash"></i>-->
<!--                                        Delete-->
<!--                                    </button>-->
                                </div>
                            </td>
                        </tr>
                        <tr v-if="toggleAddressContacts.includes(data.addressId)">
                            <td colspan="10">
                              <contact-view :contacts="data.contacts" :notify-delete="notifyDelete"/>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
<!--            <div class="modal fade" id="deleteAddressModal" role="dialog"-->
<!--                 aria-labelledby="deleteAddressModal" aria-hidden="true">-->
<!--                <ConfirmModal @confirmed="confirmAddressDelete">-->
<!--                    <template #message>-->
<!--                        Are you sure you want to delete this address?-->
<!--                    </template>-->
<!--                </ConfirmModal>-->
<!--            </div>-->
        </div>
        <button class="btn btn-primary d-flex gap-2 mt-3 align-items-center justify-content-center"
                :disabled="!masterDataPermissions.canCreateNewClientAddress"
                @click="handleNewAddress"
                data-bs-toggle="modal"
                data-bs-target="#EditMasterDataModal">
            <i class="fa fa-plus-circle"></i>
            Add Delivery Address
        </button>
        <div class="modal fade" id="deleteContactModal" role="dialog"
             aria-labelledby="deleteContactModal" aria-hidden="true">
            <ConfirmModal @confirmed="handleNotifyDeleteToChild">
                <template #message>
                    Are you sure you want to delete this contact?
                </template>
            </ConfirmModal>
        </div>
    </div>
</template>

<style scoped>

</style>
