<script>
import Header from "@/components/header/index.vue";

export default {
    name: "contact",
    components: {Header},

}
</script>

<template>
    <div class="mb-5">
        <img src="../assets/images/polar/contact.jpg" alt="support" class="w-100">
        <div class="container mt-5">
            <div>
                <h2 class="text-primary">Contact us</h2>
                <p>Our experts are here to guide you towards the best solution for you.</p>
            </div>
            <div class="row mt-5">
                <div class="col-12 col-sm-6 col-md-4">
                    <h6 class="text-primary">VISIT</h6>
                    <a href="https://maps.app.goo.gl/3qAT3uspL22RHuTy5">
                        <p>
                            Polar Speed Distribution
                            <br>
                            8 Chartmoor Road
                            <br>
                            Leighton Buzzard
                            <br>
                            Bedfordshire
                            <br>
                            LU7 4WG
                        </p>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <h6 class="text-primary">CONNECT</h6>
                    <a href="#">Some link</a>
                    <br>
                    <a href="#">Some link</a>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
