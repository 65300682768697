<script>
import {mapGetters, mapState} from "vuex";

export default {
    name: "BasketSummary",
    computed:{
        ...mapState({
            shoppingCart: state => state.product.shoppingCart
        }),
        ...mapGetters({
            gSetting: "settings/gSetting",
            loggedPermission: "auth/loggedPermission"
        }),
        currency(){
            const currencies = this.gSetting('Currency');
            if(currencies){
                const currency = currencies.find(c => c.id === this.shoppingCart?.currencyId)
                if(currency)
                    return currency.code;
            }
            return 'EUR';
        },
        summaryInfo(){
            return [
                {
                    title: 'Customer ID',
                    key: 'customerMasterDataId',
                    value: this.loggedPermission >= 3 ? `<a href="/master-data/${this.shoppingCart?.customerMasterDataId}">${this.shoppingCart?.customerMasterDataId}</a>` : null,
                },
                {title: 'Sub Total', key: 'subTotalWithoutVAT', value: `${this.currency} ${this.shoppingCart?.subTotalWithoutVAT}`},
                {title: 'VAT', key: 'vat'},
                {title: 'Total (Inc. VAT)', key: 'shoppingCartTotal', value: `${this.currency} ${this.shoppingCart?.shoppingCartTotal}`},
                {
                    title: 'Credit Limit',
                    key: 'residualCreditLimit',
                    value: `${this.shoppingCart?.residualCreditLimit.toFixed(2)}`,
                    style: 'font-danger'
                },
            ]
        }
    }
}
</script>

<template>
    <div class="filter-block">
        <h5>Shopping Cart Summary</h5>
        <ul v-if="shoppingCart">
            <li v-for="{key, title, value,style} in summaryInfo" :key="key">
                <div class="d-flex gap-1 mb-2">
                    <div class="min-w-max">
                        {{title}}:
                    </div>
                    <div :class="['min-w-max fw-bold flex-grow-1', (style || '')]">
                        <div v-html="value || shoppingCart[key]"/>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<style scoped>

</style>
