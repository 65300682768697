/* eslint-disable */
import api from '@/utils/api'
import { useToast } from 'vue-toastification';
import {getPermissionByte} from "@/utils/helperFunctions";
// import {errorToast} from "@/utils/helperFunctions";
import router from '../../router';

const toast = useToast();


const state = {
    user: null,
    token: '',
    permission: null,
    resetToken: '',
    userId: null,
    loggedUser: null,
    authResponse: {
        title: '',
        description: ''
    },
    roles: [
        {title: 'Admin', key: 'Admin', pByte: 3},
        {title: 'Read Only', key: 'Read Only', pByte: 1},
        {title: 'Customer Service', key: 'Customer Service', pByte: 2},
        {title: 'Regular User', key: 'Regular User', pByte: 1},
        {title: 'Call Centre', key: 'Call Centre', pByte: 1},
    ],
    emailConfirmed: false
};

// getters
const getters = {
    isAdminOrCS: (state) => {
        return state.loggedUser?.role === 'Admin' || state.loggedUser?.role === 'Customer Service'
    },
    masterDataPermissions: (state, getters) => {
        if(getters.isAdminOrCS){
            return {
                canCreateNewClientAddress:true,
                canEditClientAddress: true,
                canViewClientAddress: true
            }
        }
        return state.loggedUser?.permission || {
            canCreateNewClientAddress:false,
            canEditClientAddress: false,
            canViewClientAddress: false
        };
    },
    loggedPermission: (state) => {
        return getPermissionByte(state.loggedUser?.role);
    },
    canModifyUser: (state, getters) => (user) => {
        return (getters.loggedPermission > getPermissionByte(user.role)) || (user.id === state.loggedUser.id)
    },
    canEditUser: (state, getters) => (user) => {
        return (getters.loggedPermission >= getPermissionByte(user.role)) || (user.id === state.loggedUser.id)
    },
    userRoles: (state) => {
        return state.roles.filter(r => r.pByte <= state.permission);
    }
};

// mutations
const mutations = {
    mutSetState: (state, {key, value}) => {
        state[key] = value;
    },
    mutSetPermissions: (state, role) => {
        state.permission = getPermissionByte(role)
    },
    mutSetUserId: (state, userID) => {
        state.userId = userID
    },
    // mutSetToken: (state, token) => {
    //     state.token = token
    // },
    mutLoggedUser: (state, user) => {
        // const logUser = JSON.parse(localStorage.getItem('logged_user'));
        console.log('trying editing logged user')
        if(state.loggedUser?.id === user.id){
            console.log('editing logged user')
            state.loggedUser = {...state.loggedUser, ...user};
            localStorage.setItem('logged_user', JSON.stringify(state.loggedUser));
        }
    }
};

// actions
const actions = {
    actLogin: async ({commit, dispatch}, loginInfo) => {
        try{
            const response = await api.post('/user/login', loginInfo)
            if(response.data){
                console.log('success', response)
                // localStorage.setItem('access_token', response.data?.accessToken);
                localStorage.setItem('auth_info', JSON.stringify(response.data));
                localStorage.setItem('log_email', loginInfo.email);

                await dispatch('actGetLogUserData', loginInfo.email)
                // const {data} = await api.get(`/user/${loginInfo.email}`);
                // commit('mutSetState', {
                //     key: 'loggedUser',
                //     value: data
                // })
                // commit('mutSetPermissions', data.role)
                // commit('mutSetUser', response.data);
                // localStorage.setItem('logged_user', JSON.stringify(data));
            }
        } catch (e) {
            const {errors, status, title} = e.response.data
            // if(status === 404){
                //user exists
            console.log('errors::', e)
                commit('mutSetState', {
                    key: 'authResponse',
                    value: {
                        title: 'Incorrect Credentials',
                        description: errors[0]?.description
                    }
                })
            // }
            console.log('failed login', e)
        }
    },
    actGetLogUserData: async ({commit}, email) => {
        try {
            const {data} = await api.get(`/user/${email}`);
            console.log('dataaaaaaaaa')
            commit('mutSetState', {
                key: 'loggedUser',
                value: data
            })
            commit('mutSetPermissions', data.role);
            localStorage.setItem('logged_user', JSON.stringify(data));
        } catch (e) {
            // errorToast(e);
        }
    },
    actRegister: async({commit}, registerInfo) => {
        try {
            const response = await api.post('/user/register', registerInfo)
            if(response.data){
                console.log('success', response)
                commit('mutSetUserId', response.data)
                commit('mutSetState', {
                    key: 'authResponse',
                    value: {
                        title: 'Account has been registered',
                        description: `We have sent a confirmation link in your email: <b class="text-primary">${registerInfo.email}</b>`
                    }
                })
            } else {
                // console.error('failed register', response)
            }
            return 'success';
        } catch (e) {
            const {errors, status, title} = e.response.data
            // if(status === 409){
                //user exists
                commit('mutSetState', {
                    key: 'authResponse',
                    value: {
                        title,
                        description: errors[0]?.description
                    }
                })
            // }
            // errorToast(e);
            // console.log('failed register', e)
            return e;
        }
    },
    actConfirmEmail: async({commit}, {id, token}) => {
        try {
            const response = await api.post(`/user/confirmEmail/${id}`, null,{
                params: {
                    token
                }
            })
            console.log(response, 'response actConfirmEmail')
            commit('mutSetState', {
                key: 'authResponse',
                value: {
                    title: 'Email confirmed successfully',
                    description: 'You will receive another email as soon as your account is activated.'
                }
            })
            commit('mutSetState', {key: 'emailConfirmed', value: true})
        } catch (e) {
            const {errors, status, title} = e.response.data
            if(status === 404 || status === 500){
                //user dont exist or token invalid
                commit('mutSetState', {
                    key: 'authResponse',
                    value: {
                        title,
                        description: errors[0]?.description
                    }
                })
            }
        }
    },
    actResetPassword: async({commit, dispatch}, resetInfo) => {
        try {
            const response = await api.post('/user/resetPassword', resetInfo)
            if(response?.data){
                console.log('success', response)
                // commit('mutSetUserId', response.data)
            } else {

            }
            await dispatch('actLogin', {email: resetInfo.email, password: resetInfo.password});

        } catch (e) {
            console.log('failed login', e);
            const {errors, status, title} = e.response.data
            commit('mutSetState', {
                key: 'authResponse',
                value: {
                    title,
                    description: errors[0]?.description
                }
            })
        }
    },
    actChangePassword: async ({commit}, {id,...rest}) => {
        try {
          const response = await api.post(`/user/changePassword/${id}`, rest);
            console.log(response, 'response response')
            toast.success('Password changed successfully')
        } catch (e) {
            console.log(e, 'response response');
            // errorToast(e)
            return 'error'
        }
    },
    actLogOut: ({commit, dispatch}, options) => {
        // commit('mutSetUser', null);
        commit('mutGlobalLoader', true, {root: true})
        commit('mutSetState', {
            key: 'loggedUser',
            value: null
        });
        if(!options?.lockScreen){
            localStorage.clear();
            const noRedirect = options?.noRedirect;
            if(!noRedirect) {
                window.location.replace('/auth/login');
            } else {
                commit('mutGlobalLoader', false, {root: true})
            }
        }

    },
    actLockScreen: ({dispatch, rootState}) => {
        const user = {...rootState.auth.loggedUser};
        dispatch('actLogOut', {lockScreen: true});
        localStorage.clear();
        localStorage.setItem('lockScreenUser', JSON.stringify({
            email: user.email,
            title: `${user?.title}`,
            lastName: `${user?.lastName}`,
        }))
        router.push('/auth/lock-screen');
    }
    // actGetResetToken: async ({commit}, email) => {
    //     const response = await api.post('/user/getResetPasswordToken', null,{
    //         params: {email}
    //     })
    //
    //     if(response.data) {
    //         console.log(response, 'actGetResetToken success')
    //         commit('mutSetState', {key: 'resetToken', value: response.data})
    //     } else {
    //         console.log(response, 'actGetResetToken error')
    //     }
    // }
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
