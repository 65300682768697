<template>
<div>
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-xl-12 p-0">
                <div class="login-card">
                    <div>
                        <div>
                            <a class="logo">
                                <img class="img-fluid for-light" src="../../assets/images/logo/polar-logo.png" alt="looginpage" />
                            </a>
                        </div>
                        <new_register/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import PolarLoader from "@/components/PolarLoader.vue";
import New_register from "@/pages/authentication/new_register.vue";
export default {
    components: {New_register, PolarLoader},
    data() {
        return {
        }
    },
    mounted() {
        const recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute(
            "src",
            "https://www.google.com/recaptcha/api.js"
        );
        document.head.appendChild(recaptchaScript);
    },
    unmounted() {
        this.$store.commit('auth/mutSetState', {key: 'authResponse', value: {title: '', description: ''}})
    }
}
</script>
