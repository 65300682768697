<script>
export default {
    name: "ProggressButton",
    props: ['disabled', 'active', 'loading', 'btnClass']
}
</script>

<template>
    <div class="progress w-100 mx-auto text-center position-relative h-auto"
         style="max-width: min-content;">
        <button :class="[
                'btn btn-sm progress-bar w-100',
                (btnClass || '' ),
                {'btn-success': active && !btnClass},
                {'btn-danger': !active && !btnClass},
                {'disabled cursor-default': disabled},
                {'progress-bar-animated progress-bar-striped disabled': loading},
            ]"
            role="progressbar"
            aria-valuenow="100"
            aria-valuemin="0"
            aria-valuemax="100">
            <slot>
                {{active ? 'Active' : 'Inactive'}}
            </slot>
        </button>
    </div>
</template>

<style scoped>

</style>
