<script>
import SettingsInput from "@/components/settings/SettingsInput.vue";
import {addressInputs} from "@/constants/masterData";
import {mapState} from "vuex";

export default {
    name: "AddContact",
    components: {SettingsInput},
    props: ['modelValue', 'noTitle'],
    data(){
        return {
            newContact: {}
        }
    },
    computed:{
        ...mapState({
            editAddressModal: state => state.masterdata.editAddressModal
        }),
        comType(){
            return this.modelValue?.communicationType
        },
        inputs(){
            console.log(this.comType, 'comType comType comType')
            let comInputs = null
            if(this.comType === 2){
                comInputs = addressInputs.createEmailContact;
            } else {
                comInputs = addressInputs.createPhoneContact;
            }
            if(this.modelValue.id > 0){
                return [
                    {label: 'Purpose', type: 'null', key: 'purpose', notRequired: true},
                    ...comInputs
                ]
            }
            return comInputs;

        },
    },
    methods:{
        handleChange({key, value}){
            const newValue = {...this.modelValue, [key]: value};
            this.$emit('update:model-value', newValue);
        }
    },
}
</script>

<template>
    <div class="row">
        <div v-if="!noTitle" class="col-12">
            <h5>Add Contact {{comType === 2 ? 'Email' : 'Phone'}}</h5>
        </div>
        <div v-for="input in inputs"
             :class="['mb-2 d-flex flex-column', (input.customClass || 'col-12')]"
             :key="input.key">
            <settings-input
                :settingsData="modelValue"
                :model-value="modelValue[input.key]"
                @update:model-value="(val) => handleChange({key: input.key, value: val})"
                :input="input"
            />
        </div>
    </div>
</template>

<style scoped>

</style>
