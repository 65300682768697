<script>
import PolarSearch from "@/components/uiKits/PolarSearch.vue";
import api from "@/utils/api";
import PolarLoader from "@/components/PolarLoader.vue";
import {mapGetters, mapState} from "vuex";
import CustomerDropdown from "@/components/product/CustomerDropdown.vue";
import SettingsInput from "@/components/settings/SettingsInput.vue";
import {hardCodeApi} from "@/constants/tableSettings";
import {useToast} from "vue-toastification";
import {useVuelidate} from "@vuelidate/core";
import Pagination from "@/components/uiKits/Pagination.vue";

export default {
    setup(){
        return {
            v$: useVuelidate()
        }
    },
    components: {Pagination, SettingsInput, CustomerDropdown, PolarLoader, PolarSearch},
    data(){
        return{
            loading: false,
            productList: [],
            params: {
                searchKey: '',
                columnName: '',
                isDescending: false,
                pageNumber: 1,
                pageSize: 8
            },
            paginationInfo: {
                "pageNumber": 1,
                "totalPages": 1,
                "totalCount": 0,
                "hasPreviousPage": false,
                "hasNextPage": false
            },
            customers: [],
            editProduct: null,
            apiData: {
                Stock: hardCodeApi.Stock
            },
            zIdxId: null
        }
    },
    computed:{
        ...mapGetters({
            isAdminOrCS: "auth/isAdminOrCS",
            loggedPermission: "auth/loggedPermission"
        }),
        ...mapState({
            loggedUser: state => state.auth.loggedUser
        }),

        productDisplay(){
            const display = [
                {
                    key: 'urlImage', title: 'Product Image',
                    input: {type: 'string', key: 'urlImage'},
                    edit: true
                },
                {
                    key: 'name', title: 'Name',
                    edit: true,
                    input: {type: 'string', key: 'name'}
                },
                {
                    key: 'barcode', title: 'Code',
                    edit: true,
                    input: {type: 'string', key: 'barcode'}
                },
                {
                    key: 'description', title: 'Description',
                    edit: true,
                    input: {type: 'string', key: 'description'}
                },
                {
                    key: 'unitOfMeasureSymbol', title: 'Measure Symbol',
                    edit: true,
                    input: {type: 'select', api: 'UnitOfMeasure', key: 'unitOfMeasureSymbol', selectKey: 'symbol', displayKey: 'symbol'}
                },
                ...(this.loggedPermission >= 3 ? [
                    {
                        key: 'itemGroupName', title: 'Item Group', edit: true, alwaysShow: true,
                        input: {type: 'select', api: 'ItemGroup', valueType: 'number', key: 'itemGroupId'}
                    },
                    {
                        key: 'taxName', title: 'Tax Item Group', edit: true, alwaysShow: true,
                        input: {type: 'select', api: 'TaxItemGroup', displayKey: 'itemVatGroup', valueType: 'number', key: 'taxItemGroupId'}
                    }] : []),
                // {key: 'createdOnUtc', title: 'Added Date'},
                {
                    key: 'psdwP_OutOfStock', title: 'Stock',
                    edit: true,
                    input: {type: 'select', api: 'Stock', key: 'psdwP_OutOfStock', valueType: 'number'}
                },
                {
                    key: 'isChildItem', title: 'Is Child',
                    edit: true,
                    input: {type: 'boolean', key: 'isChildItem'}
                },
            ]

            if(this.editProduct?.newId){
                return [
                    {
                        key: 'id', title: 'Product ID',
                        input: {type: 'string', key: 'id'},
                        edit: true
                    },
                    ...display,
                    {
                        key: 'psdwP_Warning_Text', title: 'Warning Text',
                        input: {type: 'string', key: 'psdwP_Warning_Text'},
                        edit: true
                    },
                    {
                        key: 'psdwP_ProductDescription', title: 'Product Description',
                        input: {type: 'string', key: 'psdwP_ProductDescription'},
                        edit: true
                    },
                    {
                        key: 'psdwP_ProductSummary', title: 'Summary',
                        input: {type: 'string', key: 'psdwP_ProductSummary'},
                        edit: true
                    },
                ]
            }
            return display;
        },
        productsDisplayData(){
            if(this.loggedPermission < 3){
                return this.productList;
            }
            if(!this.apiData.ItemGroup) return [];
            return this.productList.map(p => {
                // for(const api in this.apiData){
                //     if(p[api])
                // }
                p.itemGroupName = this.apiData.ItemGroup.find(d => d.id === p.itemGroupId)?.name;
                p.taxName = this.apiData.TaxItemGroup.find(d => d.id === p.taxItemGroupId)?.itemVatGroup;
                return {
                    ...p,
                    // createdOnUtc: format(p.createdOnUtc, 'PPpp'),
                }
            })
        }
    },
    methods:{
        async getCustomers(){
            if(this.loggedPermission < 3) return;
            try {
                const {data} = await api.get('/CustomerMasterData', {
                    params: {
                        pageNumber: 1,
                        pageSize: 10000
                    }
                })

                this.customers = data.items
            } catch (e) {
                // errorToast(e);
            }
        },
        async handleGetProducts(){
            if(this.editProduct) return;
            const url = this.loggedPermission >= 3 ? `/getAllProducts` : '';
            this.loading = true;
            try {
                const {data} = await api.get(`/ProductMasterData${url}`, {
                    params: this.params
                })
                const {items, ...paginationInfo} = data;
                console.log(paginationInfo)
                this.productList = items;
                this.paginationInfo = paginationInfo
            } catch (e) {
                console.log(e, 'error');
            }
            this.loading = false;
        },
        viewProduct(id, customerKey){
            this.$router.push(`/products/${id}&&${customerKey || this.loggedUser.customerMasterDataId}`);
        },
        handleSort(key){
            if(key === this.params.columnName){
                this.params.isDescending = !this.params.isDescending;
            }
            this.params.columnName = key;
            this.params.pageNumber = 1;
            this.handleGetProducts();
        },
        handlePageChange(page){
            this.params.pageNumber = page;
            this.handleGetProducts();

        },
        handleSearch(){
            this.params.pageNumber = 1;
            this.handleGetProducts();
        },
        handleEditProduct(product){
            this.editProduct = {...product};
        },
        scrollToError(){
            const errorEl = document.getElementsByClassName('invalid-feedback');
            const el = errorEl[0];
            const td = el.parentElement.parentElement.parentElement;
            const table = document.getElementsByClassName('table-responsive')[0]
            table.scrollTo(td.offsetLeft, 0)
        },
        async handleUpdateProduct(idx){
            const result = await this.v$.$validate();
            if(!result){
                this.scrollToError();
            } else {
                this.loading = true;
                const {id, newId,...rest} = this.editProduct;
                const newProduct = {id, ...rest}
                try {
                    if(newId){
                        await api.post(`/ProductMasterData`, newProduct);
                    } else {
                        await api.put(`/ProductMasterData/${id}`, rest);
                        this.productList.splice(idx, 1, newProduct);
                    }
                    this.editProduct = null;
                    useToast().success('Product updated successfully');
                } catch (e) {

                } finally {
                    this.loading = false;
                }
            }
        },
        handleCancelEdit(){
            if(this.editProduct.newId) {
                this.productList.splice(0,1)
            }
            this.editProduct = null;
        },
        handleNewProduct(){
            const prod = {
                newId: true,
                isChildItem: false
            }
            this.productList = [prod, ...this.productList]
            this.editProduct = prod;
        },
        async getApiDetails(){
            try {
                for(const url of ['ItemGroup', 'TaxItemGroup', 'UnitOfMeasure']){
                    const {data} = await api.get(url,{
                        params: {
                            pageNumber: 1,
                            pageSize: 10000
                        }
                    })
                    this.apiData[url] = data.items;
                }
            } catch (e){

            }
        }
    },
    async mounted() {
        await this.getApiDetails()
        this.handleGetProducts();
        this.getCustomers();
    }
}
</script>

<template>
    <div>

    <Breadcrumbs main="Manage Products" title="Product List" />

    <div class="container-fluid" style="max-width: 1900px;">
        <div class="row">
            <div class="col-sm-12">
                <div :class="['card position-relative', {'overflow-hidden': loading}]">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <PolarSearch v-model="params.searchKey" @search="handleSearch" style="max-width: 400px;"/>
                        <div>

                        <button v-if="isAdminOrCS" class="btn btn-primary" @click="handleNewProduct" :disabled="!!editProduct">
                            <i class="fa fa-plus"></i>
                            New Product
                        </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div :class="[(!!zIdxId ? 'overflow-x-clip' : 'table-responsive overflow-y-clip'), 'stickyLastCol']">
                            <table class="table table-hover card-table table-vcenter text-nowrap" style="min-height: 200px">
                                <thead>
                                <tr>
                                    <th v-for="head in productDisplay" @click="handleSort(head.key)" :key="head.title" class="fw-bold cursor-pointer" scope="col">
                                        {{head.title}}
                                        <i class="fa fa-sort"/>
                                    </th>
                                    <th scope="col" class="text-center fw-bold">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(product, idx) in productsDisplayData"
                                    :key="product.id"
                                    :class="[{'filterBlur': editProduct && editProduct?.id !== product.id}]">
                                    <td v-for="{key, input, edit, title} in productDisplay">
                                        <div v-if="edit && editProduct?.id === product.id" class="prodInputCol">
                                            <settings-input
                                                class="min-w-max"
                                                :parent-select="input.api ? (apiData?.[input.api] || []) : null"
                                                :input="{...input, placeholder: title}"
                                                :settings-data="editProduct"
                                                v-model="editProduct[input.key || key]"
                                            />
                                        </div>
                                        <div v-else>
                                            <img v-if="key === 'urlImage'"
                                                 style="max-width: 100px;"
                                                 :src="product[key]"
                                                 alt="product">
                                            <div v-else-if="key === 'psdwP_OutOfStock'"
                                                 :class="!product[key] ? 'font-success' : 'font-danger'"
                                            >
                                                {{!product[key] ? 'In stock' : 'Out of stock'}}
                                            </div>
                                            <div v-else-if="key === 'isChildItem'" :class="['badge', (!!product[key] ? 'badge-success' : 'badge-danger')]">
                                                <span class="f-w-700">{{!!product[key] ? 'YES' : 'NO'}}</span>
                                            </div>
                                            <div v-else>
                                                {{product[key]}}
                                            </div>
                                        </div>

                                    </td>
                                    <td :style="(zIdxId === product.id ? 'z-index: 1;' : '')">
                                        <div class="d-flex h-100 align-items-center w-100 justify-content-center">

                                        <div v-if="editProduct?.id !== product.id" class="d-flex gap-2 justify-content-center">
                                            <button
                                                v-if="isAdminOrCS"
                                                class="btn btn-primary btn-sm"
                                                @click="handleEditProduct(product)"
                                            >
                                                <i class="fa fa-pencil"></i>
                                                Edit
                                            </button>
                                            <CustomerDropdown v-if="isAdminOrCS"
                                                              :customers="customers"
                                                              @open="() => zIdxId = product.id"
                                                              @close="() => zIdxId = null"
                                                              @viewAs="(customerKey) => viewProduct(product.id, customerKey)">
                                                View As
                                            </CustomerDropdown>
                                            <button v-else class="btn btn-outline-primary btn-sm ms-1"
                                                    @click="viewProduct(product.id)"
                                                    type="button"
                                                    data-original-title="btn btn-danger btn-sm" title="">
                                                <i class="fa fa-eye"/> View
                                            </button>
                                        </div>
                                        <div v-else class="d-flex gap-2 justify-content-center">
                                            <button
                                                class="btn btn-success btn-sm"
                                                @click="handleUpdateProduct(idx)"
                                            >
                                                <i class="fa fa-check"></i>
                                                Save
                                            </button>
                                            <button @click="handleCancelEdit"
                                                    class="btn btn-light">
                                                <i class="fa fa-close"></i>
                                                Cancel
                                            </button>
                                        </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <polar-loader v-if="loading">
                        Loading Products
                    </polar-loader>
                </div>
            </div>
            <div class="mb-5 mt-3">
                <pagination v-if="paginationInfo" :pagination-info="paginationInfo" @pageChanged="handlePageChange"/>
            </div>

        </div>
    </div>
    </div>
</template>

<style scoped>


table th:last-child{
    height: 55px;
}
table td:last-child {
    height: 156px;
}

.filterBlur{
    filter: blur(4px);
    pointer-events: none;
}
</style>

<style>
.prodInputCol label{
    margin-top: 5px !important;
}
</style>
