<script>
import PolarLoader from "@/components/PolarLoader.vue";
import changePasswordValidation from "@/mixins/validations/changePasswordValidation";
import {mapActions, mapState} from "vuex";

export default {
    name: "PasswordModal",
    mixins: [changePasswordValidation],
    data(){
        return{
            oldActive: true,
            newActive: true,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        }
    },
    components: {PolarLoader},
    computed:{
        ...mapState({
            loggedUser: state => state.auth.loggedUser
        })
    },
    methods:{
        ...mapActions({
            actChangePassword: 'auth/actChangePassword'
        }),
        async handlePasswordChange(){
            this.validatePasswordForm({
                oldPassword: this.oldPassword,
                newPassword: this.newPassword,
                confirmPassword: this.confirmPassword
            })

            if(this.formIsValid){
                await this.actChangePassword({
                    id: this.loggedUser.id,
                    oldPassword: this.oldPassword,
                    newPassword: this.newPassword,
                    confirmNewPassword: this.confirmPassword
                })

                this.closeModal();
            }
        },
        show(val){
            console.log(val, 'valiiii')
            if(val === 1){
                this.oldActive = !this.oldActive
            } else {
                this.newActive = !this.newActive
            }
        },
        closeModal(){
            document.getElementById('dismissChangePassword').click();
        }
    }
}
</script>

<template>
    <div class="modal fade" id="changePassword" role="dialog"
         aria-labelledby="changePassword" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Change Password
                    </h5>
                    <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form class="theme-form" novalidate="">
                        <div class="form-group">
                            <label class="col-form-label">Old Password</label>
                            <div class="form-input position-relative">

                                <input autocomplete="off"
                                       v-model="oldPassword"
                                       :type="oldActive?'password':'text'"
                                       :class="[
                                            'form-control',
                                            `${formSubmitted ? oldPasswordError ? 'is-invalid' : 'is-valid' : ''}`
                                       ]"
                                       name="login[password]"
                                       required=""
                                       placeholder="*********" />
                                <div class="show-hide"
                                     style="padding-right: 10px; top: 50% !important;">
                                    <span :class="oldActive?'show':'hide'" @click.prevent="show(1)"> </span>
                                </div>
                            </div>
                            <div class="invalid-feedback d-block">{{ errors.oldPassword }}</div>
                        </div>
                        <div class="form-group">
                            <label class="col-form-label">New Password</label>
                            <div class="form-input position-relative">

                                <input autocomplete="off"
                                       v-model="newPassword"
                                       :type="newActive?'password':'text'"
                                       :class="[
                                            'form-control',
                                            `${formSubmitted ? newPasswordError ? 'is-invalid' : 'is-valid' : ''}`
                                       ]"
                                       name="login[password]"
                                       required=""
                                       placeholder="*********" />
                                <div class="show-hide"
                                     style="padding-right: 10px;top: 50% !important;">
                                    <span :class="newActive?'show':'hide'" @click.prevent="show"> </span>
                                </div>
                            </div>
                            <div class="invalid-feedback d-block">{{ errors.newPassword }}</div>
                        </div>
                        <div class="form-group">
                            <label class="col-form-label">Retype Password</label>
                            <input autocomplete="off"
                                   v-model="confirmPassword"
                                   :class="[
                                        'form-control',
                                        `${formSubmitted ? confirmPasswordError ? 'is-invalid' : 'is-valid' : ''}`
                                   ]"
                                   :type="newActive?'password':'text'"
                                   name="login[password]"
                                   required=""
                                   placeholder="*********">
                            <div class="invalid-feedback">{{ errors.confirmPassword }}</div>
                        </div>
                    </form>

                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" type="button" @click="handlePasswordChange">Submit</button>
                    <button id="dismissChangePassword" class="btn btn-light" type="button" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
<!--        <polar-loader></polar-loader>-->
    </div>
</template>

<style scoped>

</style>
