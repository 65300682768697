<script>
import {colTitle} from "@/constants/tableSettings";

export default {
    name: "SimpleTable",
    methods: {colTitle},
    props: ['columns', 'data', 'actions'],
    computed:{
    }
}
</script>

<template>
    <div class="table-responsive add-project">
        <table class="table table-hover card-table table-vcenter text-nowrap">
            <thead>
                <tr>
                    <th v-for="(key, idx) in columns" :key="key + 'oi'" :class="['fw-bold', {'text-center': idx > 2}]">
                        <div :class="['p-1 cursor-pointer max-w-min', {'mx-auto': idx > 2}]">
                            <slot name="columnHead" :column="key">
                                {{ colTitle(key) }}
                            </slot>
                        </div>
                    </th>
                    <th v-if="actions" class="fw-bold text-center">
                        <div class="p-1">
                            Actions
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="d in data">
                    <td v-for="(key, idx) in columns" :key="`${key}|col`" :class="[{'text-center': idx > 2}]">
                        <div>
                            <slot name="columnRow" :tableData="d" :column="key">
                                {{ d[key] }}
                            </slot>
                        </div>
                    </td>
                    <td v-if="actions" class="text-center">
                        <slot name="actions" :data="d">
                            <button>Actions Slot</button>
                        </slot>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped>

</style>
