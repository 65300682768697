<script>
export default {
    name: "DisplayCard",
    props: ['title', 'path']
}
</script>

<template>
    <div class="card widget-1">
        <div class="card-body">
            <div>
                <h5 class="mb-1 f-w-600">{{title}}</h5><span class="f-light">Small description</span>
            </div>
            <div>
                <router-link :to="path" class="btn btn-primary d-flex align-items-center gap-2 justify-content-center">
                    <vue-feather type="settings" style="width: 15px"/>
                    <div class="d-none d-sm-block">Settings</div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
