
<script>
import api from "@/utils/api";
import {mapState} from "vuex";
import SettingsInput from "@/components/settings/SettingsInput.vue";
import {defaultAddressInputs} from "@/constants/masterData";

export default {
    name: "InvoiceAddresCheckout",
    components: {SettingsInput},
    data(){
        return {
            invoiceAddress:null,
            loading: true
        }
    },
    computed:{
        ...mapState({
            shoppingCart: state => state.product.shoppingCart
        }),
        invoiceInputs(){
            const inputKeys = [
                'd365AddressLocationID',
                'addressValidToUtc',
                'addressValidFromUtc',
            ]
            const filtered = defaultAddressInputs.filter(input => {
                return !inputKeys.includes(input.key)
            })

            return [
                {type: 'string', key: 'phone', label: 'Telephone'},
                {type: 'string', key: 'email', label: 'Email'},
                ...filtered,
                {type: 'string', key: 'paymentMethod', label: 'Payment Method'},
                {type: 'string', key: 'customerMasterDataId', label: 'Wp Account Number'},
            ]
        },
    },
    methods:{
        setInvoiceData(data){
            let phone, email;
            for(const contact of data.contacts){
                if(contact.communicationType === 1){
                    phone = contact.locator
                } else {
                    email = contact.locator
                }
            }
            return {...data, phone, email}
        },
        async getInvoice(){
            try {
                const {data} = await api.get(`/ShoppingCartSummary/getInvoiceAddress/${this.shoppingCart.shoppingCartId}`);

                this.invoiceAddress = this.setInvoiceData(data);
            } catch (e) {

            } finally {
                this.loading = false
            }
        }
    },
    mounted() {
        this.getInvoice();
    }
}
</script>

<template>
    <div>
        <div class="row" v-if="invoiceAddress">
            <settings-input v-for="input in invoiceInputs"
                            class="col-6"
                            :key="input.key"
                            :disabled="true"
                            :input="input"
                            :model-value="invoiceAddress[input.key]"
                            :settings-data="invoiceAddress"
            />
        </div>
    </div>
</template>

<style scoped>

</style>
