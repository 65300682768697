<template>
  <li class="cart-nav onhover-dropdown cursor-default">
    <div class="cart-box cursor-pointer">
      <svg>
        <use href="@/assets/svg/icon-sprite.svg#stroke-ecommerce" @click="cart_open()"></use>
      </svg>
      <!-- <feather type="shopping-cart" @click="cart_open()"></feather> -->
      <span v-if="gCartItems" class="badge rounded-pill badge-success">
          {{gCartItems}}
      </span>
    </div>
    <div class="cart-dropdown onhover-show-div" :class="{ active: cart }">
      <h6 class="f-18 mb-0 dropdown-title">Shopping Cart</h6>
      <ul>
          <transition-group name="fadeOut" leave-active-class="animated fadeOutRight">
              <li class="mt-0" v-for="line in gCartLines" :key="line.id">
                  <small-productview :cart-line="line"/>
              </li>
          </transition-group>
        <li class="total">
          <h6 class="mb-0">
            Order Total : <span class="f-right">{{gCartCurrency}} {{ gCartTotal }}</span>
          </h6>
        </li>
        <li class="text-center">
          <router-link
            class="d-block mb-3 f-w-700 view-cart cursor-pointer"
            :to="'/shopping-cart'"
          >
            Go to my shopping cart
          </router-link>
<!--          <router-link-->
<!--            :to="'/checkout'"-->
<!--            class="btn btn-primary view-checkout cursor-pointer"-->
<!--          >-->
<!--            Checkout-->
<!--          </router-link>-->
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
  import SmallProductview from "@/components/product/SmallProductview.vue";

  export default {
    name: 'Cart',
      components: {SmallProductview},
    data() {
      return {
        cart: false,
      };
    },
      computed:{
        ...mapState({
            shoppingCart: state => state.product.shoppingCart
        }),
        ...mapGetters({
            gCartLines: 'product/gCartLines',
            gCartTotal: 'product/gCartTotal',
            gCartItems: 'product/gCartItems',
            gCartCurrency: 'product/gCartCurrency',
        })
      },
    methods: {
        ...mapActions({
            actGetAllApiData: "settings/actGetAllApiData"
        }),
          cart_open() {
            this.cart = !this.cart;
          },
    },
      created() {
          this.actGetAllApiData(['Currency']);
      }
  };
</script>
