<script>
import TableSettings from "@/components/settings/TableSettings.vue";

export default {
    name: "dashboard_kit.vue",
    components: {TableSettings}
}
</script>

<template>
    <div>
        <Breadcrumbs main="Products" title="Dashboard Kit" />
        <div class="container">

            <table-settings
                dateFormat="dd MMMM yyyy, HH:mm"
                :setting-type="{
                title: 'Product Kits', api: 'Kit', CUD: [1,1,1]}"
            >
<!--                <template #title-itemId="{itemId}">-->
<!--                    Status-->
<!--                </template>-->
<!--                <template #booleanCell-isSuccess="{cellData}">-->
<!--                    {{cellData ? 'Processed' : 'Pending'}}-->
<!--                </template>-->
<!--                <template #actions="{rowData: {saleOrderHeaderId, isSuccess}}">-->
<!--                    <button v-if="loggedPermission > 3 && !isSuccess" :disabled="isSuccess" class="btn btn-outline-primary" @click="handleResend(saleOrderHeaderId)">-->
<!--                        Resend-->
<!--                    </button>-->
<!--                    <div v-else class="text-center">-->
<!--                        No actions-->
<!--                    </div>-->
<!--                </template>-->
            </table-settings>
        </div>
    </div>
</template>

<style scoped>

</style>
