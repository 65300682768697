<script>
import {mapActions, mapMutations, mapState} from "vuex";
import ProductExtraDetails from "@/components/product/ProductTabInfo.vue";
import ProductImage from "@/components/product/ProductImage.vue";
import ProductDetails from "@/components/product/ProductDetails.vue";
import ExtraDetails from "@/components/product/ExtraDetails.vue";
import {useToast} from "vue-toastification";

export default {
    name: "single_product",
    components: {ExtraDetails, ProductDetails, ProductImage, ProductExtraDetails},
    data(){
        return {
            loading: true
        }
    },
    computed:{
        ...mapState({
            singleProduct: state => state.product.singleProduct,
            currQuantity: state => state.product.currQuantity,
        })
    },
    methods: {
        ...mapActions({
            actSetSingleProduct: 'product/actSetSingleProduct',
            actGetPrice: 'product/actGetPrice',
        }),
        ...mapMutations({
            mutSetProductCustomer: 'product/mutSetProductCustomer',
            mutSetSingleProduct: 'product/mutSetSingleProduct',
            mutQuantity: 'product/mutQuantity',
        }),
        async getProduct({product}){
            this.loading = true;
            const [productId, customerKey] = product.split('&&');
            this.mutSetProductCustomer(customerKey)
            this.mutSetSingleProduct({productId})
            // await this.actSetSingleProduct(productId);
            await this.actGetPrice({productId, customerKey, quantity: this.currQuantity})
            this.loading = false;
        }
    },
    async mounted() {
        this.mutQuantity(1);
        this.mutSetSingleProduct(null)
        await this.getProduct(this.$route.params);
        if(!this.singleProduct?.price){
            useToast().error('There is no price for this product!')
        }
    },
    beforeRouteUpdate(to, from) {
        if(to.name === 'Single Product'){
            this.mutQuantity(1);
            this.mutSetSingleProduct(null)
            this.getProduct(to.params);
        }
    },
}
</script>

<template>
    <div>
        <Breadcrumbs :diff-main="singleProduct?.name || 'Single Product'" main-path="/products" main="Products" title="Product Page" />
        <div class="container-fluid" style="max-width:1500px;">
            <div>
                <div class="row product-page-main firstInfoP p-0">
                    <div class="col-xxl-4 col-lg-3 col-md-6 col-sm-6 colPb">
                        <ProductImage :image="singleProduct?.urlImage"/>
                    </div>
                    <div class="col-xxl-5 col-lg-5 order-0 order-sm-last order-lg-0 colPb">
                        <product-details />
                    </div>
                    <div class="col-xxl-3 col-lg-4 col-sm-6 colPb">
                        <extra-details />
                    </div>
                </div>
                <div class="card">
                    <div class="row product-page-main">
                        <ProductExtraDetails />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.firstInfoP .card{
    height: 100%;
}

.firstInfoP .card-body{
    display: flex;
    flex-direction: column;
}

.firstInfoP .colPb{
    padding-bottom: 30px;
}
</style>
