<template>
    <div class="container-fluid">
        <Breadcrumbs main="Dashboard"/>
        <DashboardNotifications/>
<!--        <input :value="inpValue" @input="handleUpdate">-->
<!--        <DropdownSearch :options="PhoneCodes" v-model="searchString" :filter-keys="['country']" style="max-width: 200px">-->
<!--            <template #option="{data}">-->
<!--                <div class="d-flex">-->
<!--                    <span>-->
<!--                        {{data.country}}-->
<!--                    </span>-->
<!--                    <span class="ml-auto">-->
<!--                        +{{data.code}}-->
<!--                    </span>-->
<!--                </div>-->
<!--            </template>-->
<!--        </DropdownSearch>-->
        <div class="row starter-main">
            <template v-for="(card, idx) in DashboardCards.data" :key="idx">
                <div v-if="card.reqPermission <= store.getters['auth/loggedPermission']" class="col-12 col-sm-6 col-xl-4 p-1">
                    <home-card :content="card"/>
                </div>
            </template>
        </div>
    </div>
</template>
<script setup>
import HomeCard from "@/components/homeCard.vue";
import DashboardCards from "@/data/dashboardCards.json";
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import DashboardNotifications from "@/components/dashboard/DashboardNotifications.vue";
import SearchBy from "@/components/uiKits/SearchBy.vue";
import PhoneCodes from "@/data/phoneCodes.json"
import DropdownSearch from "@/components/uiKits/DropdownSearch.vue";

const store = useStore();
// const searchString = ref('');
// searchString.value = '123456789'
//
// const inpValue = computed(() => {
//     const word = searchString.value.replace(' ', '')
//     let sliceArray = [3,3,3];
//     let newString = null;
//     let value = ''
//     for(const slice of sliceArray){
//         if(newString){
//             console.log('newString::', newString)
//             if(newString.length >= slice){
//                 value += ` ${newString.slice(0, slice)}`;
//                 newString = newString.slice(slice);
//             }
//         } else if(!value) {
//             if(word.length >= slice){
//                 value += `${word.slice(0, slice)}`;
//                 newString = word.slice(slice);
//             }
//         }
//     }
//
//     return value || word;
// })
// const handleUpdate = (e) => {
//     searchString.value = e.target.value;
// }
onMounted(() => {
    store.dispatch('notifications/actGetDashNotifications');
})

</script>
