<script>
import {mapGetters, mapState} from "vuex";
import {format} from "date-fns";

export default {
    name: "ExtraDetails",
    computed:{
        ...mapState({
            singleProduct: state => state.product.singleProduct
        }),
        ...mapGetters({
            loggedPermission: "auth/loggedPermission"
        }),
        info(){
            if(!this.singleProduct) return [];
            const {product} = this.$route.params;
            const [productId, customerKey] = product.split('&&');
            const info =  [
                {title: 'Customer ID', value: customerKey},
                {title: 'Summary', key: 'psdwP_ProductSummary'},
                // {title: 'Warning', key: 'psdwP_Warning_Text'},
                // {title: 'Created', key: 'createdOnUtc', value: format(this.singleProduct.createdOnUtc, 'PPpp')},
                // {title: 'Created By', key: 'createdBy'},
                // {title: 'Modified', key: 'lastModifiedOnUtc', value: format(this.singleProduct.lastModifiedOnUtc, 'PPpp')},
                // {title: 'Modified By', key: 'lastModifiedBy'},
            ]
            if(this.loggedPermission >= 3){
                return [...info,
                    {title: 'Tax Item Group', key: 'taxItemGroupVatName'},
                    {title: 'Item Group', key: 'itemGroupName'}
                ]
            }
            return info;
        }
    }
}
</script>

<template>
    <div class="card">
        <div class="card-body">
            <div class="filter-block">
                <h4>Information</h4>
                <ul>
                    <li v-for="i in info" :key="i.key">
                        <div class="d-flex gap-1" v-if="i.value || singleProduct[i.key]">
                            <div>
                                {{i.title}}:
                            </div>
                            <div class="min-w-max fw-bold flex-grow-1">
                                {{i.value || singleProduct[i.key]}}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
