<script>
import CreateEditSettings from "@/components/settings/CreateEditSettings.vue";
import SettingsInput from "@/components/settings/SettingsInput.vue";
import api from "@/utils/api";
import {useToast} from "vue-toastification";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
    name: "EditNotification",
    components: {SettingsInput, CreateEditSettings},
    data(){
        return {
            notification: {
                "title": "",
                "description": "",
                "color": 1,
                "isDisplayed": false
            },
            colors: [ 1, 2, 3, 4 ]
        }
    },
    methods:{
        ...mapMutations({
            mutNotificationState: 'notifications/mutNotificationState'
        }),
        ...mapActions({
            actUpdateNotification: 'notifications/actUpdateNotification'
        }),
        getColor(val){
            let color;
            switch (val) {
                case 1:
                    color = 'Green';
                    break;
                case 2:
                    color = 'Blue';
                    break;
                case 3:
                    color = 'Yellow';
                    break;
                case 4:
                    color = 'Red';
                    break;
                default:
                    color = 'No color';
            }
            return color;
        },
        async handleCreate(){
            try {
                if(this.currentNotification){
                    //editing
                    const {id, title, description, isDisplayed, color} = this.notification;
                    await this.actUpdateNotification({
                        notification: {title, description, isDisplayed, color},
                        id
                    })
                } else {
                    //creating
                    const {data} = await api.post('/homePageInfo', this.notification)
                    console.log('data::::', data)
                }
                this.closeModal();
                this.$emit('notificationUpdated')
            } catch (e) {
                const {errors} = e.response?.data || {errors: [{description: 'Something wrong happened!'}]}
                useToast().error(errors[0].description);
            }
        },
        resetNotification(){
            this.notification = {
                "title": "",
                "description": "",
                "color": 1,
                "isDisplayed": false
            }
        },
        handleModalShow(){
            if(this.currentNotification){
                this.notification = {...this.currentNotification};
            } else {
                this.resetNotification();
            }
        },
        handleModalHide(){
            if(this.currentNotification){
                this.mutNotificationState({key: 'currentNotification', value: null});
            }
            this.resetNotification();
        },
        closeModal(){
            document.getElementById('dismissCreateSettings').click();
        }
    },
    computed:{
        ...mapState({
            currentNotification: state => state.notifications.currentNotification
        })
    },
    created() {
        this.handleModalShow();
    },
    mounted() {
        const createSettingModal = document.getElementById('createSettingModal');
        createSettingModal.addEventListener('shown.bs.modal', this.handleModalShow)
        createSettingModal.addEventListener('hidden.bs.modal', this.handleModalHide)
    },
    beforeUnmount() {
        const createSettingModal = document.getElementById('createSettingModal');
        createSettingModal.removeEventListener('shown.bs.modal', this.handleModalShow)
        createSettingModal.removeEventListener('hidden.bs.modal', this.handleModalHide)
    },
}
</script>

<template>
    <div class="modal fade" id="createSettingModal" role="dialog"
         aria-labelledby="createSettingModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <div>
                        <h5 class="modal-title">{{ `${currentNotification ? 'Edit' : 'Create new'} notification`}}</h5>
                        <!--                        <p>Enter required information</p>-->
                    </div>
                    <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="theme-form">
                        <div class="form-group">
                            <label class="col-form-label" for="title">Title*</label>
                            <input v-model="notification.title"
                                   class="form-control"
                                   type="text"
                                   required=""
                                   placeholder="Enter a title"
                            />
                        </div>
                        <div class="form-group">
                            <label class="col-form-label" for="title">Description*</label>
                            <input v-model="notification.description"
                                   class="form-control"
                                   type="text"
                                   required=""
                                   placeholder="Enter a description"
                            />
                        </div>
                        <div class="form-group">
                            <label class="col-form-label">Color*</label>
                            <select
                                    v-model="notification.color"
                                    class="form-select"
                                    required="">
                                <option selected="" disabled="" value="">Choose a color...</option>
                                <option v-for="color in colors" :value="color" :selected="color === notification.color" :key="color + 'color'">{{ getColor(color) }}</option>
                            </select>
                        </div>
                        <label for="displayed"
                               class="d-block cursor-pointer mt-3">
                            <input v-model="notification.isDisplayed"
                                   class="checkbox_animated"
                                   id="displayed"
                                   type="checkbox">
                            Display
                        </label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="dismissCreateSettings" class="btn btn-light" type="button" data-bs-dismiss="modal">Cancel</button>
                    <button class="btn btn-primary" type="button" @click="handleCreate">{{ currentNotification ? 'Edit' : 'Create' }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
