<script>
export default {
    name: "CustomToast",
    props: ['message']
}
</script>

<template>
    <div @click="$emit('toastClicked')" v-html="message"/>
</template>

<style scoped>

</style>
