<script>
import {mapGetters} from "vuex";

export default {
    name: "LocatorBadge",
    methods:{
        handleBadgeClick(){
            if(this.loggedPermission > 2)
                this.$emit('delete')
        },
        handleBtnClick(){
            if(this.loggedPermission > 2)
                this.$emit('selected')
        }
    },
    computed:{
        ...mapGetters({
            loggedPermission: 'auth/loggedPermission'
        })
    }
}
</script>

<template>
    <div class="position-relative">
        <button @click="handleBtnClick" :class="['btn btn-primary max-w-max', {'cursor-default': loggedPermission < 3}]">
            <slot>Locator Value</slot>
        </button>
        <span @click="handleBadgeClick" v-if="loggedPermission > 2" :class="['badge cursor-pointer rounded-pill badge-danger positionBadge', {'disabled cursor-default': loggedPermission < 3}]">
            <i class="fa fa-trash"></i>
        </span>
    </div>

</template>

<style scoped>
.positionBadge{
    position: absolute !important;
    right: -10px !important;
    top: -10px !important;
    font-size: 12px !important;
}
</style>
